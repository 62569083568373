* {
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: normal;
}
body {
  margin-bottom: 0;
  color: #231f20;
}
a,
a:hover {
  text-decoration: none;
  color: #ffffff;
}
img {
  max-width: 100%;
}
p,
ul,
li {
  margin: 0;
  padding: 0;
}
input:hover,
textarea:hover,
.form-control:hover,
select:hover,
input:focus,
textarea:focus,
.form-control:focus,
select:focus {
  box-shadow: none;
  outline: none;
}
::-webkit-scrollbar {
  width: 5px;
  background: #e4e1df;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #a2a2a2;
  width: 5px;
  border-radius: 10px;
}
.main-heading {
  font-size: 44px;
  line-height: 50px;
  font-weight: 700;
  margin-bottom: 70px;
}
.sign-in-btn button {
  width: 180px;
  height: 50px;
  line-height: 50px;
  background: #fa7a23;
  border: 1px solid #fa7a23;
  color: #ffffff;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 600;
  transition: 0.5s;
}
.sign-in-btn button:hover {
  background: transparent;
  color: #fa7a23;
}
.form-control-main {
  margin-bottom: 24px;
  width: 100%;
  display: inline-block;
}
.form-control-main span {
  position: absolute;
  top: 14px;
  left: 15px;
}
.form-control-main .form-control-main1 {
  margin-bottom: 24px;
  width: 100%;
  display: inline-block;
}
.form-control-main .phoneInputCustom {
  border-radius: 0;
  height: 50px;
  line-height: 50px;
  background: #ebebeb !important;
  border: none;
  padding-left: 70px !important;
  color: #040404;
  max-width: 95%;
}
.form-control-main .phoneInputCustom:hover,
.form-control-main .phoneInputCustom:focus {
  box-shadow: none;
}
.form-control-main .phoneInputCustom::-webkit-input-placeholder {
  color: #747474;
  font-size: 14px;
}
.form-control-main .phoneInputCustom:-ms-input-placeholder {
  color: 747474;
  font-size: 14px;
}
.form-control-main .phoneInputCustom::placeholder {
  color: 747474;
  font-size: 14px;
}
.form-control-main .custom {
  border-radius: 0;
  height: 50px;
  line-height: 50px;
  background: blue !important;
  border: none;
  padding-left: 45px;
  color: #ffffff;
}
.form-control-main .custom:hover,
.form-control-main .custom:focus {
  box-shadow: none;
}
.form-control-main .custom::-webkit-input-placeholder {
  color: #747474;
  font-size: 14px;
}
.form-control-main .custom:-ms-input-placeholder {
  color: #747474;
  font-size: 14px;
}
.form-control-main .custom::placeholder {
  color: #747474;
  font-size: 14px;
}
.form-control-main .form-control {
  border-radius: 0;
  height: 50px;
  line-height: 50px;
  background: #3c3939;
  border: none;
  padding-left: 45px;
  color: #ffffff;
}
.form-control-main .form-control:hover,
.form-control-main .form-control:focus {
  box-shadow: none;
}
.form-control-main .form-control::-webkit-input-placeholder {
  color: #747474;
  font-size: 14px;
}
.form-control-main .form-control:-ms-input-placeholder {
  color: #747474;
  font-size: 14px;
}
.form-control-main .form-control::placeholder {
  color: #747474;
  font-size: 14px;
}
.form-control-main input[type='number']::-webkit-outer-spin-button,
.form-control-main input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.form-control-main input[type='number'] {
  -moz-appearance: textfield;
}
.form-control-main .forget-password {
  color: #fa7a23;
  font-size: 16px;
  font-weight: 500;
}
.form-control-main.sign-in-btn {
  margin: 50px 0;
}
.sign-up-wrapper {
  background: #231f20;
  min-height: 100vh;
}
.sign-up-wrapper .sign-up-left {
  background: #ffffff;
  padding-top: 30px;
}
.sign-up-wrapper .sign-up-left .bottom-img {
  padding-top: 50px;
}
.sign-up-wrapper .sign-up-left .signup-text {
  margin: 50px 0 0 0;
}
.sign-up-wrapper .sign-up-left .signup-text h2 {
  font-size: 26px;
  font-weight: 700;
  line-height: 31px;
  margin-bottom: 20px;
}
.sign-up-wrapper .sign-up-left .signup-text p {
  line-height: 24px;
  max-width: 50%;
  margin: 0 auto;
}
.sign-up-wrapper .sign-up-right {
  color: #ffffff;
}
.sign-up-wrapper .sign-up-right .sign-up-right-wrapper {
  max-width: 530px;
  margin: 0 auto;
  padding: 0 15px;
}
.sign-up-wrapper .sign-up-right .sign-up-right-wrapper .mobile-logo {
  display: none;
}
.sign-up-wrapper .sign-up-right p {
  font-size: 18px;
}
.sign-up-wrapper .sign-up-right p a {
  font-weight: 600;
  color: #ffffff;
  font-size: 18px;
  transition: 0.5s;
}
.sign-up-wrapper .sign-up-right p a:hover {
  color: #fa7a23;
}
.main-wrapper .assessment-inner {
  background: #ffffff;
  display: flex;
  flex-wrap: wrap;
  min-height: 100vh;
  width: 100%;
}
.main-wrapper .assessment-inner .navigation {
  background: #fff;
  width: 240px;
  color: #ffffff;
  padding: 10px 0 10px 30px;
  cursor: pointer;
  display: none;
}
.main-wrapper .assessment-inner .navigation .navigation-logo {
  padding-bottom: 50px;
  padding-right: 30px;
}
.main-wrapper .assessment-inner .navigation nav {
  display: none;
}
.main-wrapper .assessment-inner .navigation nav .mobile-close {
  display: none;
}
.main-wrapper .assessment-inner .navigation nav ul li a {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 25px;
}
.main-wrapper .assessment-inner .navigation nav ul li a span {
  color: #ffffff;
  padding-left: 15px;
  width: calc(100% - 35px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.main-wrapper .assessment-inner .assessment-body {
  width: 100%;
}
.main-wrapper .assessment-topbar {
  background: #f8f8f8;
  padding: 12px 30px;
}
.main-wrapper .assessment-topbar .mobile-header {
  display: none;
}
.main-wrapper .assessment-topbar .userinfo {
  font-weight: 600;
  color: #ffffff;
}
.main-wrapper .assessment-topbar .userinfo img {
  width: 40px;
  height: 40px;
}
.main-wrapper .assessment-topbar .userinfo .dropdown-toggle {
  padding: 0;
  border: none;
  color: #231f20;
  font-size: 14px;
  padding-left: 15px;
  background: transparent;
}
.main-wrapper .assessment-topbar .userinfo .dropdown-toggle:hover,
.main-wrapper .assessment-topbar .userinfo .dropdown-toggle:focus {
  box-shadow: none;
  outline: none;
}
.main-wrapper .assessment-topbar .userinfo img {
  border-radius: 8px;
}
.main-wrapper .assessment-bottom {
  padding: 30px;
}
.main-wrapper .assessment-bottom .page-title {
  font-size: 26px;
  font-weight: 700;
  line-height: 31px;
  margin-bottom: 20px;
}
.main-wrapper .assessment-bottom .page-title.new-question-title {
  margin-bottom: 0;
}
.main-wrapper .assessment-bottom .assement-header-bottom {
  margin-bottom: 12px;
}
.main-wrapper .assessment-bottom .assement-select {
  padding: 0 13px 0 60px;
  width: 357px;
}
.main-wrapper .assessment-bottom .assement-search {
  width: calc(100% - 447px);
}
.main-wrapper .assessment-bottom .assement-search.inquire {
  width: calc(100% - 499px);
}
.main-wrapper .assessment-bottom .assement-search input {
  border: 1px solid #231f20;
  width: 100%;
  height: 42px;
  padding-left: 20px;
  color: #231f20;
  transition: 0.5s;
  border-radius: 0;
}
.main-wrapper .assessment-bottom .assement-search input:hover,
.main-wrapper .assessment-bottom .assement-search input:focus {
  box-shadow: none;
  outline: none;
  border-color: #fa7a23;
}
.main-wrapper .assessment-bottom .assement-search input::-webkit-input-placeholder {
  color: #dad9dc;
  font-size: 14px;
}
.main-wrapper .assessment-bottom .assement-search input:-ms-input-placeholder {
  color: #dad9dc;
  font-size: 14px;
}
.main-wrapper .assessment-bottom .assement-search input::placeholder {
  color: #dad9dc;
  font-size: 14px;
}
.main-wrapper .assessment-bottom .profit-filter {
  width: auto;
  flex-grow: 1;
  margin: 1rem;
}
.main-wrapper .assessment-bottom .profit-filter select {
  border: 1px solid #231f20;
  width: 100%;
  height: 42px;
  padding-left: 20px;
  color: #231f20;
  transition: 0.5s;
}
.main-wrapper .assessment-bottom .profit-filter select:hover,
.main-wrapper .assessment-bottom .profit-filter select:focus {
  box-shadow: none;
  outline: none;
  border-color: #fa7a23;
}
.main-wrapper .assessment-bottom .profit-filter select::-webkit-input-placeholder {
  color: #dad9dc;
  font-size: 14px;
}
.main-wrapper .assessment-bottom .profit-filter select:-ms-input-placeholder {
  color: #dad9dc;
  font-size: 14px;
}
.main-wrapper .assessment-bottom .profit-filter select::placeholder {
  color: #dad9dc;
  font-size: 14px;
}
.main-wrapper .assessment-bottom .assesment-company {
  background: #2c5c84;
  padding: 16px;
}
.main-wrapper .assessment-bottom .assesment-company p {
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
}
.main-wrapper .assessment-bottom .assesment-company a {
  font-weight: 600;
  color: #ffffff;
  text-transform: capitalize;
}
.main-wrapper .assessment-bottom .assesment-company a span {
  padding-left: 10px;
}
.main-wrapper .assessment-bottom .assesment-table {
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06);
  padding: 17px;
}
.main-wrapper .assessment-bottom .assesment-table .assesment-table-header {
  background: #231f20;
  color: #ffffff;
  padding: 13px 25px;
}
.main-wrapper .assessment-bottom .assesment-table ul li {
  word-break: break-all;
}
.main-wrapper .assessment-bottom .assesment-table ul li.company-name {
  width: 16%;
  padding: 0 5px;
}
.main-wrapper .assessment-bottom .assesment-table ul li.email {
  width: 13%;
  padding: 0 5px;
}
.main-wrapper .assessment-bottom .assesment-table ul li.phone-number {
  width: 11%;
  padding: 0 5px;
}
.main-wrapper .assessment-bottom .assesment-table ul li.sign-up-date {
  width: 10%;
  padding: 0 5px;
}
.main-wrapper .assessment-bottom .assesment-table ul li.event-start {
  width: 13%;
  padding: 0 5px;
}
.main-wrapper .assessment-bottom .assesment-table ul li.event-end {
  width: 13%;
  padding: 0 5px;
}
.main-wrapper .assessment-bottom .assesment-table ul li.assessment {
  width: 6%;
  text-align: center;
  padding: 0 5px;
}
.main-wrapper .assessment-bottom .assesment-table ul li.edit {
  width: 5%;
  text-align: center;
  padding: 0 5px;
}
.main-wrapper .assessment-bottom .assesment-table ul li.reset {
  width: 5%;
  text-align: center;
  padding: 0 5px;
}
.main-wrapper .assessment-bottom .assesment-table ul li.delete {
  width: 5%;
  text-align: center;
  padding: 0 5px;
}
.main-wrapper .assessment-bottom .assesment-table ul li.action {
  width: 3%;
  text-align: right;
  padding: 0 5px;
}
.main-wrapper .assessment-bottom .assesment-table .assesment-table-content {
  border: 1px solid #dad9dc;
  height: calc(100vh - 377px);
  overflow: auto;
}
.main-wrapper .assessment-bottom .assesment-table .assesment-table-content ul.table-main-content {
  padding: 20px 25px;
  cursor: pointer;
  border-bottom: 1px solid #dad9dc;
}
.main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list {
  background: rgba(35, 31, 32, 0.06);
  width: 100%;
  display: inline-block;
  border-bottom: 1px solid #dad9dc;
}
.main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-top {
  padding: 12px 25px 12px 100px;
}
.main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-top .assessment-list-top-ul li {
  font-weight: 600;
}
.main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-top .assessment-list-top-ul .top-assessments {
  width: 23%;
  padding: 0 5px;
}
.main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-top .assessment-list-top-ul .top-created-date {
  width: 23%;
  padding: 0 5px;
}
.main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-top .assessment-list-top-ul .top-updated-date {
  width: 34%;
  padding: 0 5px;
}
.main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-top .assessment-list-top-ul .top-ass-btn {
  width: 20%;
  padding: 0 5px;
  text-align: right;
}
.main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-top a {
  height: 32px;
  line-height: 32px;
  background: #fa7a23;
  display: inline-block;
  width: 173px;
  text-align: center;
}
.main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-top a span {
  font-size: 12px;
  color: #ffffff;
  padding-left: 10px;
}
.main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-bottom {
  border-top: 1px dashed #a2a2a2;
}
.main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-bottom ul {
  padding: 20px 25px 20px 100px;
}
.main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-bottom ul .bot-new-name {
  width: 23%;
  padding: 0 5px;
}
.main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-bottom ul .bot-created-date {
  width: 23%;
  padding: 0 5px;
}
.main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-bottom ul .bot-updated-date {
  width: 34%;
  padding: 0 5px;
}
.main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-bottom ul .bot-new-edit {
  width: 6%;
  padding: 0 5px;
}
.main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-bottom ul .bot-new-download {
  width: 5%;
  padding: 0 5px;
}
.main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-bottom ul .bot-new-delete {
  width: 8%;
  padding: 0 5px 0 23px;
}
.main-wrapper .assessment-bottom .question-header .add-new {
  height: 40px;
  width: 160px;
  background: #fa7a23;
  border: none;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  text-transform: capitalize;
  margin-left: 15px;
}
.main-wrapper .assessment-bottom .question-header .add-new img {
  margin-left: 5px;
}
.main-wrapper .custom-radio {
  padding-right: 40px;
}
.main-wrapper .custom-radio .custom-radio-container {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 0;
}
.main-wrapper .custom-radio .custom-radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.main-wrapper .custom-radio .custom-radio-container input:checked ~ .checkmark:after {
  display: block;
}
.main-wrapper .custom-radio .custom-radio-container .checkmark:after {
  top: 3px;
  left: 3px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #fa7a23;
}
.main-wrapper .custom-radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid #fa7a23;
}
.main-wrapper .custom-radio .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}
.main-wrapper .assesment-button {
  width: 90px;
}
.main-wrapper .assesment-button a {
  width: 90px;
  height: 42px;
  line-height: 40px;
  background: #fa7a23;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  display: inline-block;
}
.new-user-detail-wrapper {
  color: #ffffff;
  background: #231f20;
  padding: 18px 24px;
  margin-bottom: 24px;
}
.new-user-detail-wrapper a {
  color: #ffffff;
}
.new-user-detail-wrapper .new-user-detail-left {
  width: 50%;
  border-right: 1px solid #dad9dc;
  padding-right: 50px;
}
.new-user-detail-wrapper .new-user-detail-left .new-user-left-user {
  width: 50%;
}
.new-user-detail-wrapper .new-user-detail-left .new-user-left-user p {
  font-size: 22px;
  line-height: 26px;
  font-weight: 700;
  text-transform: capitalize;
}
.new-user-detail-wrapper .new-user-detail-left .new-user-left-content {
  width: 50%;
}
.new-user-detail-wrapper .new-user-detail-left .new-user-left-content ul li {
  width: 100%;
  margin-bottom: 15px;
}
.new-user-detail-wrapper .new-user-detail-left .new-user-left-content ul li p,
.new-user-detail-wrapper .new-user-detail-left .new-user-left-content ul li span {
  width: 50%;
  padding: 0 5px;
}
.new-user-detail-wrapper .new-user-detail-left .new-user-left-content ul li span {
  font-weight: 500;
}
.new-user-detail-wrapper .new-user-detail-right {
  width: 50%;
  padding-left: 50px;
}
.new-user-detail-wrapper .new-user-detail-right .new-user-right-current {
  width: 50%;
}
.new-user-detail-wrapper .new-user-detail-right .new-user-right-current ul li {
  width: 100%;
  margin-bottom: 15px;
}
.new-user-detail-wrapper .new-user-detail-right .new-user-right-current ul li p {
  width: 50%;
  padding: 0 5px;
}
.new-user-detail-wrapper .new-user-detail-right .new-user-right-current ul li label {
  width: 50%;
  padding: 0 5px;
  font-weight: 500;
}
.new-ass-que-wrapper .new-ass-que-left {
  width: 65%;
  max-height: 141vh;
  overflow-y: auto;
  overflow-x: unset;
}
.new-ass-que-wrapper .new-ass-que-left h2 {
  font-size: 26px;
  font-weight: 700;
  text-transform: capitalize;
  margin: 0 0 15px 0;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content {
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06);
  margin-bottom: 20px;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content.new-ass-que-left-content-none {
  box-shadow: none;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content ul {
  padding: 0 24px;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content ul li.ass-questions {
  width: calc(100% - 434px);
  padding-right: 30px;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content ul li.acc-arrow {
  width: 24px;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content ul li.hint {
  width: calc(60px - 30px);
  margin-right: 30px;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content ul li.answers {
  width: 260px;
  padding-right: 30px;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content ul li.percentage {
  width: 90px;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content ul li.tooltip-hint {
  position: relative;
  cursor: help;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content ul li.tooltip-hint:hover .tool-tip {
  display: block;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content ul li.tooltip-hint .tool-tip {
  position: absolute;
  background: #fff;
  padding: 10px;
  top: 0px;
  right: 38px;
  width: 250px;
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid #dad9dc;
  display: none;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content ul li.tooltip-hint .tool-tip:before {
  content: '';
  right: -9px;
  top: 3px;
  position: absolute;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #dad9dc;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-head {
  background: #dad9dc;
  color: #231f20;
  text-transform: capitalize;
  padding: 14px 0;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-head ul li {
  font-weight: 600;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-content ul {
  padding-top: 10px;
  padding-bottom: 10px;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-content ul li.ass-questions {
  line-height: 16px;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-content ul li.answers button {
  width: 107px;
  height: 30px;
  line-height: 30px;
  border: 1px solid #231f20;
  text-transform: capitalize;
  font-size: 12px;
  background: transparent;
  text-align: center;
  display: inline-block;
  color: #231f20;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-content ul li.answers button.active {
  background: #231f20;
  color: #ffffff;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-content ul li.answers button:nth-child(1) {
  margin-right: 15px;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-content ul li.answers textarea {
  width: 100%;
  border: 1px solid #dad9dc;
  margin-top: 10px;
  padding: 8px;
  height: 52px;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-content ul li.answers textarea::-webkit-input-placeholder {
  /* Edge */
  color: #dad9dc;
  font-size: 12px;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-content ul li.answers textarea:-ms-input-placeholder {
  /* Internet Explorer */
  color: #dad9dc;
  font-size: 12px;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-content ul li.answers textarea::placeholder {
  color: #dad9dc;
  font-size: 12px;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-content ul li.percentage input,
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-content ul li.percentage select {
  width: 90px;
  height: 30px;
  line-height: 30px;
  border: 1px solid #dad9dc;
  font-size: 12px;
  text-align: center;
  display: inline-block;
  color: #231f20;
  border-radius: 0;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-content ul li.percentage select {
  margin-bottom: 10px;
}
.new-ass-que-wrapper .new-ass-que-right {
  width: 35%;
  padding-left: 25px;
}
.new-ass-que-wrapper .new-ass-que-right .new-ass-que-right-top {
  padding: 16px;
  margin-bottom: 10px;
  background: #231f20;
  color: #ffffff;
}
.new-ass-que-wrapper .new-ass-que-right .new-ass-que-right-top .bar-chart-wrapper {
  width: 50%;
}
.new-ass-que-wrapper .new-ass-que-right .new-ass-que-right-top .bar-chart-wrapper canvas {
  width: 100% !important;
  height: auto !important;
}
.new-ass-que-wrapper .new-ass-que-right .new-ass-que-right-top .chart-container {
  max-width: 100%;
  overflow: auto;
}
.new-ass-que-wrapper .new-ass-que-right .new-ass-que-right-bottom {
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06);
  padding: 24px;
}
.new-ass-que-wrapper .graph-block {
  width: 35%;
  padding-left: 25px;
}
.new-ass-que-wrapper .graph-block .Graph-box {
  padding: 16px;
  margin-bottom: 10px;
  background: #231f20;
  color: #ffffff;
}
.new-ass-que-wrapper .graph-block .Graph-box .bar-chart-wrapper {
  width: 50%;
}
.new-ass-que-wrapper .graph-block .Graph-box .bar-chart-wrapper canvas {
  width: 100% !important;
  height: auto !important;
}
.new-ass-que-wrapper .graph-block .Graph-box .chart-container {
  max-width: 100%;
  overflow: auto;
}
.new-ass-que-wrapper .graph-block .new-ass-que-right-bottom {
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06);
  padding: 24px;
}
.question-bottom-wrapper .question-bottom-inner form {
  margin: 0 -25px;
}
.question-bottom-wrapper .question-bottom-inner form .from-group {
  width: 50%;
  padding: 0 25px;
  margin-bottom: 15px;
}
.question-bottom-wrapper .question-bottom-inner form .from-group input,
.question-bottom-wrapper .question-bottom-inner form .from-group select {
  height: 42px;
  border: 1px solid #dad9dc;
  font-size: 15px;
  border-radius: 0;
}
.question-bottom-wrapper .question-bottom-inner form .from-group select {
  width: 100%;
}
.question-bottom-wrapper .question-bottom-inner form .from-group .add-new {
  height: 42px;
  width: 44px;
  background: #fa7a23;
  border: none;
}
.question-bottom-wrapper .question-bottom-inner form .from-group .added-btn {
  height: 50px;
  width: 160px;
  background: #fa7a23;
  border: none;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
}
.question-bottom-wrapper .question-bottom-inner form .from-group .switch-text {
  font-size: 15px;
  font-weight: 500;
}
.question-bottom-wrapper .question-bottom-inner form .from-group .form-switch {
  margin-left: 30px;
  padding-left: 0;
}
.question-bottom-wrapper .question-bottom-inner form .from-group .form-switch input {
  height: 18px;
  width: 32px;
  border-radius: 10px;
  border: none;
  background-color: #ffd3b6;
}
.question-bottom-wrapper .question-bottom-inner form .from-group .form-switch .switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 18px;
}
.question-bottom-wrapper .question-bottom-inner form .from-group .form-switch .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.question-bottom-wrapper .question-bottom-inner form .from-group .form-switch .switch input:checked + .slider {
  background-color: #fa7a23;
}
.question-bottom-wrapper .question-bottom-inner form .from-group .form-switch .switch input:checked + .slider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
}
.question-bottom-wrapper .question-bottom-inner form .from-group .form-switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.question-bottom-wrapper .question-bottom-inner form .from-group .form-switch .slider:before {
  position: absolute;
  content: '';
  height: 14px;
  width: 14px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.question-bottom-wrapper .question-bottom-inner form .from-group .form-switch .slider.round {
  border-radius: 34px;
}
.question-bottom-wrapper .question-bottom-inner form .from-group .form-switch .slider.round:before {
  border-radius: 50%;
}
.question-list-wrapper {
  margin-top: 24px;
}
.question-list-wrapper .question-inner .question-title {
  margin-bottom: 15px;
}
.question-list-wrapper .question-inner .question-title h2 {
  font-size: 26px;
  line-height: 31px;
  font-weight: 700;
  margin-bottom: 0;
}
.question-list-wrapper .question-inner .question-list-main .question-list {
  margin-bottom: 16px;
}
.question-list-wrapper .question-inner .question-list-main .question-list .question-list-head {
  background: #ffffff;
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06);
  padding: 17px 20px;
}
.question-list-wrapper .question-inner .question-list-main .question-list .question-list-head h3 {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0;
}
.question-list-wrapper .question-inner .question-list-main .question-list .question-list-head .up-arrow {
  display: none;
}
.question-list-wrapper .question-inner .question-list-main .question-list.active-question .question-list-head .up-arrow {
  display: block;
}
.question-list-wrapper .question-inner .question-list-main .question-list.active-question .question-list-head .down-arrow {
  display: none;
}
.question-list-wrapper .question-inner .question-list-main .question-list .question-list-content-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.question-list-wrapper .question-inner .question-list-main .question-list .question-list-content-wrapper .question-list-content {
  width: calc(50% - 40px);
  margin: 0 20px;
  padding: 25px 0;
  border-bottom: 1px dashed #dad9dc;
}
.question-list-wrapper .question-inner .question-list-main .question-list .question-list-content-wrapper .question-list-content:last-child,
.question-list-wrapper .question-inner .question-list-main .question-list .question-list-content-wrapper .question-list-content:nth-last-child(2) {
  border-bottom: none;
}
.question-list-wrapper .question-inner .question-list-main .question-list .question-list-content-wrapper .question-list-content .question-main {
  margin-bottom: 16px;
}
.question-list-wrapper .question-inner .question-list-main .question-list .question-list-content-wrapper .question-list-content .question-main p {
  width: calc(100% - 85px);
  padding-right: 10px;
}
.question-list-wrapper .question-inner .question-list-main .question-list .question-list-content-wrapper .question-list-content .question-main .question-action {
  width: 85px;
}
.question-list-wrapper .question-inner .question-list-main .question-list .question-list-content-wrapper .question-list-content .question-main .question-action .question-action-delete {
  margin-left: 50px;
}
.question-list-wrapper .question-inner .question-list-main .question-list .question-list-content-wrapper .question-list-content .question-hint {
  margin-bottom: 16px;
}
.question-list-wrapper .question-inner .question-list-main .question-list .question-list-content-wrapper .question-list-content .question-hint label {
  width: 52px;
  font-weight: 600;
}
.question-list-wrapper .question-inner .question-list-main .question-list .question-list-content-wrapper .question-list-content .question-hint p {
  width: calc(100% - 52px);
}
.question-list-wrapper .question-inner .question-list-main .question-list .question-list-content-wrapper .question-list-content .question-bottom label {
  font-size: 12px;
  font-weight: 600;
}
.question-list-wrapper .question-inner .question-list-main .question-list .question-list-content-wrapper .question-list-content .question-bottom p {
  padding-left: 10px;
}
.question-list-wrapper .question-inner .question-list-main .question-list .question-list-content-wrapper .question-list-content .question-bottom .question-percentage {
  margin-right: 50px;
}
.question-list-wrapper .question-inner .question-list-main .question-list .question-list-content-wrapper .question-list-content .question-bottom .question-percentage p {
  font-weight: 700;
  color: #2c5c84;
}
.question-list-wrapper .question-inner .question-list-main .question-list .question-list-content-wrapper .question-list-content .question-bottom .question-status p {
  font-weight: 700;
  color: #fa7a23;
}
.question-list-wrapper .question-inner .question-list-main .question-list.active-question .question-list-head {
  background: #2c5c84;
  color: #ffffff;
}
.question-list-wrapper .question-inner .question-list-main .question-list.active-question .question-list-content-wrapper {
  border: 1px solid #2c5c84;
}
.model-popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.model-popup-wrapper .model-popup {
  width: 822px;
  background: #ffffff;
  padding: 34px;
}
.model-popup-wrapper .model-popup .model-popup-title {
  margin: 0 0 34px 0;
  flex-wrap: wrap;
  align-items: center;
}
.model-popup-wrapper .model-popup .model-popup-title h3 {
  font-size: 26px;
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 0;
  width: calc(100% - 25px);
}
.model-popup-wrapper .model-popup .model-popup-title .btn-close:focus {
  outline: none;
  box-shadow: none;
}
.model-popup-wrapper .model-popup .model-popup-content {
  margin-bottom: 30px;
}
.model-popup-wrapper .model-popup .model-popup-content .from-group input {
  height: 42px;
  border-radius: 0;
}
.model-popup-wrapper .model-popup .model-popup-footer .save-btn {
  width: 160px;
  height: 50px;
  line-height: 50px;
  background: #fa7a23;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
}
.modal-backdrop.fade.show {
  transition: unset;
}
.d-block.fade {
  transition: unset;
}
.modal.fade .modal-dialog {
  transition: unset;
}
.pdf-component canvas {
  height: auto !important;
  width: 100% !important;
}
.form-control-main select.form-control {
  background: #3c3939 url('/assets/images/select-down-arrow.svg') no-repeat;
  background-position-x: calc(100% - 15px);
  background-position-y: 45%;
}
.main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.company-name {
  width: 20%;
}
.main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.sign-up-date {
  width: 14%;
}
.main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.email {
  width: 18%;
}
.main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.phone-number {
  width: 13%;
}
.main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.assessment {
  width: 8%;
}
.main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.edit {
  width: 6%;
}
.main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.reset {
  width: 6%;
}
.main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.delete {
  width: 6%;
}
.main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.assign {
  width: 6%;
}
.main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.action {
  width: 3%;
}
.main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.edit1 {
  width: 7%;
  text-align: center;
}
.main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.assessment1 {
  width: 9%;
}
.main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.reset1 {
  width: 7%;
  text-align: center;
}
.main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.delete1 {
  width: 6%;
  text-align: center;
}
.main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.action1 {
  width: 4%;
  text-align: center;
}
.sign-up-left img:first-child {
  max-width: 333px;
}
.main-wrapper .assessment-inner .navigation .navigation-logo img {
  max-width: 117px;
}
.agent-sign-up-wrapper {
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
}
.agent-sign-up-wrapper:before {
  content: '';
  background: url('/assets/images/agent-bottom-bg.svg') no-repeat;
  width: 367px;
  height: 366px;
  position: absolute;
  left: 0;
  bottom: 0;
}
.agent-sign-up-wrapper:after {
  content: '';
  background: url('/assets/images/agent-top-bg.svg') no-repeat;
  width: 367px;
  height: 366px;
  position: absolute;
  right: 0;
  top: 0;
}
.agent-sign-up-wrapper .agent-sign-up-inner {
  max-width: 1680px;
  margin: 0 auto;
  padding: 0 20px;
  z-index: 1;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-left {
  width: calc(50% - 40px);
  margin-right: 40px;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-left .agent-sign-up-left-top {
  margin-bottom: 70px;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-left .agent-sign-up-left-bottom img,
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-left .agent-sign-up-left-bottom iframe {
  width: 100%;
  height: 450px;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right {
  width: calc(50% - 40px);
  margin-left: 40px;
  background: #ffffff;
  box-shadow: 0px 10px 100px rgba(0, 0, 0, 0.06);
  padding: 72px 72px 56px 72px;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right h2 {
  font-weight: 700;
  font-size: 34px;
  color: #231f20;
  margin-bottom: 60px;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right .profit-advisor-top {
  margin-bottom: 16px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  padding: 12px;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right .profit-advisor-top .profit-advisor-left {
  width: 50%;
  padding-right: 12px;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right .profit-advisor-top .profit-advisor-right {
  width: 50%;
  padding-left: 12px;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right .profit-advisor-top .profit-advisor-right ul {
  padding: 0;
  margin: 0;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right .profit-advisor-top .profit-advisor-right ul li {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right .profit-advisor-top .profit-advisor-right ul li:last-child {
  margin-bottom: 0;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right .profit-advisor-top .profit-advisor-right ul li p,
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right .profit-advisor-top .profit-advisor-right ul li label {
  font-size: 14px;
  font-weight: normal;
  color: #231f20;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right .profit-advisor-top p {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right .profit-advisor-top label {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right form {
  margin: 0 -12px;
  display: flex;
  flex-wrap: wrap;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right form .form-control-main {
  width: calc(50% - 24px);
  margin: 0 12px 16px 12px;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right form .form-control-main.address-input {
  width: calc(100% - 24px);
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right form .form-control-main.profit-signup p {
  font-size: 18px;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right form .form-control-main.profit-signup p a {
  font-weight: 600;
  font-size: 18px;
  color: #231f20;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right form .form-control-main.profit-signup p a:hover {
  color: #fa7a23;
  transition-duration: 0.5s;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right form .form-control-main span {
  top: 10px;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right form .form-control-main .form-control {
  background: #ebebeb;
  color: #231f20;
  height: 42px;
  line-height: 35px;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right form .form-control-main.phoneInput .iti--allow-dropdown {
  width: 100%;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right form .form-control-main.phoneInput .iti--allow-dropdown .iti__flag-container .iti__selected-flag.dropdown-toggle {
  width: 100%;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right form .form-control-main.phoneInput .iti--allow-dropdown .country-dropdown {
  width: 230px;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right form .form-control-main.phoneInput .iti--allow-dropdown .country-dropdown .iti__country-list li {
  position: relative;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right form .form-control-main.phoneInput .iti--allow-dropdown .country-dropdown .iti__country-list li .iti__country-name {
  left: 76px;
  top: 6px;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right form .form-control-main.phoneInput .iti--allow-dropdown .country-dropdown .iti__country-list li .iti__dial-code {
  left: 37px;
  top: 6px;
}
.agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right form .form-control-main.phoneInput .phoneInputCustom {
  width: 100%;
  max-width: 100%;
  height: 42px;
  line-height: 35px;
  padding-left: 85px !important;
}
.height-sidebar-100 {
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.scroll-sidebar-100 {
  height: 100vh;
  overflow: auto;
}
.phoneInput .agentLandingPhoneInput {
  height: 42px;
}
.w-100 {
  width: 100% !important;
}
.w-px-100 {
  width: 100px !important;
}
.w-99 {
  width: 99% !important;
}
.w-px-99 {
  width: 99px !important;
}
.w-98 {
  width: 98% !important;
}
.w-px-98 {
  width: 98px !important;
}
.w-97 {
  width: 97% !important;
}
.w-px-97 {
  width: 97px !important;
}
.w-96 {
  width: 96% !important;
}
.w-px-96 {
  width: 96px !important;
}
.w-95 {
  width: 95% !important;
}
.w-px-95 {
  width: 95px !important;
}
.w-94 {
  width: 94% !important;
}
.w-px-94 {
  width: 94px !important;
}
.w-93 {
  width: 93% !important;
}
.w-px-93 {
  width: 93px !important;
}
.w-92 {
  width: 92% !important;
}
.w-px-92 {
  width: 92px !important;
}
.w-91 {
  width: 91% !important;
}
.w-px-91 {
  width: 91px !important;
}
.w-90 {
  width: 90% !important;
}
.w-px-90 {
  width: 90px !important;
}
.w-89 {
  width: 89% !important;
}
.w-px-89 {
  width: 89px !important;
}
.w-88 {
  width: 88% !important;
}
.w-px-88 {
  width: 88px !important;
}
.w-87 {
  width: 87% !important;
}
.w-px-87 {
  width: 87px !important;
}
.w-86 {
  width: 86% !important;
}
.w-px-86 {
  width: 86px !important;
}
.w-85 {
  width: 85% !important;
}
.w-px-85 {
  width: 85px !important;
}
.w-84 {
  width: 84% !important;
}
.w-px-84 {
  width: 84px !important;
}
.w-83 {
  width: 83% !important;
}
.w-px-83 {
  width: 83px !important;
}
.w-82 {
  width: 82% !important;
}
.w-px-82 {
  width: 82px !important;
}
.w-81 {
  width: 81% !important;
}
.w-px-81 {
  width: 81px !important;
}
.w-80 {
  width: 80% !important;
}
.w-px-80 {
  width: 80px !important;
}
.w-79 {
  width: 79% !important;
}
.w-px-79 {
  width: 79px !important;
}
.w-78 {
  width: 78% !important;
}
.w-px-78 {
  width: 78px !important;
}
.w-77 {
  width: 77% !important;
}
.w-px-77 {
  width: 77px !important;
}
.w-76 {
  width: 76% !important;
}
.w-px-76 {
  width: 76px !important;
}
.w-75 {
  width: 75% !important;
}
.w-px-75 {
  width: 75px !important;
}
.w-74 {
  width: 74% !important;
}
.w-px-74 {
  width: 74px !important;
}
.w-73 {
  width: 73% !important;
}
.w-px-73 {
  width: 73px !important;
}
.w-72 {
  width: 72% !important;
}
.w-px-72 {
  width: 72px !important;
}
.w-71 {
  width: 71% !important;
}
.w-px-71 {
  width: 71px !important;
}
.w-70 {
  width: 70% !important;
}
.w-px-70 {
  width: 70px !important;
}
.w-69 {
  width: 69% !important;
}
.w-px-69 {
  width: 69px !important;
}
.w-68 {
  width: 68% !important;
}
.w-px-68 {
  width: 68px !important;
}
.w-67 {
  width: 67% !important;
}
.w-px-67 {
  width: 67px !important;
}
.w-66 {
  width: 66% !important;
}
.w-px-66 {
  width: 66px !important;
}
.w-65 {
  width: 65% !important;
}
.w-px-65 {
  width: 65px !important;
}
.w-64 {
  width: 64% !important;
}
.w-px-64 {
  width: 64px !important;
}
.w-63 {
  width: 63% !important;
}
.w-px-63 {
  width: 63px !important;
}
.w-62 {
  width: 62% !important;
}
.w-px-62 {
  width: 62px !important;
}
.w-61 {
  width: 61% !important;
}
.w-px-61 {
  width: 61px !important;
}
.w-60 {
  width: 60% !important;
}
.w-px-60 {
  width: 60px !important;
}
.w-59 {
  width: 59% !important;
}
.w-px-59 {
  width: 59px !important;
}
.w-58 {
  width: 58% !important;
}
.w-px-58 {
  width: 58px !important;
}
.w-57 {
  width: 57% !important;
}
.w-px-57 {
  width: 57px !important;
}
.w-56 {
  width: 56% !important;
}
.w-px-56 {
  width: 56px !important;
}
.w-55 {
  width: 55% !important;
}
.w-px-55 {
  width: 55px !important;
}
.w-54 {
  width: 54% !important;
}
.w-px-54 {
  width: 54px !important;
}
.w-53 {
  width: 53% !important;
}
.w-px-53 {
  width: 53px !important;
}
.w-52 {
  width: 52% !important;
}
.w-px-52 {
  width: 52px !important;
}
.w-51 {
  width: 51% !important;
}
.w-px-51 {
  width: 51px !important;
}
.w-50 {
  width: 50% !important;
}
.w-px-50 {
  width: 50px !important;
}
.w-49 {
  width: 49% !important;
}
.w-px-49 {
  width: 49px !important;
}
.w-48 {
  width: 48% !important;
}
.w-px-48 {
  width: 48px !important;
}
.w-47 {
  width: 47% !important;
}
.w-px-47 {
  width: 47px !important;
}
.w-46 {
  width: 46% !important;
}
.w-px-46 {
  width: 46px !important;
}
.w-45 {
  width: 45% !important;
}
.w-px-45 {
  width: 45px !important;
}
.w-44 {
  width: 44% !important;
}
.w-px-44 {
  width: 44px !important;
}
.w-43 {
  width: 43% !important;
}
.w-px-43 {
  width: 43px !important;
}
.w-42 {
  width: 42% !important;
}
.w-px-42 {
  width: 42px !important;
}
.w-41 {
  width: 41% !important;
}
.w-px-41 {
  width: 41px !important;
}
.w-40 {
  width: 40% !important;
}
.w-px-40 {
  width: 40px !important;
}
.w-39 {
  width: 39% !important;
}
.w-px-39 {
  width: 39px !important;
}
.w-38 {
  width: 38% !important;
}
.w-px-38 {
  width: 38px !important;
}
.w-37 {
  width: 37% !important;
}
.w-px-37 {
  width: 37px !important;
}
.w-36 {
  width: 36% !important;
}
.w-px-36 {
  width: 36px !important;
}
.w-35 {
  width: 35% !important;
}
.w-px-35 {
  width: 35px !important;
}
.w-34 {
  width: 34% !important;
}
.w-px-34 {
  width: 34px !important;
}
.w-33 {
  width: 33% !important;
}
.w-px-33 {
  width: 33px !important;
}
.w-32 {
  width: 32% !important;
}
.w-px-32 {
  width: 32px !important;
}
.w-31 {
  width: 31% !important;
}
.w-px-31 {
  width: 31px !important;
}
.w-30 {
  width: 30% !important;
}
.w-px-30 {
  width: 30px !important;
}
.w-29 {
  width: 29% !important;
}
.w-px-29 {
  width: 29px !important;
}
.w-28 {
  width: 28% !important;
}
.w-px-28 {
  width: 28px !important;
}
.w-27 {
  width: 27% !important;
}
.w-px-27 {
  width: 27px !important;
}
.w-26 {
  width: 26% !important;
}
.w-px-26 {
  width: 26px !important;
}
.w-25 {
  width: 25% !important;
}
.w-px-25 {
  width: 25px !important;
}
.w-24 {
  width: 24% !important;
}
.w-px-24 {
  width: 24px !important;
}
.w-23 {
  width: 23% !important;
}
.w-px-23 {
  width: 23px !important;
}
.w-22 {
  width: 22% !important;
}
.w-px-22 {
  width: 22px !important;
}
.w-21 {
  width: 21% !important;
}
.w-px-21 {
  width: 21px !important;
}
.w-20 {
  width: 20% !important;
}
.w-px-20 {
  width: 20px !important;
}
.w-19 {
  width: 19% !important;
}
.w-px-19 {
  width: 19px !important;
}
.w-18 {
  width: 18% !important;
}
.w-px-18 {
  width: 18px !important;
}
.w-17 {
  width: 17% !important;
}
.w-px-17 {
  width: 17px !important;
}
.w-16 {
  width: 16% !important;
}
.w-px-16 {
  width: 16px !important;
}
.w-15 {
  width: 15% !important;
}
.w-px-15 {
  width: 15px !important;
}
.w-14 {
  width: 14% !important;
}
.w-px-14 {
  width: 14px !important;
}
.w-13 {
  width: 13% !important;
}
.w-px-13 {
  width: 13px !important;
}
.w-12 {
  width: 12% !important;
}
.w-px-12 {
  width: 12px !important;
}
.w-11 {
  width: 11% !important;
}
.w-px-11 {
  width: 11px !important;
}
.w-10 {
  width: 10% !important;
}
.w-px-10 {
  width: 10px !important;
}
.w-9 {
  width: 9% !important;
}
.w-px-9 {
  width: 9px !important;
}
.w-8 {
  width: 8% !important;
}
.w-px-8 {
  width: 8px !important;
}
.w-7 {
  width: 7% !important;
}
.w-px-7 {
  width: 7px !important;
}
.w-6 {
  width: 6% !important;
}
.w-px-6 {
  width: 6px !important;
}
.w-5 {
  width: 5% !important;
}
.w-px-5 {
  width: 5px !important;
}
.w-4 {
  width: 4% !important;
}
.w-px-4 {
  width: 4px !important;
}
.w-3 {
  width: 3% !important;
}
.w-px-3 {
  width: 3px !important;
}
.w-2 {
  width: 2% !important;
}
.w-px-2 {
  width: 2px !important;
}
.w-1 {
  width: 1% !important;
}
.w-px-1 {
  width: 1px !important;
}
.h-100 {
  height: 100% !important;
}
.h-99 {
  height: 99% !important;
}
.h-98 {
  height: 98% !important;
}
.h-97 {
  height: 97% !important;
}
.h-96 {
  height: 96% !important;
}
.h-95 {
  height: 95% !important;
}
.h-94 {
  height: 94% !important;
}
.h-93 {
  height: 93% !important;
}
.h-92 {
  height: 92% !important;
}
.h-91 {
  height: 91% !important;
}
.h-90 {
  height: 90% !important;
}
.h-89 {
  height: 89% !important;
}
.h-88 {
  height: 88% !important;
}
.h-87 {
  height: 87% !important;
}
.h-86 {
  height: 86% !important;
}
.h-85 {
  height: 85% !important;
}
.h-84 {
  height: 84% !important;
}
.h-83 {
  height: 83% !important;
}
.h-82 {
  height: 82% !important;
}
.h-81 {
  height: 81% !important;
}
.h-80 {
  height: 80% !important;
}
.h-79 {
  height: 79% !important;
}
.h-78 {
  height: 78% !important;
}
.h-77 {
  height: 77% !important;
}
.h-76 {
  height: 76% !important;
}
.h-75 {
  height: 75% !important;
}
.h-74 {
  height: 74% !important;
}
.h-73 {
  height: 73% !important;
}
.h-72 {
  height: 72% !important;
}
.h-71 {
  height: 71% !important;
}
.h-70 {
  height: 70% !important;
}
.h-69 {
  height: 69% !important;
}
.h-68 {
  height: 68% !important;
}
.h-67 {
  height: 67% !important;
}
.h-66 {
  height: 66% !important;
}
.h-65 {
  height: 65% !important;
}
.h-64 {
  height: 64% !important;
}
.h-63 {
  height: 63% !important;
}
.h-62 {
  height: 62% !important;
}
.h-61 {
  height: 61% !important;
}
.h-60 {
  height: 60% !important;
}
.h-59 {
  height: 59% !important;
}
.h-58 {
  height: 58% !important;
}
.h-57 {
  height: 57% !important;
}
.h-56 {
  height: 56% !important;
}
.h-55 {
  height: 55% !important;
}
.h-54 {
  height: 54% !important;
}
.h-53 {
  height: 53% !important;
}
.h-52 {
  height: 52% !important;
}
.h-51 {
  height: 51% !important;
}
.h-50 {
  height: 50% !important;
}
.h-49 {
  height: 49% !important;
}
.h-48 {
  height: 48% !important;
}
.h-47 {
  height: 47% !important;
}
.h-46 {
  height: 46% !important;
}
.h-45 {
  height: 45% !important;
}
.h-44 {
  height: 44% !important;
}
.h-43 {
  height: 43% !important;
}
.h-42 {
  height: 42% !important;
}
.h-41 {
  height: 41% !important;
}
.h-40 {
  height: 40% !important;
}
.h-39 {
  height: 39% !important;
}
.h-38 {
  height: 38% !important;
}
.h-37 {
  height: 37% !important;
}
.h-36 {
  height: 36% !important;
}
.h-35 {
  height: 35% !important;
}
.h-34 {
  height: 34% !important;
}
.h-33 {
  height: 33% !important;
}
.h-32 {
  height: 32% !important;
}
.h-31 {
  height: 31% !important;
}
.h-30 {
  height: 30% !important;
}
.h-29 {
  height: 29% !important;
}
.h-28 {
  height: 28% !important;
}
.h-27 {
  height: 27% !important;
}
.h-26 {
  height: 26% !important;
}
.h-25 {
  height: 25% !important;
}
.h-24 {
  height: 24% !important;
}
.h-23 {
  height: 23% !important;
}
.h-22 {
  height: 22% !important;
}
.h-21 {
  height: 21% !important;
}
.h-20 {
  height: 20% !important;
}
.h-19 {
  height: 19% !important;
}
.h-18 {
  height: 18% !important;
}
.h-17 {
  height: 17% !important;
}
.h-16 {
  height: 16% !important;
}
.h-15 {
  height: 15% !important;
}
.h-14 {
  height: 14% !important;
}
.h-13 {
  height: 13% !important;
}
.h-12 {
  height: 12% !important;
}
.h-11 {
  height: 11% !important;
}
.h-10 {
  height: 10% !important;
}
.h-9 {
  height: 9% !important;
}
.h-8 {
  height: 8% !important;
}
.h-7 {
  height: 7% !important;
}
.h-6 {
  height: 6% !important;
}
.h-5 {
  height: 5% !important;
}
.h-4 {
  height: 4% !important;
}
.h-3 {
  height: 3% !important;
}
.h-2 {
  height: 2% !important;
}
.h-1 {
  height: 1% !important;
}
.h-0 {
  height: 0% !important;
}
#landingContent {
  line-height: 30px;
}
.exclamation_mark {
  border: 1px solid orange;
  border-radius: 50%;
  padding: 0.1em 0.4em;
  color: orange;
  max-height: 22px;
  display: flex;
  align-items: center;
  max-width: 22px;
  width: 22px;
  justify-content: center;
  margin-right: 0.4rem;
}
.word-break {
  word-break: break-all;
  word-break: break-word;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-bottom {
  margin-top: 15px;
  padding: 0 15px;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-bottom .next-btn {
  width: 120px;
  height: 46px;
  background: #fa7a23;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-bottom .prev-btn {
  width: 120px;
  height: 46px;
  background: #2c5c84;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content {
  padding-bottom: 15px;
}
.new-assessment-bottom .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content.active-faq .new-ass-que-left-content-content,
.new-assessment-bottom .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content.active-faq .new-ass-que-left-content-bottom {
  display: block;
}
.new-assessment-bottom .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-content,
.new-assessment-bottom .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-bottom {
  display: none;
}
.NoData {
  display: none !important;
}
.imageRoate {
  transform: rotate(180deg);
}
.adminList {
  background: transparent !important;
  box-shadow: 0 6px 30px #0000000f !important;
  padding: 24px !important;
  margin-bottom: 10px !important;
  color: #000000 !important;
}
.new-morgage .sign-up-left h2 {
  width: 100%;
  font-size: 3rem;
}
.main-wrapper .assessment-bottom .question-header .add-new.add-script-btn {
  width: auto;
  padding: 0 14px;
}
.Disclaimer {
  font-size: 16px;
  color: #231f20;
  font-weight: 500;
  padding: 10px;
}
.form-control-main .tooltip-inner {
  width: 840px;
  max-width: unset !important;
  position: absolute;
  right: -85px;
  bottom: 7px;
  text-align: left;
}
.agent-profit-plan-bottom .plan-type-header {
  display: none;
}
.agent-profit-plan-bottom .agent-profit-plan-bottom-content .agent-content-box .agent-content-box-link .second {
  justify-content: flex-end;
}
.signup-input-div.phoneInput .iti,
.signup-input-div.phoneInput .iti input {
  width: 100%;
}
.new-classes-wrapper .new-classes-left {
  width: 210px;
  background: #fff;
  box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.06);
}
.new-classes-wrapper .new-classes-middle {
  width: calc(100% - 712px);
  margin: 0 20px;
  background: #fff;
  box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.06);
  padding: 20px;
  max-height: calc(100vh - 314px);
  overflow: auto;
}
.new-classes-wrapper .new-classes-right {
  width: 462px;
  background: #fff;
  box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.06);
  padding: 16px 16px 0 16px;
  max-height: calc(100vh - 314px);
  overflow: auto;
}
.new-classes-wrapper .new-classes-right .agent-profit-plan-bottom-content {
  columns: unset;
}
.new-classes-wrapper .new-classes-middle .new-classes-download-btn .download-btn-new {
  background: #fa7a23;
  padding: 0 12px;
  height: 32px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border: none;
  cursor: pointer;
}
.new-classes-wrapper .new-classes-middle .new-classes-download-btn .download-btn-new span,
.new-classes-wrapper .new-classes-middle .new-classes-download-btn .delete-btn-new span {
  color: #fff;
  font-size: 12px;
  font-weight: 400;
}
.new-classes-wrapper .new-classes-middle .new-classes-download-btn .delete-btn-new {
  background: #ec6363;
  padding: 0 12px;
  height: 32px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border: none;
  cursor: pointer;
}
.new-classes-wrapper .new-classes-middle .middle-heading {
  color: #231f20;
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
}
.new-classes-wrapper .new-classes-right .agent-content-box .agent-content-box-wrapper-new .agent-content-box-left {
  width: 170px;
  height: 110px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.new-classes-wrapper .new-classes-right .agent-content-box .agent-content-box-wrapper-new .agent-content-box-left img {
  max-height: 100%;
}
.new-classes-wrapper .new-classes-right .agent-content-box.video-upload .agent-content-box-wrapper-new .agent-content-box-left {
  background: #fff1e8;
}
.new-classes-wrapper .new-classes-right .agent-content-box.file-upload .agent-content-box-wrapper-new .agent-content-box-left {
  background: #e5f8f3;
}
.new-classes-wrapper .new-classes-right .agent-content-box.file-upload.ppt-bg .agent-content-box-wrapper-new .agent-content-box-left {
  background: #fff6f3;
}
.new-classes-wrapper .new-classes-right .agent-content-box.file-upload.pdf-bg .agent-content-box-wrapper-new .agent-content-box-left {
  background: #fff6f3;
}
.new-classes-wrapper .new-classes-right .agent-content-box.link-upload .agent-content-box-wrapper-new .agent-content-box-left {
  background: #f0f9ff;
}
.new-classes-wrapper .new-classes-right .agent-content-box .agent-content-box-wrapper-new .agent-content-box-right {
  width: calc(100% - 170px);
  padding-left: 16px;
  box-sizing: border-box;
}
.f-14-400 {
  font-size: 14px;
  font-weight: 400;
  color: #231f20;
}
.new-classes-wrapper .new-classes-right .agent-content-box .agent-content-box-wrapper-new .agent-content-box-right h4 {
  height: 74px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.new-classes-wrapper .new-classes-right .agent-content-box .agent-content-box-wrapper-new .agent-content-box-right span {
  width: calc(100% - 34px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.new-classes-wrapper .new-classes-right .agent-content-box .agent-content-box-wrapper-new .agent-content-box-right span a {
  color: #009bff;
  font-size: 12px;
  font-weight: 600;
}
.new-classes-wrapper .new-classes-right .agent-content-box .agent-content-box-wrapper-new .agent-content-box-right .new-delete-icon {
  width: 24px;
  margin-left: 10px;
  height: 24px;
  background: #ec6363;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.new-classes-wrapper .new-classes-middle .new-classes-download-btn .new-classes-download-btn-left {
  width: calc(100% - 300px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.new-classes-wrapper .new-classes-middle .new-classes-download-btn .new-classes-download-btn-left span {
  color: #231f20;
  font-size: 12px;
  font-weight: 400;
}
.new-classes-wrapper .new-classes-middle .new-classes-download-btn .new-classes-download-btn-right {
  width: 300px;
  padding-left: 10px;
}
.new-classes-wrapper .new-classes-left .agent-profit-plan-bottom-topbar .agent-topbar-wrapper a {
  color: #231f20;
  font-size: 14px;
  font-weight: 400;
  padding: 10px 12px;
  display: inline-block;
  width: 100%;
}
.new-classes-wrapper .new-classes-middle .pdf-upload-prev .preview-middel,
.new-classes-wrapper .new-classes-middle .ppt-upload-prev .preview-middel {
  box-sizing: border-box;
  justify-content: center;
}
.new-classes-wrapper .new-classes-middle .text-upload-prev .preview-middel p {
  color: #009bff;
  font-size: 12px;
  font-weight: 400;
  margin-left: 6px;
}
.new-classes-wrapper .new-classes-middle .pdf-upload-prev .preview-middel p,
.new-classes-wrapper .new-classes-middle .ppt-upload-prev .preview-middel p {
  color: #231f20;
  font-size: 12px;
  font-weight: 400;
  margin-left: 6px;
}
.new-classes-wrapper .new-classes-middle .image-upload-prev .preview-middel {
  width: 100%;
  height: 200px;
  background: #f0f9ff;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.new-classes-wrapper .new-classes-middle .image-upload-prev .preview-middel {
  height: auto;
  min-height: 200px;
}
.new-classes-wrapper .new-classes-middle .image-upload-prev .preview-middel span {
  text-align: center;
}
.new-classes-wrapper .new-classes-middle .image-upload-prev .preview-middel {
  background: #e5f8f3;
}
.image-preview {
  height: 5rem;
  margin: 1rem 0;
}
.image-preview img {
  height: 100%;
}
.agent-profit-plan-bottom .agent-profit-plan-bottom-content .agent-content-box {
  cursor: pointer;
}
.text-center-middle {
  width: 100%;
  height: 200px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.previous-coaching-wrapper {
  background: #fff;
  box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.06);
  width: 100%;
  padding: 16px;
}
.previous-coaching-wrapper .previous-coaching-search {
  margin-bottom: 16px;
}
.previous-coaching-wrapper .previous-coaching-search a {
  width: 90px;
  height: 42px;
  line-height: 40px;
  background: #fa7a23;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}
.previous-coaching-wrapper .previous-coaching-search input {
  border: 1px solid #231f20;
  width: calc(100% - 110px);
  height: 42px;
  padding-left: 20px;
  color: #231f20;
  transition: 0.5s;
  border-radius: 0;
  margin-right: 20px;
}
.previous-coaching-wrapper .previous-coaching-search input:hover,
.previous-coaching-wrapper .previous-coaching-search input:focus {
  border-color: #fa7a23;
}
.previous-coaching-table .previous-coaching-table-head {
  background: #231f20;
  width: 100%;
  padding: 12px 0;
}
.previous-coaching-table .previous-coaching-table-head li {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}
.previous-coaching-table .previous-coaching-table-content .list-video-name {
  color: #231f20;
  font-size: 14px;
  font-weight: 400;
  padding-left: 16px;
  box-sizing: border-box;
  width: calc(100% - 88px);
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.previous-coaching-table .previous-coaching-table-content .action {
  text-align: right;
}
.previous-coaching-table .previous-coaching-table-content .action,
.previous-coaching-table .previous-coaching-table-head li.action {
  width: 80px;
  padding: 0 16px;
}
.previous-coaching-table .previous-coaching-table-content .clickhere,
.previous-coaching-table .previous-coaching-table-head li.clickhere {
  width: 82px;
  padding: 0 16px;
}
.previous-coaching-table .previous-coaching-table-content .modified,
.previous-coaching-table .previous-coaching-table-head li.modified {
  width: 176px;
  padding: 0 16px;
}
.previous-coaching-table .previous-coaching-table-content .title,
.previous-coaching-table .previous-coaching-table-head li.title {
  width: calc(100% - 338px);
  padding: 0 16px;
}
.previous-coaching-table .previous-coaching-table-content .modified {
  color: #231f20;
  font-size: 14px;
  font-weight: 400;
}
.previous-coaching-table .previous-coaching-table-content .clickhere a {
  color: #fa7a23;
  font-size: 10px;
  font-weight: 600;
}
.previous-coaching-table .previous-coaching-table-content .previous-coaching-table-wrapper {
  border-bottom: 1px solid #dad9dc;
}
.previous-coaching-table .previous-coaching-table-content .previous-coaching-table-wrapper:last-child {
  border: none;
}
.previous-coaching-table .previous-coaching-table-content {
  border: 1px solid #dad9dc;
  border-top: none;
  max-height: calc(100vh - 467px);
  overflow: auto;
}
.prec-detail-wrapper .prec-detail-wrapper-left {
  background: #fff;
  box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.06);
  width: calc(100% - 482px);
  margin-right: 20px;
  padding: 20px;
  box-sizing: border-box;
  max-height: calc(100vh - 314px);
  overflow: auto;
}
.prec-detail-wrapper .prec-detail-wrapper-left h3 {
  color: #231f20;
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 10px;
}
.prec-detail-wrapper .prec-detail-wrapper-left .video-des {
  color: #231f20;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin: 0 0 20px 0;
}
.prec-detail-wrapper .prec-detail-wrapper-left .video-des a,
.prec-detail-wrapper .prec-detail-wrapper-left .video-des span,
.prec-detail-wrapper .prec-detail-wrapper-left .video-des p {
  font-size: 16px;
}
.prec-detail-wrapper .prec-detail-wrapper-left .video-des a {
  color: #fa7a23;
  word-break: break-all;
}
.prec-detail-wrapper .prec-detail-wrapper-left .video-btn-only a svg {
  margin-right: 8px;
}
.prec-detail-wrapper .prec-detail-wrapper-left .video-btn-only a.edit-prev-btn {
  background: #fa7a23;
}
.prec-detail-wrapper .prec-detail-wrapper-left .video-btn-only a.video-delete-btn {
  width: 85px;
  height: 32px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background: #ec6363;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
}
.prec-detail-wrapper .prec-detail-wrapper-right {
  background: #fff;
  box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.06);
  width: 462px;
  padding: 20px;
  box-sizing: border-box;
}
.previous-coaching-table ul li {
  list-style: none;
}
.prec-detail-wrapper .prec-detail-wrapper-right .pre-call-content-box-wrapper {
  max-height: calc(100vh - 412px);
  overflow: auto;
}
.prec-detail-wrapper .prec-detail-wrapper-right .pre-call-content-box:last-child {
  margin-bottom: 0;
}
.prec-detail-wrapper .prec-detail-wrapper-right .pre-call-content-box {
  width: 100%;
  margin-bottom: 16px;
  cursor: pointer;
}
.prec-detail-wrapper .prec-detail-wrapper-right .pre-call-content-box .agent-content-box-wrapper-new {
  padding-right: 5px;
}
.prec-detail-wrapper .prec-detail-wrapper-right .pre-call-content-box .agent-content-box-left {
  width: 170px;
  background: #f1f1f1;
  text-align: center;
}
.prec-detail-wrapper .prec-detail-wrapper-right .pre-call-content-box .agent-content-box-left img {
  max-height: 116px;
}
.prec-detail-wrapper .prec-detail-wrapper-right .pre-call-content-box .agent-content-box-right {
  width: calc(100% - 170px);
  padding-left: 16px;
}
.prec-detail-wrapper .prec-detail-wrapper-right .pre-call-content-box .agent-content-box-right h4 {
  color: #231f20;
  font-size: 14px;
  line-height: 24px;
  height: 74px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 12px;
}
.prec-detail-wrapper .prec-detail-wrapper-right .pre-call-content-box .agent-content-box-right span {
  width: calc(100% - 55px);
  margin-right: 6px;
  color: #231f20;
  font-size: 12px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
.prec-detail-wrapper .prec-detail-wrapper-right .pre-call-content-box .agent-content-box-right span a {
  color: #231f20;
  font-size: 12px;
  font-weight: 400;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
.prec-detail-wrapper .prec-detail-wrapper-right .pre-call-content-box .agent-content-box-right .new-delete-icon {
  height: 24px;
  width: 24px;
  background: #ec6363;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.prec-detail-wrapper .prec-detail-wrapper-right .right-coaching-search {
  margin-bottom: 16px;
}
.prec-detail-wrapper .prec-detail-wrapper-right .right-coaching-search input {
  border: 1px solid #231f20;
  width: calc(100% - 110px);
  height: 42px;
  padding-left: 20px;
  color: #231f20;
  transition: 0.5s;
  border-radius: 0;
  margin-right: 20px;
}
.prec-detail-wrapper .prec-detail-wrapper-right .right-coaching-search input:hover,
.prec-detail-wrapper .prec-detail-wrapper-right .right-coaching-search input:focus {
  border-color: #fa7a23;
}
.prec-detail-wrapper .prec-detail-wrapper-right .right-coaching-search a {
  width: 90px;
  height: 42px;
  line-height: 40px;
  background: #fa7a23;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}
.model-popup .question-bottom-wrapper .right-side .form-control-main.upload-img .position-relative + p,
.model-popup .question-bottom-wrapper .right-side .form-control-main.upload-img .image-preview + p {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.previous-coaching-popup.model-popup-wrapper .model-popup {
  width: 440px;
}
.cursor-pointer {
  cursor: pointer;
}
.cdk-drag-preview.courses-drag {
  width: calc(25% - 24px);
  margin: 0 12px 24px 12px;
  cursor: pointer;
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06);
}
.cdk-drag-preview.courses-drag .all-courses-img {
  height: 220px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.cdk-drag-preview.courses-drag img {
  max-height: 220px;
}
.cdk-drag-preview.courses-drag .all-courses-heading {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 16px 0 8px 0;
  padding: 0 12px;
}
.cdk-drag-preview.courses-drag .all-courses-heading p {
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #231f20;
  width: calc(100% - 50px);
  cursor: pointer;
  height: 50px;
  overflow: hidden;
  word-break: break-word;
}
.cdk-drag-preview.courses-drag .all-courses-heading span {
  width: 50px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background: rgba(44, 92, 132, 0.1);
  font-size: 14px;
  color: #2c5c84;
  height: 28px;
}
.cdk-drag-preview.courses-drag .all-courses-heading span svg {
  width: 14px;
  margin-right: 8px;
}
.cdk-drag-preview.courses-drag .all-courses-p {
  margin-bottom: 8px;
  color: #231f20;
  font-size: 14px;
  line-height: 24px;
  padding: 0 12px;
  height: 50px;
  overflow: hidden;
  word-break: break-word;
}
.cdk-drag-preview.courses-drag .all-courses-bottom {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background: #f9f9f9;
  padding: 9px 12px;
  width: calc(100% - 24px);
  margin: 0 12px 12px 12px;
}
.cdk-drag-preview.courses-drag .all-courses-bottom a {
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: #fa7a23;
}
.cdk-drag-preview.courses-drag .all-courses-bottom a svg {
  width: 12px;
  margin-right: 10px;
}
.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
.cdk-drop-list-dragging .cdk-drag {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
.example-custom-placeholder {
  background: #e7e6e6;
  border: dashed 3px #999;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.inner {
  width: 100%;
  height: 100%;
  cursor: move;
}
.categories-item {
  height: 400px;
  position: relative;
}
.form-control-main .signup-uri {
  width: calc(100% - 40px);
}
.form-control-main .signup-tooltip {
  width: 40px;
  height: 50px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}
.form-control-main .signup-tooltip svg {
  cursor: help;
}
.form-control-main .signup-tooltip .signup-tooltip-content {
  position: absolute;
  top: 45px;
  background: #000;
  font-size: 14px;
  padding: 10px;
  width: 100%;
  display: none;
}
.form-control-main .signup-tooltip .signup-tooltip-content:before {
  content: '';
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #000;
  position: absolute;
  top: -5px;
  right: 7px;
}
.form-control-main .signup-tooltip:hover .signup-tooltip-content {
  display: block;
}
.form-control-main .signup-tooltip .signup-tooltip-content p {
  font-size: 13px;
  color: #b4b1b1;
}
.signup-uri-input {
  text-transform: lowercase;
}
.cdk-drag-preview .agent-content-box .agent-content-box-wrapper-new .agent-content-box-left {
  width: 170px;
  height: 110px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.cdk-drag-preview .agent-content-box.link-upload .agent-content-box-wrapper-new .agent-content-box-left {
  background: #f0f9ff;
}
.cdk-drag-preview .agent-content-box .agent-content-box-wrapper-new .agent-content-box-left img {
  max-height: 100%;
}
.cdk-drag-preview .agent-content-box .agent-content-box-wrapper-new .agent-content-box-right {
  width: calc(100% - 170px);
  padding-left: 16px;
  box-sizing: border-box;
}
.cdk-drag-preview .agent-content-box .agent-content-box-wrapper-new .agent-content-box-right h4 {
  height: 74px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #231f20;
  margin-bottom: 12px;
}
.cdk-drag-preview .agent-content-box .agent-content-box-wrapper-new .agent-content-box-right span {
  width: calc(100% - 34px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  color: #000000;
}
.cdk-drag-preview .agent-content-box .agent-content-box-wrapper-new .agent-content-box-right .new-delete-icon {
  width: 24px;
  margin-left: 10px;
  height: 24px;
  background: #ec6363;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.agent-profit-plan-classes-drag-placeholder {
  background: #e7e6e6;
  border: dashed 3px #999;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.cdk-drag-preview .agent-content-box .agent-content-box-wrapper-new .agent-content-box-right span a {
  color: #009bff;
  font-size: 12px;
  font-weight: 600;
}
.cdk-drag-preview .agent-content-box.file-upload .agent-content-box-wrapper-new .agent-content-box-left {
  background: #e5f8f3;
}
.cdk-drag-preview .agent-content-box.file-upload.pdf-bg .agent-content-box-wrapper-new .agent-content-box-left {
  background: #fff6f3;
}
.cdk-drag-preview .agent-content-box.file-upload.ppt-bg .agent-content-box-wrapper-new .agent-content-box-left {
  background: #fff6f3;
}
.new-classes-wrapper .new-classes-right .cdk-drop-list {
  height: 126px;
  position: relative;
}
.new-classes-wrapper .new-classes-right .cdk-drop-list .cdk-drag {
  width: 100%;
  height: 100%;
  cursor: move;
}
.resource-wrapper .resource-title {
  color: #231f20;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
}
.resource-wrapper .resource-inner {
  margin: 0 -8px;
}
.resource-wrapper .resource-inner .resource-box {
  width: calc(25% - 16px);
  margin: 0 8px 16px 8px;
  background: #f9f9f9;
  padding: 8px;
  box-sizing: border-box;
}
.resource-wrapper .resource-inner .resource-box .resource-box-left {
  width: 100%;
}
.resource-wrapper .resource-inner .resource-box .resource-box-right {
  width: 100%;
  padding-top: 8px;
  box-sizing: border-box;
}
.resource-wrapper .resource-inner .resource-box .resource-box-right .resource-title {
  color: #231f20;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  margin-bottom: 8px;
}
.resource-wrapper .resource-inner .resource-box .resource-box-right .resource-email label,
.resource-wrapper .resource-inner .resource-box .resource-box-right .resource-link label,
.resource-wrapper .resource-inner .resource-box .resource-box-right .resource-link a,
.resource-wrapper .resource-inner .resource-box .resource-box-right .resource-desc {
  color: #231f20;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  margin-bottom: 8px;
}
.resource-wrapper .resource-inner .resource-box .resource-box-right .resource-email span,
.resource-wrapper .resource-inner .resource-box .resource-box-right .resource-link span {
  width: 20px;
}
.resource-wrapper .resource-inner .resource-box .resource-box-right .resource-email label,
.resource-wrapper .resource-inner .resource-box .resource-box-right .resource-link a {
  width: calc(100% - 20px);
}
.resource-wrapper .resource-inner .resource-box .resource-box-right .resource-link a:hover {
  color: #fa7a23;
}
.resource-wrapper .resource-inner .resource-box .resource-box-right .resource-box-right-top {
  margin-bottom: 7px;
}
.resource-wrapper .resource-inner .resource-box .resource-box-right .resource-box-right-bottom .small-download span {
  color: #fa7a23;
  font-size: 10px;
  font-weight: 600;
}
.upload-file-wrapper-prev {
  margin: 0 -6px;
  max-height: 158px;
  overflow: auto;
}
.upload-file-wrapper-prev .upload-file-box-prev {
  width: calc(50% - 12px);
  margin: 0 6px 12px 6px;
  padding: 10px;
  background: #f9f9f9;
}
.upload-file-wrapper-prev .upload-file-box-prev p {
  color: #231f20;
  font-size: 12px;
  font-weight: 400;
  width: calc(100% - 16px);
  padding-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cdk-drag-preview .title {
  width: calc(100% - 338px);
  padding: 0 16px;
}
.cdk-drag-preview .modified {
  width: 176px;
  padding: 0 16px;
  color: #231f20;
  font-size: 14px;
  font-weight: 400;
}
.cdk-drag-preview .clickhere {
  width: 82px;
  padding: 0 16px;
}
.cdk-drag-preview .clickhere a {
  color: #fa7a23;
  font-size: 10px;
  font-weight: 600;
}
.cdk-drag-preview .action {
  width: 80px;
  padding: 0 16px;
}
.cdk-drag-preview .list-video-name {
  color: #231f20;
  font-size: 14px;
  font-weight: 400;
  padding-left: 16px;
  box-sizing: border-box;
  width: calc(100% - 88px);
}
.previous-coaching-table-content .previous-coaching-call-placeholder-wrapper {
  width: 100%;
  height: 100%;
  cursor: move;
  padding: 12px 0;
}
.previous-coaching-call-placeholder {
  background: #e7e6e6;
  border: dashed 3px #999;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.previous-coaching-table-content .cdk-drop-list {
  height: 78px;
  position: relative;
}
.pre-call-content-box-wrapper .cdk-drop-list {
  height: 116px;
  position: relative;
}
.cdk-drag-preview .agent-content-box-left {
  width: 170px;
  background: #f1f1f1;
}
.cdk-drag-preview .agent-content-box-right {
  width: calc(100% - 170px);
  padding-left: 16px;
}
.cdk-drag-preview .agent-content-box-right h4 {
  color: #231f20;
  font-size: 14px;
  line-height: 24px;
  height: 74px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 12px;
}
.all-courses-wrapper .all-courses-inner .all-courses-heading p {
  height: 54px !important;
}
.newplan-link .ck.ck-editor__editable_inline {
  background: #ebebeb;
  border-color: #ebebeb;
}
.newplan-link.form-control-main span {
  position: unset;
}
.question-bottom-wrapper .question-bottom-inner form .newplan-link form {
  margin: 0;
}
.newplan-link .ck.ck-editor__editable_inline a {
  color: #fa7a23;
}
.newplan-link .ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
  border-color: #ebebeb;
  box-shadow: none;
}
.newplan-link .ck-body-inner {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.newplan-link .ck-body-inner .ck.ck-toolbar {
  display: flex;
  order: 1;
  margin-left: auto;
  width: 40px;
  height: 48px;
}
.newplan-link .ck-body-inner .ck.ck-content {
  width: calc(100% - 50px);
}
.ck.ck-powered-by {
  display: none;
}
.description-heading {
  margin: 20px 0 10px 0;
}
.description-heading p {
  color: #231f20;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
}
.profit-course.previous-coaching-popup .model-popup-inner {
  max-height: 90%;
  overflow: auto;
}
.resource-add-heading {
  margin-bottom: -32px;
}
.resource-add-heading a {
  padding: 0 12px;
  height: 32px;
  background: #fa7a23;
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.video-des-email {
  width: calc(100% - 85px);
  padding-right: 10px;
}
.video-des-email .video-des--link {
  margin-right: 16px;
}
.video-des-email .video-des--link a {
  color: #231f20;
}
.video-des-email .video-des--link a:hover {
  color: #fa7a23;
}
.inquiry-search-heade .assement-search.profit-filter {
  width: 23%;
  margin: 0;
  flex-grow: unset;
  padding-right: 16px;
}
.inquiry-search-heade .inquiry-search-wrapper {
  width: 77%;
}
.main-wrapper .assessment-bottom .inquiry-search-heade .assement-search.inquire {
  width: calc(100% - 97px);
}
.question-bottom-inner .form-control-main .form-control {
  color: #212529;
}
.previous-coaching-popup .question-bottom-wrapper .question-bottom-inner form .from-group {
  padding: 0;
  margin-right: 15px;
}
.profit-course .model-popup-inner {
  overflow-x: hidden !important;
}
.previous-coaching-table .previous-coaching-table-content .list-video-name {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.main-wrapper .assessment-topbar .userinfo .header-logo img {
  width: auto;
  height: auto;
}
.main-wrapper .assessment-topbar .userinfo .header-logo {
  padding-right: 40px;
  margin-right: 40px;
  border-right: 2px solid #fff;
}
.all-courses-wrapper {
  max-height: calc(100vh - 196px) !important;
}
.modal-content {
  border: none;
}
.preview-middel span.w-100 {
  text-align: center;
}
.new-canva-right {
  width: calc(100% - 226px);
  margin-left: 16px;
  box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.06);
  padding: 16px;
}
.new-canva-right .new-canva-right-search .new-canva-right-search-left {
  width: calc(100% - 114px);
}
.new-canva-right .new-canva-right-search .new-canva-right-search-left input {
  height: 42px;
  border: 1px solid #231f20;
  padding: 0 10px;
  width: 100%;
  box-sizing: border-box;
}
.new-canva-right .new-canva-right-search .new-canva-right-search-right {
  width: 114px;
  padding-left: 24px;
}
.new-canva-right .new-canva-right-search .new-canva-right-search-right button {
  width: 90px;
  height: 42px;
  background: #fa7a23;
  border: none;
  color: #fff;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
  align-items: center;
  justify-content: center;
}
.new-canva-right-table .new-canva-right-table-head {
  background: #231f20;
  padding: 12px 16px;
}
.new-canva-right-table .new-canva-right-table-head li,
.new-canva-right-table .new-canva-right-table-content-wrapper .new-canva-right-table-content li {
  list-style: none;
}
.new-canva-right-table .new-canva-right-table-content-wrapper .new-canva-right-table-content .canva-table-title,
.new-canva-right-table .new-canva-right-table-head .canva-table-title {
  width: calc(100% - 100px);
}
.new-canva-right-table .new-canva-right-table-content-wrapper .new-canva-right-table-content .canva-table-action,
.new-canva-right-table .new-canva-right-table-head .canva-table-action {
  width: 100px;
}
.new-canva-right-table .new-canva-right-table-head .canva-table-title {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}
.new-canva-right-table .new-canva-right-table-head .canva-table-action {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}
.new-canva-right-table .new-canva-right-table-content-wrapper .new-canva-right-table-content .canva-table-title .canva-table-title-img img {
  width: 88px;
}
.new-canva-right-table .new-canva-right-table-content-wrapper {
  border: 1px solid #dad9dc;
  border-bottom: none;
}
.new-canva-right-table .new-canva-right-table-content-wrapper .new-canva-right-table-content .canva-table-title .canva-table-title-img p {
  width: calc(100% - 88px);
  font-size: 16px;
  padding-left: 16px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
}
.new-canva-right-table .new-canva-right-table-content-wrapper .new-canva-right-table-content {
  border-bottom: 1px solid #dad9dc;
  padding: 0 0;
}
.new-canva-right-table .new-canva-right-table-content-wrapper .new-canva-right-table-content .script-canva-table-thead {
  padding: 12px 16px 12px 16px;
  align-items: center;
}
.new-canva-right-table-content-visible {
  margin: 16px 16px 16px 16px;
}
.new-canva-right-table-content-visible .new-canva-right-table-content-visible-left {
  width: 684px;
}
.new-canva-right-table-content-visible .new-canva-right-table-content-visible-right {
  width: calc(100% - 684px);
  padding-left: 24px;
}
.new-canva-right-table-content-visible .new-canva-right-table-content-visible-right p {
  color: #231f20;
  font-size: 16px;
  line-height: 26px;
  height: calc(100% - 32px);
  padding-bottom: 10px;
}
.new-canva-right-table-content-visible .new-canva-right-table-content-visible-right .new-canva-right-table-content-visible-right-bottom {
  height: 32px;
}
.new-canva-right-table-content-visible .new-canva-right-table-content-visible-right .new-canva-right-table-content-visible-right-bottom .d-button {
  background: #fa7a23;
  height: 32px;
  padding: 0 12px;
  color: #fff;
  text-align: center;
  font-size: 12px;
  border: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.new-canva-right-table .new-canva-right-table-content-wrapper.active-table .script-canva-table-thead {
  background: #f2f2f2;
}
.ck-body-wrapper.email-text-ckeditor {
  width: calc(100% - 30px);
  margin: 0 15px 0 15px;
  background: #ebebeb;
  padding: 8px;
}
.ck-body-wrapper.email-text-ckeditor .ck.ck-toolbar {
  background: #ebebeb;
  border: none;
}
.ck-body-wrapper.email-text-ckeditor .ck.ck-content {
  min-height: 100px;
}
.ck-body-wrapper.email-text-ckeditor .ck.ck-content a {
  color: #fa7a23;
}
.ck-body-wrapper.email-text-ckeditor .ck.ck-content ul {
  padding-left: 1rem;
}
.question-bottom-wrapper .question-bottom-inner .email-text-ckeditor form {
  margin: 0;
}
.ck-body-wrapper.email-text-ckeditor .ck.ck-content:focus,
.ck-body-wrapper.email-text-ckeditor .ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable),
.ck-body-wrapper.email-text-ckeditor .ck.ck-content:focus-visible {
  outline: none;
  border: 1px solid transparent;
  box-shadow: none;
}
.received_msg .received_withd_msg a {
  color: #fa7a23;
}
.received_msg .received_withd_msg strong u {
  font-weight: bolder;
}
.ck-body-wrapper.email-text-ckeditor .ck.ck-content strong u {
  font-weight: bolder;
}
.mass-heading {
  margin-left: 16px;
  margin-right: 16px;
  font-size: 16px;
}
.invalid-feedback-msg {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 12px;
  color: #dc3545;
}
.LoaderModel {
  height: 100vh;
  width: 100vw !important;
  max-width: 100vw !important;
  margin: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.LoaderModel .modal-content {
  height: 100vh;
  width: 100vw;
  max-width: 100vw;
  background-color: transparent;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.toastModelBody .modal {
  position: fixed;
  width: 0;
}
.toastModelBody .modal .fade {
  width: 0;
}
.toastModel {
  width: 0;
  display: flex;
  justify-content: flex-end;
}
.toastModel .modal-content {
  position: fixed;
  top: 12px;
  right: 12px;
  display: flex;
  justify-content: flex-end;
  width: 0;
}
/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */
/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-left {
  top: 12px;
  left: 12px;
}
.toast-top-right {
  top: 12px;
  right: 12px;
}
.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}
.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}
/* toast styles */
.toast-title {
  font-weight: bold;
}
.toast-message {
  word-wrap: break-word;
}
.toast-message a,
.toast-message label {
  color: #FFFFFF;
}
.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}
.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}
.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}
/*Additional properties for button version
   iOS requires the button element instead of an anchor tag.
   If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}
.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}
.toast-container * {
  box-sizing: border-box;
}
.toast-container .toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}
.toast-container .toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOS4wNDMgOCA4IDExOS4wODMgOCAyNTZjMCAxMzYuOTk3IDExMS4wNDMgMjQ4IDI0OCAyNDhzMjQ4LTExMS4wMDMgMjQ4LTI0OEM1MDQgMTE5LjA4MyAzOTIuOTU3IDggMjU2IDh6bTAgMTEwYzIzLjE5NiAwIDQyIDE4LjgwNCA0MiA0MnMtMTguODA0IDQyLTQyIDQyLTQyLTE4LjgwNC00Mi00MiAxOC44MDQtNDIgNDItNDJ6bTU2IDI1NGMwIDYuNjI3LTUuMzczIDEyLTEyIDEyaC04OGMtNi42MjcgMC0xMi01LjM3My0xMi0xMnYtMjRjMC02LjYyNyA1LjM3My0xMiAxMi0xMmgxMnYtNjRoLTEyYy02LjYyNyAwLTEyLTUuMzczLTEyLTEydi0yNGMwLTYuNjI3IDUuMzczLTEyIDEyLTEyaDY0YzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MTAwaDEyYzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MjR6Jy8+PC9zdmc+");
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOSA4IDggMTE5IDggMjU2czExMSAyNDggMjQ4IDI0OCAyNDgtMTExIDI0OC0yNDhTMzkzIDggMjU2IDh6bTEyMS42IDMxMy4xYzQuNyA0LjcgNC43IDEyLjMgMCAxN0wzMzggMzc3LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwyNTYgMzEybC02NS4xIDY1LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwxMzQuNCAzMzhjLTQuNy00LjctNC43LTEyLjMgMC0xN2w2NS42LTY1LTY1LjYtNjUuMWMtNC43LTQuNy00LjctMTIuMyAwLTE3bDM5LjYtMzkuNmM0LjctNC43IDEyLjMtNC43IDE3IDBsNjUgNjUuNyA2NS4xLTY1LjZjNC43LTQuNyAxMi4zLTQuNyAxNyAwbDM5LjYgMzkuNmM0LjcgNC43IDQuNyAxMi4zIDAgMTdMMzEyIDI1Nmw2NS42IDY1LjF6Jy8+PC9zdmc+");
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTE3My44OTggNDM5LjQwNGwtMTY2LjQtMTY2LjRjLTkuOTk3LTkuOTk3LTkuOTk3LTI2LjIwNiAwLTM2LjIwNGwzNi4yMDMtMzYuMjA0YzkuOTk3LTkuOTk4IDI2LjIwNy05Ljk5OCAzNi4yMDQgMEwxOTIgMzEyLjY5IDQzMi4wOTUgNzIuNTk2YzkuOTk3LTkuOTk3IDI2LjIwNy05Ljk5NyAzNi4yMDQgMGwzNi4yMDMgMzYuMjA0YzkuOTk3IDkuOTk3IDkuOTk3IDI2LjIwNiAwIDM2LjIwNGwtMjk0LjQgMjk0LjQwMWMtOS45OTggOS45OTctMjYuMjA3IDkuOTk3LTM2LjIwNC0uMDAxeicvPjwvc3ZnPg==");
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1NzYgNTEyJyB3aWR0aD0nNTc2JyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTU2OS41MTcgNDQwLjAxM0M1ODcuOTc1IDQ3Mi4wMDcgNTY0LjgwNiA1MTIgNTI3Ljk0IDUxMkg0OC4wNTRjLTM2LjkzNyAwLTU5Ljk5OS00MC4wNTUtNDEuNTc3LTcxLjk4N0wyNDYuNDIzIDIzLjk4NWMxOC40NjctMzIuMDA5IDY0LjcyLTMxLjk1MSA4My4xNTQgMGwyMzkuOTQgNDE2LjAyOHpNMjg4IDM1NGMtMjUuNDA1IDAtNDYgMjAuNTk1LTQ2IDQ2czIwLjU5NSA0NiA0NiA0NiA0Ni0yMC41OTUgNDYtNDYtMjAuNTk1LTQ2LTQ2LTQ2em0tNDMuNjczLTE2NS4zNDZsNy40MTggMTM2Yy4zNDcgNi4zNjQgNS42MDkgMTEuMzQ2IDExLjk4MiAxMS4zNDZoNDguNTQ2YzYuMzczIDAgMTEuNjM1LTQuOTgyIDExLjk4Mi0xMS4zNDZsNy40MTgtMTM2Yy4zNzUtNi44NzQtNS4wOTgtMTIuNjU0LTExLjk4Mi0xMi42NTRoLTYzLjM4M2MtNi44ODQgMC0xMi4zNTYgNS43OC0xMS45ODEgMTIuNjU0eicvPjwvc3ZnPg==");
}
.toast-container.toast-top-center .toastr,
.toast-container.toast-bottom-center .toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}
.toast-container.toast-top-full-width .toastr,
.toast-container.toast-bottom-full-width .toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}
.toastr {
  background-color: #030303;
  pointer-events: auto;
}
.toast-success {
  background-color: #51A351;
}
.toast-error {
  background-color: #BD362F;
}
.toast-info {
  background-color: #2F96B4;
}
.toast-warning {
  background-color: #F89406;
}
.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}
/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
.add-new-ass-que-left {
  width: 65%;
  max-height: 141vh;
  overflow-y: auto;
  overflow-x: unset;
}
.new-ass-que-right .user-details-title {
  font-size: 26px;
  font-weight: 700;
  text-transform: capitalize;
  margin: 0 0 6px 0;
}
.new-user-left-content {
  border-bottom: 1px dashed #dad9dc;
}
.new-user-left-content li {
  width: 100%;
  padding-bottom: 8px;
}
.user-details-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.user-input-div-details {
  width: 50%;
  padding: 5px 15px;
}
.user-input-div-details label {
  margin-bottom: 8px;
}
.user-input-div-details input {
  background: transparent;
  border: 1px solid #ffffff;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  padding: 6px;
  width: 100%;
}
.span a {
  color: #ffffff;
}
.graph-container {
  width: 100%;
  display: inline-block;
}
.graph-block {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
  width: auto !important;
  padding: 0 !important;
}
.Graph-box {
  width: 100%;
  margin: 0 12px;
  padding: 20px !important;
  float: left;
  background: #ffffff !important;
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06);
  color: black !important;
}
.user-details-header {
  display: none;
}
.dashboard-wrapper {
  padding: 24px 24px 0 30px;
}
.dashboard-wrapper .dashboard-title {
  margin-bottom: 32px;
}
.dashboard-wrapper .dashboard-title p {
  color: #000;
  font-size: 24px;
  font-weight: 600;
}
.dashboard-wrapper .dashboard-content {
  margin: 0 -24px;
}
.dashboard-wrapper .dashboard-content .dashboard-box {
  width: calc(20% - 48px);
  margin: 0 24px 48px 24px;
}
.model-popup-wrapper.dashboard-popup .model-popup {
  width: 1371px;
}
.model-popup-wrapper.dashboard-popup .dashboard-content {
  margin: 0 -24px;
}
.model-popup-wrapper.dashboard-popup .dashboard-content .dashboard-box {
  width: calc(25% - 48px);
  margin: 0 24px 48px 24px;
}
.header-dashboard a {
  color: #e5702d;
  font-size: 16px;
  font-weight: 500;
}
@media (max-width: 1400px) {
  .model-popup-wrapper.dashboard-popup .model-popup {
    width: calc(100% - 30px);
    margin: 0 auto;
  }
  .model-popup-wrapper.dashboard-popup .dashboard-content {
    margin: 0 -24px;
  }
  .model-popup-wrapper.dashboard-popup .dashboard-content .dashboard-box {
    width: calc(25% - 48px);
    margin: 0 24px 48px 24px;
  }
}
@media (max-width: 1024px) {
  .dashboard-wrapper .dashboard-content {
    margin: 0 -15px;
  }
  .dashboard-wrapper .dashboard-content .dashboard-box {
    width: calc(25% - 30px);
    margin: 0 15px 30px 15px;
  }
}
@media (max-width: 991px) {
  .model-popup-wrapper.dashboard-popup .dashboard-content {
    margin: 0 -15px;
  }
  .model-popup-wrapper.dashboard-popup .dashboard-content .dashboard-box {
    width: calc(33.33% - 30px);
    margin: 0 15px 30px 15px;
  }
  .header-dashboard {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .dashboard-wrapper {
    padding: 12px 15px;
  }
  .dashboard-wrapper .dashboard-content {
    margin: 0 -15px;
  }
  .dashboard-wrapper .dashboard-content .dashboard-box {
    width: calc(50% - 30px);
    margin: 0 15px 30px 15px;
  }
  .model-popup-wrapper.dashboard-popup .dashboard-content {
    margin: 0 -15px;
  }
  .model-popup-wrapper.dashboard-popup .dashboard-content .dashboard-box {
    width: calc(50% - 30px);
  }
}
.message-heading h2 {
  color: #231f20;
  font-size: 26px;
  font-weight: bold;
}
.message-heading button {
  width: 90px;
  height: 42px;
  background: #fa7a23;
  border: none;
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  margin-left: 20px;
}
.message-board-content {
  background: #fff;
  box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.06);
  padding: 16px;
  max-height: calc(100vh - 150px);
  overflow: auto;
}
.message-board-content .message-heading-sub {
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
}
.message-board-content .message-board-search input {
  height: 42px;
  border: 1px solid #231f20;
  padding: 0 12px;
  font-size: 14px;
  width: calc(100% - 110px);
}
.message-board-content .message-board-search .message-search-btn {
  width: 90px;
  height: 42px;
  background: #fa7a23;
  border: none;
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  margin-left: 20px;
}
.message-board-content .message-board-category-box .message-board-category-title {
  padding: 12px 16px;
  background: var(--White, #fff);
  box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.06);
}
.message-board-content .message-board-category-box .message-board-category-title .message-board-category-title-left {
  width: calc(100% - 410px);
}
.message-board-content .message-board-category-box .message-board-category-title .message-board-category-title-left p {
  color: #2c5c84;
  font-size: 12px;
  font-weight: 600;
}
.message-board-content .message-board-category-box .message-board-category-title .message-board-category-title-right {
  width: 400px;
  margin-left: 10px;
  justify-content: flex-end;
}
.message-board-content .message-board-category-box .message-board-category-title .message-board-category-title-right .total-subject {
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.message-board-content .message-board-category-box .message-board-category-title .message-board-category-title-right .total-subject span {
  height: 24px;
  min-width: 24px;
  background: #fa7a23;
  color: #fff;
  border-radius: 50%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  margin-left: 5px;
  padding: 0 2px;
}
.message-board-content .message-board-category-box .message-board-category-content {
  border: 1px solid #2c5c84;
  background: #fff;
  box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.06);
  padding: 16px 8px;
  display: none !important;
}
.message-board-content .message-board-category-box .message-board-category-content .message-board-category-content-top {
  width: 100%;
}
.message-board-content .message-board-category-box .message-board-category-content .message-board-category-content-top .message-board-category-content-top-box {
  width: calc(50% - 16px);
  margin: 0 8px 16px 8px;
  background: #f2f2f2;
  padding: 16px 8px 8px 16px;
}
.message-board-content .message-board-category-box .message-board-category-content .message-board-category-content-top .message-board-category-content-top-box p {
  color: #717171;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 4px;
}
.message-board-content .message-board-category-box .message-board-category-content .message-board-category-content-top .message-board-category-content-top-box p a {
  color: #fa7a23;
}
.message-board-content .message-board-category-box .message-board-category-content .message-board-category-content-top .message-board-category-content-top-box span {
  font-size: 14px;
  font-weight: bold;
}
.message-board-content .message-board-category-box .message-board-category-content .message-board-category-content-top .message-board-category-content-top-box span label {
  color: #6f6f6f;
  font-size: 12px;
  font-weight: 400;
}
.message-board-content .message-board-category-box .message-board-category-content .message-board-category-content-top .message-board-category-content-top-box .redirect-btn {
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 0;
  border: none;
  font-size: 12px;
  text-decoration: underline;
  color: #fa7a23;
}
.message-board-content .message-board-category-box .message-board-category-content .message-board-category-content-bottom {
  padding-right: 8px;
}
.message-board-content .message-board-category-box .message-board-category-content .message-board-category-content-bottom .add-subject {
  width: 120px;
  height: 42px;
  background: #231f20;
  color: #fff;
  font-size: 16px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.message-board-content .message-board-category-box .message-board-category-content .message-board-category-content-bottom .add-subject.subsubject {
  width: 157px;
}
.message-board-content .message-board-category-box.active-catagory .message-board-category-title {
  background: #2c5c84;
  box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.06);
}
.message-board-content .message-board-category-box.active-catagory .message-board-category-title .message-board-category-title-left p {
  color: #fff;
}
.message-board-content .message-board-category-box.active-catagory .message-board-category-title .message-board-category-title-left svg path {
  fill: #fff;
}
.message-board-content .message-board-category-box.active-catagory .message-board-category-title .message-board-category-title-right svg {
  transform: rotate(180deg);
}
.message-board-content .message-board-category-box.active-catagory .message-board-category-title .message-board-category-title-right svg path {
  fill: #fff;
}
.message-board-content .message-board-category-box.active-catagory .message-board-category-title .message-board-category-title-right .total-subject {
  color: #fff;
}
.message-board-content .message-board-category-box.active-catagory .message-board-category-content {
  display: flex !important;
}
.chatbox-waraaper .chatbox-inner-scroll {
  max-width: 100%;
  max-height: calc(100vh - 342px);
  overflow: auto;
  min-height: calc(100vh - 342px);
  display: flex;
  flex-wrap: wrap;
}
.chatbox-waraaper .chatbox-inner {
  width: calc(100% - 500px);
}
.chatbox-waraaper .chatbox-inner .chatbox-box .chatbox-box-left {
  width: 136px;
}
.chatbox-waraaper .chatbox-inner .chatbox-box .chatbox-box-left img {
  max-width: 100%;
  height: auto;
  border-radius: 16px;
}
.chatbox-waraaper .chatbox-inner .chatbox-box .chatbox-box-left p {
  text-align: center;
  color: #000;
  font-size: 12px;
  font-weight: 600;
}
.chatbox-waraaper .chatbox-inner .chatbox-box .chatbox-box-right {
  width: calc(100% - 245px);
  padding: 0 16px;
  position: relative;
}
.chatbox-waraaper .chatbox-inner .chatbox-box .chatbox-box-right .chatbox-content {
  padding: 12px;
  border-radius: 0px 10px 10px 10px;
}
.chatbox-waraaper .chatbox-inner .chatbox-box .chatbox-box-right .chatbox-content .received_withd_msg span {
  font-size: 12px;
  color: #7e7e7e;
  margin-top: 8px;
}
.chatbox-waraaper .chatbox-inner .chatbox-box .chatbox-box-right .chatbox-content .received_withd_msg ul {
  padding-left: 2rem;
}
.chatbox-waraaper .chatbox-inner .chatbox-box .chatbox-box-right .chatbox-content .received_withd_msg_reply span {
  font-size: 12px;
  color: #242632;
  margin-top: 8px;
}
.chatbox-waraaper .chatbox-inner .chatbox-box .chatbox-box-right .chatbox-content .received_withd_msg_reply ul {
  padding-left: 2rem;
}
.chatbox-waraaper .chatbox-inner .chatbox-box .chatbox-box-right .chatbox-content .chatbox-box-btn {
  position: absolute;
  top: 0;
  right: -53px;
  top: 10px;
}
.chatbox-waraaper .chat-message-box {
  max-width: 1100px;
  margin: 0 auto;
  position: relative;
}
.chatbox-waraaper .chat-message-box .ck-body-wrapper.email-text-ckeditor {
  background: #fff;
  box-shadow: 0px 4px 20px 0px #ededed;
}
.chatbox-waraaper .chat-message-box .ck-body-wrapper.email-text-ckeditor .ck.ck-toolbar {
  background: #fff;
}
.chatbox-waraaper .chat-message-box .ck-body-wrapper.email-text-ckeditor .ck.ck-toolbar .ck.ck-toolbar__items {
  margin-left: 34px;
}
.chatbox-waraaper .chat-message-box .ck-body-wrapper.email-text-ckeditor strong u {
  font-weight: bolder;
}
.chatbox-waraaper .chat-message-box .sent-btn {
  height: 38px;
  width: 38px;
  background: #231f20;
  border: none;
  position: absolute;
  top: 16px;
  right: 26px;
}
.chatbox-waraaper .chat-message-box-reply {
  max-width: 1078px;
  margin: 0 auto;
  position: relative;
  padding: 5px;
  border-radius: 8px;
  background: #edededfc;
}
.chatbox-waraaper .chat-message-box-reply .sent-btn {
  height: 38px;
  width: 38px;
  background: #231f20;
  border: none;
  position: absolute;
  top: 40px;
  right: 26px;
}
.chatbox-waraaper .chatbox-subject-detail {
  width: 500px;
}
.chatbox-waraaper .chatbox-subject-detail .chatbox-subject-detail-inner {
  position: sticky;
  top: 0;
  background: #f6f6f6;
  padding: 12px;
  margin-right: 10px;
}
.chatbox-waraaper .chatbox-subject-detail .chatbox-subject-detail-inner .chatbox-subject-detail-inner-box p {
  width: 110px;
  font-size: 16px;
  font-weight: 600;
}
.chatbox-waraaper .chatbox-subject-detail .chatbox-subject-detail-inner .chatbox-subject-detail-inner-box span {
  width: calc(100% - 110px);
  font-size: 16px;
}
.des-height {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.ck-editor-fileupload {
  position: absolute;
  top: 17px;
  left: 26px;
  background: transparent;
  border: none;
  padding: 0;
}
.file-upload-list-wrapper-messageboard {
  max-width: 1070px;
  margin: 15px auto auto auto;
}
.file-upload-list-wrapper-messageboard ul {
  margin: 0 -8px;
}
.file-upload-list-wrapper-messageboard ul li {
  width: calc(25% - 16px);
  margin: 0 8px 8px 8px;
}
.file-upload-list-wrapper-messageboard ul li p {
  width: calc(100% - 20px);
  padding-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.file-upload-list-wrapper-messageboard ul li button {
  border: none;
  background: transparent;
  padding: 0;
}
.images-delete-wrapper img {
  max-width: 200px;
}
.reply-content {
  height: 19px;
  overflow: hidden;
}
.message-box-right-content-iner.main-message-notes-NA {
  display: none;
}
@media (max-width: 1200px) {
  .chatbox-waraaper .chatbox-inner {
    order: 1;
    width: 100%;
    margin-top: 30px;
  }
  .chatbox-waraaper .chatbox-subject-detail .chatbox-subject-detail-inner {
    margin-right: 0;
  }
}
@media (max-width: 991px) {
  .message-board-content .message-board-category-box .message-board-category-content .message-board-category-content-top .message-board-category-content-top-box {
    width: calc(100% - 16px);
  }
}
@media (max-width: 767px) {
  .message-board-content .message-board-category-box .message-board-category-content .message-board-category-content-top .message-board-category-content-top-box {
    width: 100%;
    margin: 0 0 16px 0;
  }
  .message-board-content .message-board-category-box .message-board-category-content .message-board-category-content-top .message-board-category-content-top-box .w-30,
  .message-board-content .message-board-category-box .message-board-category-content .message-board-category-content-top .message-board-category-content-top-box .w-40,
  .message-board-content .message-board-category-box .message-board-category-content .message-board-category-content-top .message-board-category-content-top-box .w-70 {
    width: 100% !important;
    padding-right: 0 !important;
  }
  .message-board-content .message-board-category-box .message-board-category-title {
    position: relative;
  }
  .message-board-content .message-board-category-box .message-board-category-title .message-board-category-title-left {
    width: 100%;
  }
  .message-board-content .message-board-category-box .message-board-category-title .message-board-category-title-right .total-subject {
    width: 100%;
    justify-content: flex-end;
    margin-right: 0 !important;
  }
  .message-board-content .message-board-category-box .message-board-category-title .message-board-category-title-right .total-subject:first-child {
    margin-bottom: 10px;
  }
  .message-board-content .message-board-category-box .message-board-category-title .message-board-category-title-right .accordian-arrow {
    position: absolute;
    top: 13px;
    right: 10px;
  }
}
@media (max-width: 1600px) {
  .resource-wrapper .resource-inner .resource-box {
    width: calc(50% - 24px);
  }
}
@media (min-width: 1300px) and (max-width: 1600px) {
  .main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.assessment {
    width: 9%;
  }
  .main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.assign {
    width: 7%;
  }
  .main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.email {
    width: 16%;
  }
  .form-control-main .tooltip-inner {
    width: 600px;
  }
}
@media (min-width: 1366px) {
  .new-ass-que-wrapper .new-ass-que-left {
    max-height: calc(100vh - 142px);
  }
  .new-ass-que-wrapper .new-ass-que-right {
    max-height: calc(100vh - 142px);
    overflow-y: auto;
    overflow-x: hidden;
  }
}
@media (max-width: 1366px) {
  .new-ass-que-wrapper .new-ass-que-left {
    width: 100%;
    margin-bottom: 20px;
  }
  .new-ass-que-wrapper .new-ass-que-right {
    width: 100%;
    padding-left: 0;
  }
  .agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right {
    padding: 40px;
  }
  .form-control-main .tooltip-inner {
    width: 600px;
  }
  .new-classes-wrapper .new-classes-right {
    width: 300px;
  }
  .new-classes-wrapper .new-classes-right .agent-content-box .agent-content-box-wrapper-new .agent-content-box-left {
    width: 110px;
  }
  .new-classes-wrapper .new-classes-right .agent-content-box .agent-content-box-wrapper-new .agent-content-box-right {
    width: calc(100% - 110px);
  }
  .new-classes-wrapper .new-classes-middle {
    width: calc(100% - 550px);
  }
  .new-classes-wrapper .new-classes-right .agent-content-box.video-upload .agent-content-box-wrapper-new .agent-content-box-left video {
    width: 100%;
  }
  .new-canva-right-table-content-visible .new-canva-right-table-content-visible-left {
    width: 400px;
  }
  .new-canva-right-table-content-visible .new-canva-right-table-content-visible-right {
    width: calc(100% - 400px);
  }
}
@media (max-width: 1290px) {
  .new-classes-wrapper .new-classes-left {
    width: 100%;
    margin-bottom: 20px;
  }
  .new-classes-wrapper .new-classes-middle {
    width: 100%;
    margin: 0 0 20px 0;
  }
  .new-classes-wrapper .new-classes-right {
    width: 100%;
  }
  .prec-detail-wrapper .prec-detail-wrapper-left {
    width: 100%;
    margin-bottom: 30px;
    margin-right: 0;
    max-height: unset;
    overflow: unset;
  }
  .prec-detail-wrapper .prec-detail-wrapper-right {
    width: 100%;
  }
  .prec-detail-wrapper .prec-detail-wrapper-right .pre-call-content-box-wrapper {
    max-height: unset;
    overflow: unset;
  }
  .new-canva-right {
    width: 100%;
    margin-left: 0;
  }
}
@media (max-width: 1200px) {
  .agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right .profit-advisor-top .profit-advisor-left {
    width: 100%;
    margin-bottom: 20px;
    padding-right: 0;
  }
  .agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right .profit-advisor-top .profit-advisor-right {
    width: 100%;
    padding-left: 0;
  }
}
@media (max-width: 1024px) {
  .main-wrapper .assessment-bottom .assesment-table {
    width: 100%;
    overflow: auto;
  }
  .main-wrapper .assessment-bottom .assesment-table .assesment-table-content {
    height: calc(100vh - 430px);
  }
  .main-wrapper .assessment-bottom .assesment-table-inner {
    width: 1200px;
  }
  .main-wrapper .assessment-bottom .assement-search {
    width: 100%;
    margin-bottom: 10px;
  }
  .main-wrapper .assessment-bottom .profit-filter {
    width: 100%;
    margin: 0;
    margin-bottom: 10px;
  }
  .main-wrapper .assessment-bottom .assement-select {
    padding-left: 0;
  }
  .main-wrapper .assessment-bottom .assesment-button {
    width: calc(100% - 357px);
    text-align: right;
  }
  .new-user-detail-wrapper .new-user-detail-left {
    width: 100%;
    padding-right: 0;
    border-right: none;
    margin-bottom: 20px;
  }
  .new-user-detail-wrapper .new-user-detail-left a {
    word-break: break-all;
  }
  .new-user-detail-wrapper .new-user-detail-right {
    width: 100%;
    padding-left: 0;
  }
  .main-wrapper .assessment-bottom .agent-profit-adviser .assement-search {
    width: calc(100% - 100px);
    margin-bottom: 0;
    padding-right: 10px;
  }
  .main-wrapper .assessment-bottom .agent-profit-adviser .assesment-button {
    width: 90px;
  }
  .inquiry-search-heade .assement-search.profit-filter {
    padding-right: 0;
  }
  .inquiry-search-heade .inquiry-search-wrapper {
    width: 100%;
  }
  .main-wrapper .assessment-bottom .inquiry-search-heade .assesment-button {
    width: 90px;
  }
}
@media (max-width: 991px) {
  .signup-input-div.phoneInput .iti {
    width: 100%;
  }
  .signup-input-div.phoneInput .iti input {
    width: 100%;
  }
  .main-banner-wrapper .main-banner-right iframe {
    width: 100%;
  }
  .signup-img iframe {
    width: 100% !important;
  }
  .main-wrapper .assessment-bottom {
    padding: 20px;
  }
  .question-list-wrapper .question-inner .question-list-main .question-list .question-list-content-wrapper .question-list-content {
    width: calc(100% - 40px);
  }
  .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content ul li.ass-questions {
    width: calc(100% - 30px);
  }
  .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content ul li.hint {
    margin-right: 0;
  }
  .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content ul li.answers {
    width: calc(100% - 80px);
    margin-top: 20px;
  }
  .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content ul li.percentage {
    margin-top: 20px;
  }
  .model-popup-wrapper .model-popup-inner {
    width: 100%;
  }
  .model-popup-wrapper .model-popup {
    width: calc(100% - 30px);
    margin: 0 auto;
  }
  .agent-sign-up-wrapper {
    height: auto;
  }
  .agent-sign-up-wrapper:after,
  .agent-sign-up-wrapper:before {
    background-size: 175px;
    height: 175px;
    width: 175px;
  }
  .agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-left {
    width: 100%;
    margin: 40px 0;
  }
  .agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-left .agent-sign-up-left-bottom img,
  .agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-left .agent-sign-up-left-bottom iframe {
    height: 340px;
  }
  .agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-left .agent-sign-up-left-top {
    margin-bottom: 40px;
  }
  .agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right {
    width: 100%;
    margin: 0 0 50px 0;
  }
  .agent-classes-detail .agent-profit-plan-bottom .plan-type-header {
    background: #eeeeee;
    padding: 16px;
    display: block;
  }
  .agent-classes-detail .agent-profit-plan-bottom .plan-type-header.accordian-up-img + .agent-topbar-wrapper {
    display: flex !important;
  }
  .agent-classes-detail .agent-profit-plan-bottom .plan-type-header.accordian-up-img img {
    transform: rotate(180deg);
  }
  .agent-classes-detail .agent-profit-plan-bottom .agent-topbar-wrapper {
    display: none !important;
  }
  .agent-classes-detail .agent-profit-plan-bottom .agent-topbar-wrapper a {
    width: 100%;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.action-profit-ad.delete:before {
    content: 'Delete :';
  }
  body .main-wrapper .assessment-bottom.inquiry-assessment-bottom .assement-search.profit-filter select {
    border-radius: 0;
  }
  .new-canva-right-table-content-visible .new-canva-right-table-content-visible-left {
    width: 300px;
  }
  .new-canva-right-table-content-visible .new-canva-right-table-content-visible-right {
    width: calc(100% - 300px);
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  body .main-wrapper .assessment-bottom {
    padding: 20px 15px;
  }
  body .main-wrapper .assessment-bottom .assement-select {
    width: calc(100% - 90px);
  }
  body .main-wrapper .assessment-bottom .assesment-button {
    width: 90px;
    margin-left: 0 !important;
  }
  body .main-wrapper .assessment-bottom .assesment-table-inner {
    width: unset;
  }
  body .main-wrapper .assessment-bottom .assesment-table {
    padding: 0;
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-header {
    display: none;
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content {
    height: calc(100vh - 174px) !important;
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content ul.table-main-content {
    padding: 10px 22px 10px 10px;
    position: relative;
    margin: 0 -5px;
    box-sizing: border-box;
    width: 100%;
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-top {
    padding: 10px;
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-top .top-assessments,
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-top .top-created-date,
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-top .top-updated-date {
    display: none;
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-top .assessment-list-top-ul .top-ass-btn {
    width: 100% !important;
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-bottom ul {
    padding-left: 10px;
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-bottom ul li:before {
    display: inline-block;
    font-weight: 600;
    margin-right: 10px;
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-bottom ul .bot-new-name:before {
    content: 'Assessments : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-bottom ul .bot-created-date:before {
    content: 'Created Date : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-bottom ul .bot-updated-date:before {
    content: 'Updated Date : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-bottom ul .bot-new-edit:before {
    content: 'Edit :';
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-bottom ul .bot-new-download:before {
    content: 'Download :';
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-bottom ul .bot-new-delete:before {
    content: 'Delete :';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li {
    width: calc(50% - 10px) !important;
    padding: 0 !important;
    margin: 5px;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li:before {
    display: inline-block;
    font-weight: 600;
    margin-right: 10px;
    pointer-events: none;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.age-agent-name:before {
    content: 'Agent name : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.inq-profit-advisor:before {
    content: 'Profit Advisor : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.gui-user:before {
    content: 'User Name : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.gui-count:before {
    content: 'Count : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.gui-date:before {
    content: 'Created Date : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.gui-gui:before {
    content: 'Is Guided : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.inq-name:before {
    content: 'Name : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.inq-email:before {
    content: 'Email : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.inq-phone-number {
    text-align: left !important;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.inq-phone-number:before {
    content: 'Phone Number : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.inq-inquiry-date {
    text-align: left !important;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.inq-inquiry-date:before {
    content: 'Inquiry Date : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.inq-sign-in {
    text-align: left !important;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.inq-sign-in:before {
    content: 'Sign In : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.inq-assign {
    text-align: left !important;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.age-assessment {
    text-align: left !important;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.age-assessment:before {
    content: 'Assessment : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.age-edit {
    text-align: left !important;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.age-edit:before {
    content: 'Edit : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.age-delete {
    text-align: left !important;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.age-delete:before {
    content: 'Delete : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.inq-action {
    text-align: left !important;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.inq-action:before {
    content: 'Action : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.age-assessmentcompany-name:before {
    content: 'Loan Officer Name : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.email:before {
    content: 'Email : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.uri:before {
    content: 'URI : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.phone-number:before {
    content: 'phone-number : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.sign-up-date:before {
    content: 'Sign Up Date : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.event-end:before {
    content: 'Event End : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.edit {
    text-align: left;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.edit:before {
    content: 'Edit : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.reset {
    text-align: left !important;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.reset:before {
    content: 'Reset : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.new-action {
    text-align: left !important;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.new-action:before {
    content: 'Show Agent : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.new-action .showAgent-btn {
    display: inline-block;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.event-start:before {
    content: 'Event Start : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.assessment {
    text-align: left;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.assessment:before {
    content: 'Assessment : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.delete {
    text-align: left;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.delete:before {
    content: 'Action : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.action {
    position: absolute;
    top: 10px;
    right: 5px;
    width: auto !important;
  }
  body .main-wrapper .assessment-bottom .question-header .add-new:first-child {
    margin-left: 0;
  }
  body .main-wrapper .assessment-bottom.agent-asst-bottom .assement-search {
    width: calc(100% - 105px);
    margin-right: 15px;
    margin-bottom: 0;
  }
  body .main-wrapper .assessment-bottom.faq-asst-bottom .question-list-wrapper .question-inner .question-list-main .question-list .question-list-head h3 {
    width: calc(100% - 100px);
  }
  body .main-wrapper .assessment-bottom.agent-assessment-top .assement-search {
    width: calc(100% - 105px);
    margin-right: 15px;
    margin-bottom: 0;
  }
  body .main-wrapper .assessment-bottom.agent-assessment-top .assesment-button a {
    height: 40px;
    line-height: 40px;
  }
  body .main-wrapper .assessment-bottom.agent-assessment-top .assesment-table .assesment-table-content {
    height: calc(100vh - 273px) !important;
  }
  body .main-wrapper .assessment-bottom.inquiry-assessment-bottom .assement-search:not(.profit-filter) {
    width: calc(100% - 105px);
    margin-right: 15px;
    margin-bottom: 0;
  }
  body .main-wrapper .assessment-bottom.inquiry-assessment-bottom .assement-search.profit-filter select {
    padding-right: 25px;
  }
  body .main-wrapper .assessment-bottom.agent-classes-detail .agent-profit-plan-classes .agent-profit-plan-classes-left > div p {
    max-height: 123px;
    overflow: auto;
  }
  body .main-wrapper .custom-radio {
    width: 100%;
    padding-right: 0;
    margin-bottom: 10px;
  }
  body .main-wrapper .custom-radio:last-child {
    margin-bottom: 0;
  }
  body .main-wrapper .test-assessment-ipad .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-head ul li.hint,
  body .main-wrapper .test-assessment-ipad .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-head ul li.percentage,
  body .main-wrapper .test-assessment-ipad .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-head ul li.answers {
    display: none;
  }
  body .main-wrapper .test-assessment-ipad .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content ul li.answers {
    width: calc(100% - 90px);
  }
  body .main-wrapper .test-assessment-ipad .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content ul li.percentage {
    text-align: right;
  }
  body .main-wrapper .test-assessment-ipad .new-ass-que-wrapper .new-ass-que-inner > .user-details-header {
    padding: 0;
    margin-bottom: 15px;
  }
  body .main-wrapper .test-assessment-ipad .new-ass-que-wrapper .user-details-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: #231f20;
    color: #ffffff;
    padding: 16px;
  }
  body .main-wrapper .test-assessment-ipad .new-ass-que-wrapper .user-details-header h1 {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 0;
  }
  body .main-wrapper .test-assessment-ipad .new-ass-que-wrapper .new-ass-que-right .new-ass-que-right-top {
    padding: 0;
    margin-bottom: 0;
  }
  body .main-wrapper .test-assessment-ipad .new-ass-que-wrapper .new-ass-que-right .user-detail-form .new-ass-que-right-top .new-user-left-content,
  body .main-wrapper .test-assessment-ipad .new-ass-que-wrapper .new-ass-que-right .user-detail-form .new-ass-que-right-top .user-details-container {
    display: none;
  }
  body .main-wrapper .test-assessment-ipad .new-ass-que-wrapper .new-ass-que-right .user-detail-form .new-ass-que-right-top .new-user-left-content {
    padding: 16px;
  }
  body .main-wrapper .test-assessment-ipad .new-ass-que-wrapper .new-ass-que-right .user-detail-form .new-ass-que-right-top .new-user-left-content ul li .imageRoate {
    display: none;
  }
  body .main-wrapper .test-assessment-ipad .new-ass-que-wrapper .new-ass-que-right .user-detail-form .new-ass-que-right-top .user-details-container {
    padding-bottom: 16px;
  }
  body .main-wrapper .test-assessment-ipad .new-ass-que-wrapper .new-ass-que-right .user-detail-form .new-ass-que-right-top .user-details-container .user-input-div-details {
    width: 100%;
  }
  body .main-wrapper .test-assessment-ipad .new-ass-que-wrapper .new-ass-que-right .user-detail-form.active-form .new-ass-que-right-top .new-user-left-content,
  body .main-wrapper .test-assessment-ipad .new-ass-que-wrapper .new-ass-que-right .user-detail-form.active-form .new-ass-que-right-top .user-details-container {
    display: block;
  }
  body .main-wrapper .test-assessment-ipad .new-ass-que-wrapper .new-ass-que-left + .new-ass-que-right .user-detail-form {
    display: none;
  }
  body .main-wrapper .test-assessment-ipad .form-control-main.sign-in-btn {
    margin: 20px 0;
  }
  body .main-wrapper .test-assessment-ipad .new-assessment-bottom .new-ass-que-wrapper .new-ass-que-left + .new-ass-que-right .user-detail-form {
    display: none;
  }
  body .main-wrapper .assessment-topbar {
    background: #f8f8f8;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 12px 15px;
  }
  body .main-wrapper .assessment-topbar .mobile-header {
    display: block;
  }
  body .main-wrapper .assessment-topbar .mobile-header a {
    display: inline-block;
  }
  body .main-wrapper .assessment-topbar .mobile-header .mobile-logo {
    margin-left: 15px;
  }
  body .main-wrapper .assessment-topbar .mobile-header .mobile-logo img {
    height: 40px;
  }
  body .main-wrapper .assessment-topbar .userinfo {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  body .main-wrapper .assessment-topbar .userinfo .dropdown-toggle span {
    white-space: nowrap;
    width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    text-align: left;
  }
  body .main-wrapper .assessment-inner .navigation {
    display: none;
    padding: 20px 15px;
    position: absolute;
    cursor: pointer;
    top: 64px;
    border-top: 2px solid #ffffff;
    left: 0;
    width: 100%;
    z-index: 1;
  }
  body .main-wrapper .assessment-inner .navigation .navigation-logo {
    display: none;
  }
  body .main-wrapper .assessment-inner .navigation nav .mobile-close {
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  body .main-wrapper .assessment-inner .assessment-body {
    width: 100%;
  }
  body .main-wrapper .assessment-inner.menu-open {
    position: relative;
  }
  body .main-wrapper .assessment-inner.menu-open .navigation {
    display: block;
  }
  .model-popup-wrapper.add-new-course-popup .question-bottom-wrapper .question-bottom-inner form .from-group {
    padding: 0 15px;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul.ui-agent-delete li.delete:before {
    content: 'Delete :';
  }
}
@media (max-width: 767px) {
  .sign-up-wrapper {
    min-height: 100vh;
  }
  .sign-up-wrapper .sign-up-left,
  .sign-up-wrapper .sign-up-right {
    width: 100% !important;
  }
  .sign-up-wrapper .sign-up-left {
    padding: 20px 15px;
    display: none;
  }
  .sign-up-wrapper .sign-up-right .sign-up-right-wrapper {
    padding: 20px 15px;
  }
  .sign-up-wrapper .sign-up-right .sign-up-right-wrapper .mobile-logo {
    display: block;
    margin-bottom: 30px;
  }
  .sign-up-wrapper .sign-up-right .sign-up-right-wrapper .mobile-logo img {
    width: 100px;
  }
  .main-heading {
    font-size: 35px;
    line-height: 38px;
    margin-bottom: 40px;
  }
  .form-control-main.sign-in-btn {
    margin: 30px 0;
  }
  body .main-wrapper .assessment-inner .navigation {
    display: none;
    padding: 20px 15px;
    position: absolute;
    cursor: pointer;
    top: 64px;
    border-top: 2px solid #ffffff;
    left: 0;
    width: 100%;
    z-index: 1;
  }
  body .main-wrapper .assessment-inner .navigation .navigation-logo {
    display: none;
  }
  body .main-wrapper .assessment-inner .navigation nav .mobile-close {
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  body .main-wrapper .assessment-inner .assessment-body {
    width: 100%;
  }
  body .main-wrapper .assessment-inner.menu-open {
    position: relative;
  }
  body .main-wrapper .assessment-inner.menu-open .navigation {
    display: block;
  }
  body .main-wrapper .assessment-topbar {
    background: #f8f8f8;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 12px 15px;
  }
  body .main-wrapper .assessment-topbar .mobile-header {
    display: block;
  }
  body .main-wrapper .assessment-topbar .mobile-header a {
    display: inline-block;
  }
  body .main-wrapper .assessment-topbar .mobile-header .mobile-logo {
    margin-left: 15px;
  }
  body .main-wrapper .assessment-topbar .mobile-header .mobile-logo img {
    height: 40px;
  }
  body .main-wrapper .assessment-topbar .userinfo {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  body .main-wrapper .assessment-topbar .userinfo .dropdown-toggle span {
    white-space: nowrap;
    width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    text-align: left;
  }
  body .main-wrapper .assessment-bottom {
    padding: 20px 15px;
  }
  body .main-wrapper .assessment-bottom .assement-select {
    width: calc(100% - 90px);
  }
  body .main-wrapper .assessment-bottom .assesment-button {
    width: 90px;
    margin-left: 0 !important;
  }
  body .main-wrapper .assessment-bottom .assesment-table-inner {
    width: unset;
  }
  body .main-wrapper .assessment-bottom .assesment-table {
    padding: 0;
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-header {
    display: none;
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content {
    height: unset;
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content ul.table-main-content {
    padding: 10px 22px 10px 10px;
    position: relative;
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-top {
    padding: 10px;
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-top .top-assessments,
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-top .top-created-date,
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-top .top-updated-date {
    display: none;
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-bottom ul {
    padding-left: 10px;
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-bottom ul li:before {
    display: inline-block;
    font-weight: 600;
    margin-right: 10px;
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-bottom ul .bot-new-name:before {
    content: 'Assessments : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-bottom ul .bot-created-date:before {
    content: 'Created Date : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-bottom ul .bot-updated-date:before {
    content: 'Updated Date : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-bottom ul .bot-new-edit:before {
    content: 'Edit :';
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-bottom ul .bot-new-download:before {
    content: 'Download :';
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-bottom ul .bot-new-delete:before {
    content: 'Delete :';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li {
    width: 100% !important;
    padding: 0 !important;
    margin: 5px;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li:before {
    display: inline-block;
    font-weight: 600;
    margin-right: 10px;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.company-name:before {
    content: 'Loan Officer Name : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.email:before {
    content: 'Email : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.phone-number:before {
    content: 'phone-number : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.sign-up-date:before {
    content: 'Sign Up Date : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.event-end:before {
    content: 'Event End : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.edit {
    text-align: left;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.edit:before {
    content: 'Edit : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.reset {
    text-align: left !important;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.reset:before {
    content: 'Reset : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.new-action {
    text-align: left !important;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.new-action:before {
    content: 'Show Agent : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.new-action .showAgent-btn {
    display: inline-block;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.event-start:before {
    content: 'Event Start : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.assessment {
    text-align: left;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.assessment:before {
    content: 'Assessment : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.delete {
    text-align: left;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.delete:before {
    content: 'Action : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.action {
    position: absolute;
    top: 10px;
    right: 5px;
    width: auto !important;
  }
  body .main-wrapper .assessment-bottom .question-header .add-new:first-child {
    margin-left: 0;
  }
  body .main-wrapper .custom-radio {
    width: 100%;
    padding-right: 0;
    margin-bottom: 10px;
  }
  body .main-wrapper .custom-radio:last-child {
    margin-bottom: 0;
  }
  .question-bottom-wrapper .question-bottom-inner form .from-group {
    width: 100%;
  }
  .new-user-detail-wrapper {
    padding: 15px;
  }
  .new-user-detail-wrapper .new-user-detail-left .new-user-left-user {
    width: 100%;
    margin-bottom: 20px;
  }
  .new-user-detail-wrapper .new-user-detail-left .new-user-left-content {
    width: 100%;
  }
  .new-user-detail-wrapper .new-user-detail-right .new-user-right-current {
    width: 100%;
  }
  .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content ul {
    padding: 0 15px;
  }
  .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content ul li.answers {
    padding-right: 10px;
    width: 100%;
  }
  .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content ul li.percentage {
    width: 100%;
  }
  .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content ul li.percentage select {
    margin: 0 10px 0 0;
  }
  .model-popup-wrapper .model-popup {
    padding: 20px;
  }
  .agent-sign-up-wrapper:after,
  .agent-sign-up-wrapper:before {
    background-size: 100px;
    height: 100px;
    width: 100px;
  }
  .agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-left .agent-sign-up-left-top {
    margin-bottom: 20px;
  }
  .agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-left .agent-sign-up-left-top img {
    max-width: 150px;
  }
  .agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-left .agent-sign-up-left-bottom img,
  .agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-left .agent-sign-up-left-bottom iframe {
    height: 250px;
  }
  .agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right {
    padding: 20px;
  }
  .agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right h2 {
    margin-bottom: 20px;
  }
  .agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right form .form-control-main {
    width: calc(100% - 24px);
  }
  .agent-sign-up-wrapper .agent-sign-up-inner .agent-sign-up-right form .form-control-main.profit-signup p {
    width: 100%;
    margin-bottom: 16px;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul.ui-agent-delete li.delete:before {
    content: 'Delete :';
  }
}
@media (max-width: 767px) {
  .new-ass-que-wrapper .user-input-div-details {
    width: 100%;
  }
  .new-ass-que-wrapper .user-details-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: #231f20;
    color: #ffffff;
    padding: 16px;
  }
  h1 {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 0;
  }
  .new-ass-que-wrapper .new-ass-que-right .new-ass-que-right-top {
    padding: 0;
  }
  .new-ass-que-wrapper .new-user-left-content {
    padding: 16px;
  }
  .new-assessment-bottom .new-ass-que-wrapper .new-ass-que-left {
    margin-top: 20px;
  }
  .new-assessment-bottom .new-ass-que-wrapper .new-ass-que-left h2 {
    font-size: 17px;
  }
  .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-head ul li.hint,
  .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-head ul li.answers,
  .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-head ul li.percentage {
    display: none;
  }
  .graph-container .graph-block {
    margin: 0;
  }
  .graph-container .Graph-box {
    width: 100%;
    margin: 0 0 20px 0;
  }
  .new-assessment-bottom .new-ass-que-wrapper .new-ass-que-left + .new-ass-que-right .user-detail-form {
    display: none;
  }
  .new-assessment-bottom .new-ass-que-wrapper .new-ass-que-inner > .user-details-header {
    padding: 0;
  }
  .new-assessment-bottom .new-ass-que-wrapper .new-ass-que-right .user-detail-form.active-form .new-ass-que-right-top .new-user-left-content,
  .new-assessment-bottom .new-ass-que-wrapper .new-ass-que-right .user-detail-form.active-form .new-ass-que-right-top .user-details-container {
    display: block;
  }
  .new-assessment-bottom .new-ass-que-wrapper .new-ass-que-right .user-detail-form .new-ass-que-right-top .new-user-left-content,
  .new-assessment-bottom .new-ass-que-wrapper .new-ass-que-right .user-detail-form .new-ass-que-right-top .user-details-container {
    display: none;
  }
  .new-assessment-bottom .new-ass-que-wrapper .new-ass-que-right .new-ass-que-right-top {
    margin-bottom: 0;
  }
  body .main-wrapper .assessment-bottom.agent-classes-detail .agent-profit-plan-classes .agent-profit-plan-classes-left > div p {
    max-height: 123px;
    overflow: auto;
  }
}
@media (max-width: 767px) {
  .landing-signup-container .signup-img iframe {
    width: 100%;
  }
  .signup-input-div.phoneInput .iti--allow-dropdown {
    width: 100%;
  }
  .signup-input-div.phoneInput .iti--allow-dropdown .agentLandingPhoneInput {
    width: 100%;
  }
  .signup-title h2 {
    font-size: 26px;
    line-height: 38px;
  }
  body .main-wrapper .assessment-bottom.agent-assessment-top .assesment-company .agent-top-header-mobile {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    margin-top: 15px;
  }
  body .main-wrapper .assessment-bottom .assesment-table {
    padding: 0;
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-header {
    display: none;
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content {
    height: calc(100vh - 167px) !important;
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content ul.table-main-content {
    padding: 10px 22px 10px 10px;
    position: relative;
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-top {
    padding: 10px;
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-top .top-assessments,
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-top .top-created-date,
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-top .top-updated-date {
    display: none;
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-bottom ul {
    padding-left: 10px;
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-bottom ul li:before {
    display: inline-block;
    font-weight: 600;
    margin-right: 10px;
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-bottom ul .bot-new-name:before {
    content: 'Assessments : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-bottom ul .bot-created-date:before {
    content: 'Created Date : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-bottom ul .bot-updated-date:before {
    content: 'Updated Date : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-bottom ul .bot-new-edit:before {
    content: 'Edit :';
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-bottom ul .bot-new-download:before {
    content: 'Download :';
  }
  body .main-wrapper .assessment-bottom .assesment-table .assesment-table-content .assessment-list .assessment-list-bottom ul .bot-new-delete:before {
    content: 'Delete :';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li {
    width: 100% !important;
    padding: 0 !important;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li:before {
    display: inline-block;
    font-weight: 600;
    margin-right: 10px;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.inq-profit-advisor:before {
    content: 'Profit Advisor : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.gui-user:before {
    content: 'User Name : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.gui-count:before {
    content: 'Count : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.gui-date:before {
    content: 'Created Date : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.gui-gui:before {
    content: 'Is Guided : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.inq-name:before {
    content: 'Name : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.inq-email:before {
    content: 'Email : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.age-agent-name:before {
    content: 'Agent name : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.inq-phone-number {
    text-align: left !important;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.inq-phone-number:before {
    content: 'Phone Number : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.inq-inquiry-date {
    text-align: left !important;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.inq-inquiry-date:before {
    content: 'Inquiry Date : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.inq-sign-in {
    text-align: left !important;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.inq-sign-in:before {
    content: 'Sign In : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.inq-assign {
    text-align: left !important;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.age-assessment {
    text-align: left !important;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.age-assessment:before {
    content: 'Assessment : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.age-edit {
    text-align: left !important;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.age-edit:before {
    content: 'Edit : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.age-delete {
    text-align: left !important;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.age-delete:before {
    content: 'Delete : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.inq-action {
    text-align: left !important;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.inq-action:before {
    content: 'Action : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.age-assessmentcompany-name:before {
    content: 'Loan Officer Name : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.email:before {
    content: 'Email : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.uri:before {
    content: 'URI : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.phone-number:before {
    content: 'phone-number : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.sign-up-date:before {
    content: 'Sign Up Date : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.event-end:before {
    content: 'Event End : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.edit {
    text-align: left;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.edit:before {
    content: 'Edit : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.reset {
    text-align: left !important;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.reset:before {
    content: 'Reset : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.new-action {
    text-align: left !important;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.new-action:before {
    content: 'Show Agent : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.new-action .showAgent-btn {
    display: inline-block;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.event-start:before {
    content: 'Event Start : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.assessment {
    text-align: left;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.assessment:before {
    content: 'Assessment : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.delete {
    text-align: left;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.delete:before {
    content: 'Action : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.action {
    position: absolute;
    top: 10px;
    right: 5px;
    width: auto !important;
  }
  body .main-wrapper .test-assessment-ipad .form-control-main.sign-in-btn button {
    width: calc(50% - 8px);
  }
  .model-popup-wrapper.add-new-officer .custom-radio-wrapper .custom-radio .radio-container {
    width: 150px;
  }
  .model-popup-wrapper.add-new-officer .custom-radio-wrapper .custom-radio .checkmark {
    width: 130px;
  }
  body .main-wrapper .assessment-bottom.agent-assessment-top .assesment-table .assesment-table-content {
    height: calc(100vh - 336px) !important;
  }
  .question-list-wrapper.question-mobile .question-inner .question-list-main .question-list .question-list-head h3 {
    width: calc(100% - 114px);
  }
  .question-list-wrapper.question-mobile .question-inner .question-list-main .question-list .question-list-head div {
    width: 114px;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  .question-list-wrapper.question-mobile .question-inner .question-list-main .question-list .question-list-head div h3 {
    width: 100%;
    display: block;
    margin-right: 0 !important;
    text-align: right;
    margin-bottom: 10px;
  }
  .faq-asst-bottom .question-list-wrapper .question-inner .question-list-main .question-list .question-list-head h3 {
    width: calc(100% - 85px);
  }
  .assessment-body .assessment-bottom.courses-top-header.agent-classes-detail .assesment-header button {
    width: auto;
  }
  .model-popup-wrapper .model-popup .model-popup-title h3 {
    font-size: 21px;
  }
  .user-profile-bottom-inner .edit-user-profile .custom-radio {
    margin-bottom: 0;
  }
  .user-profile-bottom-inner .edit-user-profile .custom-radio .radio-container {
    width: 134px !important;
  }
  .user-profile-bottom-inner .edit-user-profile .custom-radio .radio-container .checkmark {
    width: 134px !important;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.inq-name:before {
    content: 'Name : ';
  }
  .model-popup-wrapper.add-new-course-popup .question-bottom-wrapper .question-bottom-inner form .from-group {
    padding: 0;
  }
  .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content ul li.percentage {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content ul li.percentage .border {
    margin: 0 10px 0 0;
  }
  .form-control-main .tooltip-inner {
    width: 300px;
  }
  .new-classes-wrapper .new-classes-middle .new-classes-download-btn .new-classes-download-btn-left {
    width: 100%;
    margin-bottom: 8px;
  }
  .new-classes-wrapper .new-classes-middle .new-classes-download-btn .new-classes-download-btn-right {
    width: 100%;
    padding-left: 0;
    justify-content: flex-end;
  }
  .right-side .popup-custom-checkbox {
    width: auto !important;
  }
  .prec-detail-wrapper .prec-detail-wrapper-left iframe {
    height: 200px;
  }
  .prec-detail-wrapper .prec-detail-wrapper-right .pre-call-content-box .agent-content-box-left {
    width: 110px;
  }
  .prec-detail-wrapper .prec-detail-wrapper-right .pre-call-content-box .agent-content-box-right {
    width: calc(100% - 110px);
  }
  .prec-detail-wrapper .prec-detail-wrapper-right .pre-call-content-box .agent-content-box-left iframe {
    width: 100%;
  }
  .previous-coaching-table .previous-coaching-table-content .title,
  .previous-coaching-table .previous-coaching-table-content .modified,
  .previous-coaching-table .previous-coaching-table-content .clickhere,
  .previous-coaching-table .previous-coaching-table-content .action {
    width: 100%;
    text-align: left;
    margin: 5px 0;
  }
  .previous-coaching-table .previous-coaching-table-head {
    display: none;
  }
  .previous-coaching-table .previous-coaching-table-content {
    border-top: 1px solid #dad9dc;
  }
  .previous-coaching-table .previous-coaching-table-content .title {
    position: relative;
    padding-left: 63px;
  }
  .previous-coaching-table .previous-coaching-table-content .title:before {
    content: 'Title : ';
    font-weight: 600;
    margin-right: 10px;
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translate(0, -50%);
  }
  .previous-coaching-table .previous-coaching-table-content .modified {
    position: relative;
    padding-left: 93px;
  }
  .previous-coaching-table .previous-coaching-table-content .modified:before {
    content: 'Created : ';
    font-weight: 600;
    margin-right: 10px;
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translate(0, -50%);
  }
  .previous-coaching-table .previous-coaching-table-content .clickhere {
    position: relative;
    padding-left: 63px;
  }
  .previous-coaching-table .previous-coaching-table-content .clickhere:before {
    content: 'Link : ';
    font-weight: 600;
    margin-right: 10px;
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translate(0, -50%);
  }
  .previous-coaching-table .previous-coaching-table-content .action {
    position: relative;
    padding-left: 77px;
  }
  .previous-coaching-table .previous-coaching-table-content .action:before {
    content: 'Action : ';
    font-weight: 600;
    margin-right: 10px;
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translate(0, -50%);
  }
  .previous-coaching-table .previous-coaching-table-content {
    max-height: unset;
    overflow: unset;
  }
  .previous-coaching-popup.model-popup-wrapper .model-popup {
    width: calc(100% - 30px);
    margin: 0 auto;
  }
  body .main-wrapper .assessment-topbar .mobile-header .mobile-logo img {
    height: 37px;
  }
  body .main-wrapper .assessment-topbar .mobile-header .mobile-logo {
    margin-left: 5px;
  }
  .main-wrapper .assessment-topbar .userinfo img {
    width: 30px;
    height: 30px;
  }
  .previous-coaching-table .previous-coaching-table-content .list-video-name {
    width: 100%;
    padding-left: 0;
    margin-top: 5px;
    word-break: break-all;
  }
  .courses-top-header .question-header .add-new.back-to-list {
    display: block;
    margin-right: 0 !important;
    margin-top: 10px;
  }
  .new-classes-wrapper .new-classes-right .agent-content-box.video-upload .agent-content-box-wrapper-new .agent-content-box-left iframe {
    width: 100%;
  }
  .upload-file-wrapper-prev {
    margin: 0;
  }
  .upload-file-wrapper-prev .upload-file-box-prev {
    width: 100%;
    margin: 0 0 16px 0;
  }
  .resource-wrapper .resource-inner {
    margin: 0;
  }
  .resource-wrapper .resource-inner .resource-box {
    width: 100%;
    margin: 0 0 16px 0;
  }
  .previous-coaching-table-content .cdk-drop-list {
    height: auto;
  }
  .main-wrapper .assessment-bottom .inquiry-search-heade .assesment-button {
    width: 97px;
  }
  .video-des-email .video-des--email span,
  .video-des-email .video-des--link span {
    width: 23px;
  }
  .video-des-email .video-des--email label,
  .video-des-email .video-des--link a {
    width: calc(100% - 23px);
  }
  .previous-coaching-popup .question-bottom-wrapper .question-bottom-inner form .from-group {
    margin-right: 0;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul.ui-agent-delete li.delete1:before {
    content: 'Delete :';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.assessment1:before {
    content: 'Assessment : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.edit1:before {
    content: 'Edit : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.action1 {
    position: absolute;
    top: 10px;
    right: 5px;
    width: auto !important;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.edit1 {
    text-align: left !important;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.delete1 {
    text-align: left !important;
  }
  .new-canva-right-table .new-canva-right-table-content-wrapper .new-canva-right-table-content .canva-table-title {
    width: 100%;
    margin-bottom: 15px;
    position: relative;
    padding-left: 47px;
  }
  .new-canva-right-table .new-canva-right-table-content-wrapper .new-canva-right-table-content .canva-table-action {
    width: 100%;
    position: relative;
    padding-left: 61px;
  }
  .new-canva-right-table .new-canva-right-table-content-wrapper .new-canva-right-table-content .canva-table-title:before {
    content: 'Title : ';
    font-weight: 600;
    margin-right: 10px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
  }
  .new-canva-right-table .new-canva-right-table-content-wrapper .new-canva-right-table-content .canva-table-action:before {
    content: 'Action : ';
    font-weight: 600;
    margin-right: 10px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
  }
  .new-canva-right-table .new-canva-right-table-content-wrapper .new-canva-right-table-content .canva-table-title .canva-table-title-img p {
    width: 100%;
    padding-left: 0;
    margin-top: 5px;
  }
  .new-canva-right-table .new-canva-right-table-head {
    display: none;
  }
  .new-canva-right-table-content-visible .new-canva-right-table-content-visible-left {
    width: 100%;
  }
  .new-canva-right-table-content-visible .new-canva-right-table-content-visible-right {
    width: 100%;
    padding-left: 0;
    margin-top: 20px;
  }
}
