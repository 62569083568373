.add-new-ass-que-left {
  width: 65%;
  max-height: 141vh;
  overflow-y: auto;
  overflow-x: unset;
}

.new-ass-que-right .user-details-title {
  font-size: 26px;
  font-weight: 700;
  text-transform: capitalize;
  margin: 0 0 6px 0;
}

.new-user-left-content {
  border-bottom: 1px dashed #dad9dc;
}

.new-user-left-content li {
  width: 100%;
  padding-bottom: 8px;
}

.user-details-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.user-input-div-details {
  width: 50%;
  padding: 5px 15px;
}

.user-input-div-details label {
  margin-bottom: 8px;
}

.user-input-div-details input {
  background: transparent;
  border: 1px solid #ffffff;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  padding: 6px;
  width: 100%;
}

.span a {
  color: #ffffff;
}

.graph-container {
  width: 100%;
  // margin-top: 24px;
  display: inline-block;
}

.graph-block {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
  width: auto !important;
  padding: 0 !important;
}

.Graph-box {
  width: 100%;
  margin: 0 12px;
  padding: 20px !important;
  float: left;
  background: #ffffff !important;
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06);
  color: black !important;
}

.user-details-header {
  display: none;
}
