.message-heading {
  h2 {
    color: #231f20;
    font-size: 26px;
    font-weight: bold;
  }
  button {
    width: 90px;
    height: 42px;
    background: #fa7a23;
    border: none;
    color: #fff;
    font-size: 16px;
    font-weight: normal;
    margin-left: 20px;
  }
}
.message-board-content {
  background: #fff;
  box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.06);
  padding: 16px;
  max-height: calc(100vh - 150px);
  overflow: auto;
  .message-heading-sub {
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
  }
  .message-board-search {
    input {
      height: 42px;
      border: 1px solid #231f20;
      padding: 0 12px;
      font-size: 14px;
      width: calc(100% - 110px);
    }
    .message-search-btn {
      width: 90px;
      height: 42px;
      background: #fa7a23;
      border: none;
      color: #fff;
      font-size: 16px;
      font-weight: normal;
      margin-left: 20px;
    }
  }
  .message-board-category-box {
    .message-board-category-title {
      padding: 12px 16px;
      background: var(--White, #fff);
      box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.06);
      .message-board-category-title-left {
        width: calc(100% - 410px);
        p {
          color: #2c5c84;
          font-size: 12px;
          font-weight: 600;
        }
      }
      .message-board-category-title-right {
        width: 400px;
        margin-left: 10px;
        justify-content: flex-end;
        .total-subject {
          font-size: 14px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          span {
            height: 24px;
            min-width: 24px;
            background: #fa7a23;
            color: #fff;
            border-radius: 50%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            font-size: 13px;
            margin-left: 5px;
            padding: 0 2px;
          }
        }
      }
    }
    .message-board-category-content {
      border: 1px solid #2c5c84;
      background: #fff;
      box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.06);
      padding: 16px 8px;
      display: none !important;
      .message-board-category-content-top {
        width: 100%;
        .message-board-category-content-top-box {
          width: calc(50% - 16px);
          margin: 0 8px 16px 8px;
          background: #f2f2f2;
          padding: 16px 8px 8px 16px;
          p {
            color: #717171;
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 4px;
            a {
              color: @orange;
            }
          }
          span {
            font-size: 14px;
            font-weight: bold;
            label {
              color: #6f6f6f;
              font-size: 12px;
              font-weight: 400;
              // margin-left: 8px;
            }
          }
          .redirect-btn {
            position: absolute;
            top: 8px;
            right: 8px;
            padding: 0;
            border: none;
            font-size: 12px;
            text-decoration: underline;
            color: #fa7a23;
          }
        }
      }
      .message-board-category-content-bottom {
        padding-right: 8px;
        .add-subject {
          width: 120px;
          height: 42px;
          background: #231f20;
          color: #fff;
          font-size: 16px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          &.subsubject {
            width: 157px;
          }
        }
      }
    }
    &.active-catagory {
      .message-board-category-title {
        background: #2c5c84;
        box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.06);
        .message-board-category-title-left {
          p {
            color: #fff;
          }
          svg {
            path {
              fill: #fff;
            }
          }
        }
        .message-board-category-title-right {
          svg {
            transform: rotate(180deg);
            path {
              fill: #fff;
            }
          }
          .total-subject {
            color: #fff;
          }
        }
      }
      .message-board-category-content {
        display: flex !important;
      }
    }
  }
}
.chatbox-waraaper {
  .chatbox-inner-scroll {
    max-width: 100%;
    max-height: calc(100vh - 342px);
    overflow: auto;
    min-height: calc(100vh - 342px);
    display: flex;
    flex-wrap: wrap;
  }
  .chatbox-inner {
    width: calc(100% - 500px);
    // max-height: calc(100vh - 342px);
    // overflow: auto;
    // min-height: calc(100vh - 342px);
    .chatbox-box {
      .chatbox-box-left {
        width: 136px;
        img {
          max-width: 100%;
          height: auto;
          border-radius: 16px;
        }
        p {
          text-align: center;
          color: #000;
          font-size: 12px;
          font-weight: 600;
        }
      }
      .chatbox-box-right {
        width: calc(100% - 245px);
        padding: 0 16px;
        position: relative;
        .chatbox-content {
          padding: 12px;
          border-radius: 0px 10px 10px 10px;
          .received_withd_msg {
            span {
              font-size: 12px;
              color: #7e7e7e;
              margin-top: 8px;
            }
            ul {
              padding-left: 2rem;
            }
          }
          .received_withd_msg_reply {
            span {
              font-size: 12px;
              color: #242632;
              margin-top: 8px;
            }
            ul {
              padding-left: 2rem;
            }
          }
          .chatbox-box-btn {
            position: absolute;
            top: 0;
            right: -53px;
            top: 10px;
          }
        }
      }
    }
  }
  .chat-message-box {
    max-width: 1100px;
    margin: 0 auto;
    position: relative;
    .ck-body-wrapper {
      &.email-text-ckeditor {
        background: #fff;
        box-shadow: 0px 4px 20px 0px #ededed;
        .ck.ck-toolbar {
          background: #fff;
          .ck {
            &.ck-toolbar__items {
              margin-left: 34px;
            }
          }
        }
        strong {
          u {
            font-weight: bolder;
          }
        }
      }
    }
    .sent-btn {
      height: 38px;
      width: 38px;
      background: #231f20;
      border: none;
      position: absolute;
      top: 16px;
      right: 26px;
    }
  }
  .chat-message-box-reply {
    max-width: 1078px;
    margin: 0 auto;
    position: relative;
    padding: 5px;
    border-radius: 8px;
    background: #edededfc;
    .sent-btn {
      height: 38px;
      width: 38px;
      background: #231f20;
      border: none;
      position: absolute;
      top: 40px;
      right: 26px;
    }
  }
  .chatbox-subject-detail {
    width: 500px;
    .chatbox-subject-detail-inner {
      position: sticky;
      top: 0;
      background: #f6f6f6;
      padding: 12px;
      margin-right: 10px;
      .chatbox-subject-detail-inner-box {
        p {
          width: 110px;
          font-size: 16px;
          font-weight: 600;
        }
        span {
          width: calc(100% - 110px);
          font-size: 16px;
        }
      }
    }
  }
}
.des-height {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.ck-editor-fileupload {
  position: absolute;
  top: 17px;
  left: 26px;
  background: transparent;
  border: none;
  padding: 0;
}
.file-upload-list-wrapper-messageboard {
  max-width: 1070px;
  margin: 15px auto auto auto;
  ul {
    margin: 0 -8px;
    li {
      width: calc(25% - 16px);
      margin: 0 8px 8px 8px;
      p {
        width: calc(100% - 20px);
        padding-right: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      button {
        border: none;
        background: transparent;
        padding: 0;
      }
    }
  }
}
.images-delete-wrapper {
  img {
    max-width: 200px;
  }
}
.reply-content {
  height: 19px;
  overflow: hidden;
}
.message-box-right-content-iner.main-message-notes-NA {
  display: none;
}
@media (max-width: 1200px) {
  .chatbox-waraaper {
    .chatbox-inner {
      order: 1;
      width: 100%;
      margin-top: 30px;
    }
    .chatbox-subject-detail {
      .chatbox-subject-detail-inner {
        margin-right: 0;
      }
    }
  }
}
@media (max-width: 991px) {
  .message-board-content {
    .message-board-category-box {
      .message-board-category-content {
        .message-board-category-content-top {
          .message-board-category-content-top-box {
            width: calc(100% - 16px);
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .message-board-content {
    .message-board-category-box {
      .message-board-category-content {
        .message-board-category-content-top {
          .message-board-category-content-top-box {
            width: 100%;
            margin: 0 0 16px 0;
            .w-30,
            .w-40,
            .w-70 {
              width: 100% !important;
              padding-right: 0 !important;
            }
          }
        }
      }
    }
  }
  .message-board-content .message-board-category-box .message-board-category-title {
    position: relative;
  }
  .message-board-content .message-board-category-box .message-board-category-title .message-board-category-title-left {
    width: 100%;
  }
  .message-board-content .message-board-category-box .message-board-category-title .message-board-category-title-right .total-subject {
    width: 100%;
    justify-content: flex-end;
    margin-right: 0 !important;
  }
  .message-board-content
    .message-board-category-box
    .message-board-category-title
    .message-board-category-title-right
    .total-subject:first-child {
    margin-bottom: 10px;
  }
  .message-board-content .message-board-category-box .message-board-category-title .message-board-category-title-right .accordian-arrow {
    position: absolute;
    top: 13px;
    right: 10px;
  }
}
