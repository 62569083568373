//Common Css
* {
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: normal;
}

body {
  margin-bottom: 0;
  color: @text-black;
}

a,
a:hover {
  text-decoration: none;
  color: #ffffff;
}

img {
  max-width: @full;
}

p,
ul,
li {
  margin: 0;
  padding: 0;
}

input,
textarea,
.form-control,
select {
  &:hover,
  &:focus {
    box-shadow: none;
    outline: none;
  }
}

//Variable Start
@bg-white: #ffffff;
@bg-black: #231f20;
@button-bg: #fa7a23;
@input-bg: #3c3939;
@text-white: #ffffff;
@input-text-color: #747474;
@orange: #fa7a23;
@text-black: #231f20;
@border-color: #231f20;
@border-color1: #dad9dc;
@blue-bg: #2c5c84;

@twenty: 20px;
@thirty: 30px;
@fifty: 50px;
@full: 100%;
@half: 50%;
@none: none;

//Common CSS
::-webkit-scrollbar {
  width: 5px;
  background: #e4e1df;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #a2a2a2;
  width: 5px;
  border-radius: 10px;
}

.main-heading {
  font-size: 44px;
  line-height: 50px;
  font-weight: 700;
  margin-bottom: 70px;
}

.sign-in-btn {
  button {
    width: 180px;
    height: @fifty;
    line-height: @fifty;
    background: @button-bg;
    border: 1px solid @button-bg;
    color: @text-white;
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 600;
    transition: 0.5s;

    &:hover {
      background: transparent;
      color: @orange;
    }
  }
}

.form-control-main {
  margin-bottom: @twenty + 4;
  width: @full;
  display: inline-block;

  span {
    position: absolute;
    top: 14px;
    left: 15px;
  }

  .form-control-main1 {
    margin-bottom: @twenty + 4;
    width: @full;
    display: inline-block;
  }

  .phoneInputCustom {
    border-radius: 0;
    height: 50px;
    line-height: 50px;
    background: #ebebeb !important;
    border: none;
    border: none;
    padding-left: 70px !important;
    color: #040404;
    max-width: 95%;
  }

  .phoneInputCustom:hover,
  .phoneInputCustom:focus {
    box-shadow: none;
  }

  .phoneInputCustom::-webkit-input-placeholder {
    color: #747474;
    font-size: 14px;
  }

  .phoneInputCustom:-ms-input-placeholder {
    color: 747474;
    font-size: 14px;
  }

  .phoneInputCustom::placeholder {
    color: 747474;
    font-size: 14px;
  }

  .custom {
    border-radius: 0;
    height: 50px;
    line-height: 50px;
    background: blue !important;
    border: none;
    border: @none;
    padding-left: 45px;
    color: @text-white;

    &:hover,
    &:focus {
      box-shadow: @none;
    }

    &::-webkit-input-placeholder {
      color: @input-text-color;
      font-size: 14px;
    }

    &:-ms-input-placeholder {
      color: @input-text-color;
      font-size: 14px;
    }

    &::placeholder {
      color: @input-text-color;
      font-size: 14px;
    }
  }

  .form-control {
    border-radius: 0;
    height: 50px;
    line-height: 50px;
    background: @input-bg;
    border: @none;
    padding-left: 45px;
    color: @text-white;

    &:hover,
    &:focus {
      box-shadow: @none;
    }

    &::-webkit-input-placeholder {
      color: @input-text-color;
      font-size: 14px;
    }

    &:-ms-input-placeholder {
      color: @input-text-color;
      font-size: 14px;
    }

    &::placeholder {
      color: @input-text-color;
      font-size: 14px;
    }
  }

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  .forget-password {
    color: @orange;
    font-size: 16px;
    font-weight: 500;
  }

  &.sign-in-btn {
    margin: @fifty 0;
  }
}

//CSS START
.sign-up-wrapper {
  background: @bg-black;
  min-height: 100vh;

  .sign-up-left {
    background: @bg-white;
    padding-top: @fifty - 20px;

    .bottom-img {
      padding-top: @fifty;
    }

    .signup-text {
      margin: @fifty 0 0 0;

      h2 {
        font-size: 26px;
        font-weight: 700;
        line-height: 31px;
        margin-bottom: @twenty;
      }

      p {
        line-height: @twenty + 4;
        max-width: 50%;
        margin: 0 auto;
      }
    }
  }

  .sign-up-right {
    color: @text-white;

    .sign-up-right-wrapper {
      max-width: 530px;
      margin: 0 auto;
      padding: 0 15px;

      .mobile-logo {
        display: none;
      }
    }

    p {
      font-size: 18px;

      a {
        font-weight: 600;
        color: @text-white;
        font-size: 18px;
        transition: 0.5s;

        &:hover {
          color: @orange;
        }
      }
    }
  }
}

//Assessment Start
//.main-wrapper {
//  .assessment-inner {
//      background: @bg-white;
//      display: flex;
//      flex-wrap: wrap;
//      min-height: 100vh;
//      width: 100%;

//      .navigation {
//          background: @bg-black;
//          width: 240px;
//          color: @text-white;
//          padding: 10px 30px;

//          .navigation-logo {
//              padding-bottom: @fifty;
//          }

//          nav {
//              ul {
//                  li {
//                      a {
//                          display: flex;
//                          flex-wrap: wrap;
//                          align-items: center;
//                          padding-bottom: @twenty - 5;

//                          span {
//                              font-weight: 700;
//                              color: @text-white;
//                              padding-left: @twenty - 5;
//                          }
//                      }
//                  }
//              }
//          }
//      }

//      .assessment-body {
//          width: ~"calc(100% - 240px)";
//      }
//  }

//  .assessment-topbar {
//      background: @orange;
//      padding: 12px @twenty + 10;
//      .userinfo {
//          font-weight: 600;
//          color: @text-white;
//          span {
//              padding-left: 10px;
//          }
//          img {
//              border-radius: 8px;
//          }
//      }
//  }
//}

.main-wrapper {
  .assessment-inner {
    background: @bg-white;
    display: flex;
    flex-wrap: wrap;
    min-height: 100vh;
    width: 100%;

    .navigation {
      background: #fff;
      width: 240px;
      color: @text-white;
      padding: 10px 0 10px 30px;
      cursor: pointer;
      display: none;

      .navigation-logo {
        padding-bottom: @fifty;
        padding-right: 30px;
      }

      nav {
        display: none;
        .mobile-close {
          display: none;
        }

        ul {
          li {
            a {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              margin-bottom: @twenty + 5;

              span {
                //font-weight: 700;
                color: @text-white;
                padding-left: @twenty - 5;
                width: calc(100% - 35px);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
        }
      }
    }

    .assessment-body {
      width: 100%;
    }
  }

  .assessment-topbar {
    background: #f8f8f8;
    padding: 12px @twenty + 10;

    .mobile-header {
      display: @none;
    }

    .userinfo {
      font-weight: 600;
      color: @text-white;

      img {
        width: 40px;
        height: 40px;
      }

      .dropdown-toggle {
        padding: 0;
        border: none;
        color: #231f20;
        font-size: 14px;
        padding-left: 15px;
        background: transparent;

        &:hover,
        &:focus {
          box-shadow: none;
          outline: none;
        }
      }

      img {
        border-radius: 8px;
      }
    }
  }

  .assessment-bottom {
    padding: @thirty;

    .page-title {
      font-size: 26px;
      font-weight: 700;
      line-height: 31px;
      margin-bottom: @twenty;

      &.new-question-title {
        margin-bottom: 0;
      }
    }

    .assement-header-bottom {
      margin-bottom: 12px;
    }

    .assement-select {
      padding: 0 13px 0 60px;
      width: 357px;
    }

    .assement-search {
      width: ~'calc(100% - 447px)';

      &.inquire {
        width: ~'calc(100% - 499px)';
      }

      input {
        border: 1px solid @border-color;
        width: 100%;
        height: 42px;
        padding-left: @twenty;
        color: @text-black;
        transition: 0.5s;
        border-radius: 0;

        &:hover,
        &:focus {
          box-shadow: none;
          outline: none;
          border-color: @orange;
        }

        &::-webkit-input-placeholder {
          color: @border-color1;
          font-size: 14px;
        }

        &:-ms-input-placeholder {
          color: @border-color1;
          font-size: 14px;
        }

        &::placeholder {
          color: @border-color1;
          font-size: 14px;
        }
      }
    }

    .profit-filter {
      width: auto;
      flex-grow: 1;
      margin: 1rem;

      select {
        border: 1px solid @border-color;
        width: 100%;
        height: 42px;
        padding-left: @twenty;
        color: @text-black;
        transition: 0.5s;

        &:hover,
        &:focus {
          box-shadow: none;
          outline: none;
          border-color: @orange;
        }

        &::-webkit-input-placeholder {
          color: @border-color1;
          font-size: 14px;
        }

        &:-ms-input-placeholder {
          color: @border-color1;
          font-size: 14px;
        }

        &::placeholder {
          color: @border-color1;
          font-size: 14px;
        }
      }
    }

    .assesment-company {
      background: @blue-bg;
      padding: 16px;

      p {
        font-size: 18px;
        font-weight: 600;
        color: @text-white;
      }

      a {
        font-weight: 600;
        color: @text-white;
        text-transform: capitalize;

        span {
          padding-left: 10px;
        }
      }
    }

    .assesment-table {
      box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06);
      padding: 17px;
      //height: calc(100vh - 296px);
      //overflow: auto;

      .assesment-table-header {
        background: @bg-black;
        color: @text-white;
        padding: 13px 25px;
      }

      ul {
        li {
          word-break: break-all;

          &.company-name {
            width: 16%;
            padding: 0 5px;
          }

          &.email {
            width: 13%;
            padding: 0 5px;
          }

          &.phone-number {
            width: 11%;
            padding: 0 5px;
          }

          &.sign-up-date {
            width: 10%;
            padding: 0 5px;
          }

          &.event-start {
            width: 13%;
            padding: 0 5px;
          }

          &.event-end {
            width: 13%;
            padding: 0 5px;
          }

          &.assessment {
            width: 6%;
            text-align: center;
            padding: 0 5px;
          }

          &.edit {
            width: 5%;
            text-align: center;
            padding: 0 5px;
          }

          &.reset {
            width: 5%;
            text-align: center;
            padding: 0 5px;
          }

          &.delete {
            width: 5%;
            text-align: center;
            padding: 0 5px;
          }

          &.action {
            width: 3%;
            text-align: right;
            padding: 0 5px;
          }
        }
      }

      .assesment-table-content {
        border: 1px solid @border-color1;
        height: ~'calc(100vh - 377px)';
        overflow: auto;

        ul.table-main-content {
          padding: 20px 25px;
          cursor: pointer;
          border-bottom: 1px solid @border-color1;

          li {
          }
        }

        .assessment-list {
          background: rgba(35, 31, 32, 0.06);
          width: 100%;
          display: inline-block;
          border-bottom: 1px solid @border-color1;

          .assessment-list-top {
            padding: 12px 25px 12px 100px;

            .assessment-list-top-ul {
              li {
                font-weight: 600;
              }

              .top-assessments {
                width: 23%;
                padding: 0 5px;
              }

              .top-created-date {
                width: 23%;
                padding: 0 5px;
              }

              .top-updated-date {
                width: 34%;
                padding: 0 5px;
              }

              .top-ass-btn {
                width: 20%;
                padding: 0 5px;
                text-align: right;
              }
            }

            a {
              height: 32px;
              line-height: 32px;
              background: @orange;
              display: inline-block;
              width: 173px;
              text-align: center;

              span {
                font-size: 12px;
                color: @text-white;
                padding-left: 10px;
              }
            }
          }

          .assessment-list-bottom {
            border-top: 1px dashed #a2a2a2;

            ul {
              padding: 20px 25px 20px 100px;

              .bot-new-name {
                width: 23%;
                padding: 0 5px;
              }

              .bot-created-date {
                width: 23%;
                padding: 0 5px;
              }

              .bot-updated-date {
                width: 34%;
                padding: 0 5px;
              }

              .bot-new-edit {
                width: 6%;
                padding: 0 5px;
              }

              .bot-new-download {
                width: 5%;
                padding: 0 5px;
              }

              .bot-new-delete {
                width: 8%;
                padding: 0 5px 0 23px;
              }
            }
          }
        }
      }
    }

    .question-header {
      .add-new {
        height: 40px;
        width: 160px;
        background: @orange;
        border: none;
        font-size: 16px;
        font-weight: 500;
        color: @text-white;
        text-transform: capitalize;
        margin-left: 15px;

        img {
          margin-left: 5px;
        }
      }
    }
  }

  .custom-radio {
    padding-right: 40px;

    .custom-radio-container {
      display: block;
      position: relative;
      padding-left: 30px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      margin-bottom: 0;

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;

        &:checked {
          ~ .checkmark {
            &:after {
              display: block;
            }
          }
        }
      }

      .checkmark {
        &:after {
          top: 3px;
          left: 3px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: @orange;
        }
      }
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: transparent;
      border-radius: 50%;
      border: 2px solid @orange;

      &:after {
        content: '';
        position: absolute;
        display: none;
      }
    }
  }

  .assesment-button {
    width: 90px;

    a {
      width: 90px;
      height: 42px;
      line-height: 40px;
      background: @orange;
      color: @text-white;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      display: inline-block;
    }
  }
}

//New Assessment start
.new-user-detail-wrapper {
  color: @text-white;
  background: @bg-black;
  padding: @twenty - 2 @twenty + 4;
  margin-bottom: @twenty + 4;

  a {
    color: @text-white;
  }

  .new-user-detail-left {
    width: @half;
    border-right: 1px solid #dad9dc;
    padding-right: @fifty;

    .new-user-left-user {
      width: @half;

      p {
        font-size: 22px;
        line-height: 26px;
        font-weight: 700;
        text-transform: capitalize;
      }
    }

    .new-user-left-content {
      width: @half;

      ul {
        li {
          width: @full;
          margin-bottom: 15px;

          p,
          span {
            width: @half;
            padding: 0 5px;
          }

          span {
            font-weight: 500;
          }
        }
      }
    }
  }

  .new-user-detail-right {
    width: @half;
    padding-left: @fifty;

    .new-user-right-current {
      width: @half;

      ul {
        li {
          width: @full;
          margin-bottom: 15px;

          p {
            width: @half;
            padding: 0 5px;
          }

          label {
            width: @half;
            padding: 0 5px;
            font-weight: 500;
          }
        }
      }
    }
  }
}

.new-ass-que-wrapper {
  .new-ass-que-left {
    width: 65%;
    max-height: 141vh;
    overflow-y: auto;
    overflow-x: unset;

    h2 {
      font-size: 26px;
      font-weight: 700;
      text-transform: capitalize;
      margin: 0 0 @twenty - 5 0;
    }

    .new-ass-que-left-content {
      box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06);
      margin-bottom: @twenty;

      &.new-ass-que-left-content-none {
        box-shadow: none;
      }

      ul {
        padding: 0 24px;

        li {
          &.ass-questions {
            width: ~'calc(100% - 434px)';
            padding-right: @thirty;
          }

          &.acc-arrow {
            width: 24px;
          }

          &.hint {
            width: ~'calc(60px - 30px)';
            margin-right: 30px;
          }

          &.answers {
            width: 260px;
            padding-right: 30px;
          }

          &.percentage {
            width: 90px;
          }

          &.tooltip-hint {
            position: relative;
            cursor: help;

            &:hover {
              .tool-tip {
                display: block;
              }
            }

            .tool-tip {
              position: absolute;
              background: #fff;
              padding: 10px;
              top: 0px;
              right: 38px;
              width: 250px;
              box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.1);
              border: 1px solid @border-color1;
              display: none;

              &:before {
                content: '';
                right: -9px;
                top: 3px;
                position: absolute;
                width: 0;
                height: 0;
                border-top: 8px solid transparent;
                border-bottom: 8px solid transparent;
                border-left: 8px solid @border-color1;
              }
            }
          }
        }
      }

      .new-ass-que-left-content-head {
        background: #dad9dc;
        color: #231f20;
        text-transform: capitalize;
        padding: @twenty - 6 0;

        ul {
          li {
            font-weight: 600;
          }
        }
      }

      .new-ass-que-left-content-content {
        ul {
          //border-bottom: 1px solid #DAD9DC;
          padding-top: @twenty - 10;
          padding-bottom: @twenty - 10;

          li {
            &.ass-questions {
              line-height: 16px;
            }

            &.answers {
              button {
                width: 107px;
                height: @thirty;
                line-height: @thirty;
                border: 1px solid @border-color;
                text-transform: capitalize;
                font-size: 12px;
                background: transparent;
                text-align: center;
                display: inline-block;
                color: @text-black;

                &.active {
                  background: @bg-black;
                  color: @text-white;
                }

                &:nth-child(1) {
                  margin-right: 15px;
                }
              }

              textarea {
                width: @full;
                border: 1px solid @border-color1;
                margin-top: 10px;
                padding: 8px;
                height: 52px;

                &::-webkit-input-placeholder {
                  /* Edge */
                  color: @border-color1;
                  font-size: 12px;
                }

                &:-ms-input-placeholder {
                  /* Internet Explorer */
                  color: @border-color1;
                  font-size: 12px;
                }

                &::placeholder {
                  color: @border-color1;
                  font-size: 12px;
                }
              }
            }

            &.percentage {
              input,
              select {
                width: @fifty + 40;
                height: @thirty;
                line-height: @thirty;
                border: 1px solid @border-color1;
                font-size: 12px;
                text-align: center;
                display: inline-block;
                color: @text-black;
                border-radius: 0;
              }

              select {
                margin-bottom: 10px;
              }
            }
          }
        }
      }
    }
  }

  .new-ass-que-right {
    width: 35%;
    padding-left: @twenty + 5;

    .new-ass-que-right-top {
      // box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06);
      padding: 16px;
      margin-bottom: 10px;
      background: #231f20;
      color: #ffffff;

      .bar-chart-wrapper {
        width: 50%;

        canvas {
          width: 100% !important;
          height: auto !important;
        }
      }

      .chart-container {
        max-width: 100%;
        overflow: auto;
      }
    }

    .new-ass-que-right-bottom {
      box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06);
      padding: @twenty + 4;
    }
  }

  .graph-block {
    width: 35%;
    padding-left: @twenty + 5;

    .Graph-box {
      // box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06);
      padding: 16px;
      margin-bottom: 10px;
      background: #231f20;
      color: #ffffff;

      .bar-chart-wrapper {
        width: 50%;

        canvas {
          width: 100% !important;
          height: auto !important;
        }
      }

      .chart-container {
        max-width: 100%;
        overflow: auto;
      }
    }

    .new-ass-que-right-bottom {
      box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06);
      padding: @twenty + 4;
    }
  }
}

//.add-new-question-btn {
//  height: 32px;
//  line-height: 32px;
//  background: @orange;
//  display: inline-block;
//  width: 155px;
//  text-align: center;

//  span {
//    font-size: 12px;
//    color: @text-white;
//    padding-left: 10px;
//  }
//}
.question-bottom-wrapper {
  //margin-top: @twenty;
  //box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06);
  //padding: @twenty + 4 @twenty + 4 10px @twenty + 4;

  .question-bottom-inner {
    form {
      margin: 0 -25px;

      .from-group {
        width: 50%;
        padding: 0 25px;
        margin-bottom: 15px;

        input,
        select {
          height: 42px;
          border: 1px solid @border-color1;
          font-size: 15px;
          border-radius: 0;
        }

        select {
          //width: ~"calc(100% - 44px)";
          width: 100%;
        }

        .add-new {
          height: 42px;
          width: 44px;
          background: @orange;
          border: none;
        }

        .added-btn {
          height: 50px;
          width: 160px;
          background: @orange;
          border: none;
          font-size: 16px;
          font-weight: 600;
          color: @text-white;
        }

        .switch-text {
          font-size: 15px;
          font-weight: 500;
        }

        .form-switch {
          margin-left: @thirty;
          padding-left: 0;

          input {
            height: 18px;
            width: 32px;
            border-radius: 10px;
            border: none;
            background-color: #ffd3b6;
          }

          .switch {
            position: relative;
            display: inline-block;
            width: 32px;
            height: 18px;

            input {
              opacity: 0;
              width: 0;
              height: 0;

              &:checked + .slider {
                background-color: #fa7a23;

                &:before {
                  -webkit-transform: translateX(14px);
                  -ms-transform: translateX(14px);
                  transform: translateX(14px);
                }
              }
            }
          }

          .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            -webkit-transition: 0.4s;
            transition: 0.4s;

            &:before {
              position: absolute;
              content: '';
              height: 14px;
              width: 14px;
              left: 2px;
              bottom: 2px;
              background-color: white;
              -webkit-transition: 0.4s;
              transition: 0.4s;
            }

            &.round {
              border-radius: 34px;

              &:before {
                border-radius: 50%;
              }
            }
          }
        }
      }
    }
  }
}

.question-list-wrapper {
  margin-top: @twenty + 4;

  .question-inner {
    .question-title {
      margin-bottom: 15px;

      h2 {
        font-size: 26px;
        line-height: 31px;
        font-weight: 700;
        margin-bottom: 0;
      }
    }

    .question-list-main {
      .question-list {
        margin-bottom: 16px;

        .question-list-head {
          background: #ffffff;
          box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06);
          padding: 17px 20px;

          h3 {
            font-size: 12px;
            font-weight: 600;
            margin-bottom: 0;
          }

          .up-arrow {
            display: none;
          }
        }

        &.active-question {
          .question-list-head {
            .up-arrow {
              display: block;
            }

            .down-arrow {
              display: none;
            }
          }
        }

        .question-list-content-wrapper {
          display: flex;
          flex-wrap: wrap;

          .question-list-content {
            width: ~'calc(50% - 40px)';
            margin: 0 @twenty;
            padding: @twenty + 5 0;
            border-bottom: 1px dashed @border-color1;

            &:last-child,
            &:nth-last-child(2) {
              border-bottom: none;
            }

            .question-main {
              margin-bottom: 16px;

              p {
                width: ~'calc(100% - 85px)';
                padding-right: 10px;
              }

              .question-action {
                width: 85px;

                .question-action-delete {
                  margin-left: @fifty;
                }
              }
            }

            .question-hint {
              margin-bottom: 16px;

              label {
                width: 52px;
                font-weight: 600;
              }

              p {
                width: ~'calc(100% - 52px)';
              }
            }

            .question-bottom {
              label {
                font-size: 12px;
                font-weight: 600;
              }

              p {
                padding-left: 10px;
              }

              .question-percentage {
                margin-right: @fifty;

                p {
                  font-weight: 700;
                  color: #2c5c84;
                }
              }

              .question-status {
                p {
                  font-weight: 700;
                  color: @orange;
                }
              }
            }
          }
        }

        &.active-question {
          .question-list-head {
            background: @blue-bg;
            color: @text-white;
          }

          .question-list-content-wrapper {
            border: 1px solid @blue-bg;
          }
        }
      }
    }
  }
}

//MODEL POPUP START
.model-popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  .model-popup {
    width: 822px;
    background: @bg-white;
    padding: 34px;

    // .model-popup-coach {
    //   width: 410px;
    //   background: @bg-white;
    //   padding: 34px;

    .model-popup-title {
      margin: 0 0 @thirty + 4 0;
      flex-wrap: wrap;
      align-items: center;

      h3 {
        font-size: 26px;
        font-weight: 700;
        text-transform: capitalize;
        margin-bottom: 0;
        width: calc(100% - 25px);
      }

      .btn-close {
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }

    .model-popup-content {
      margin-bottom: @thirty;

      .from-group {
        input {
          height: 42px;
          border-radius: 0;
        }
      }
    }

    .model-popup-footer {
      .save-btn {
        width: 160px;
        height: 50px;
        line-height: 50px;
        background: @orange;
        display: inline-block;
        font-size: 16px;
        font-weight: 600;
        color: @text-white;
        text-align: center;
      }
    }
  }
}

.modal-backdrop {
  &.fade {
    &.show {
      transition: unset;
    }
  }
}

.d-block {
  &.fade {
    transition: unset;
  }
}

.modal {
  &.fade {
    .modal-dialog {
      transition: unset;
    }
  }
}

.pdf-component canvas {
  height: auto !important;
  width: 100% !important;
}

.form-control-main select.form-control {
  background: #3c3939 url('/assets/images/select-down-arrow.svg') no-repeat;
  background-position-x: calc(100% - 15px);
  background-position-y: 45%;
}

.main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.company-name {
  width: 20%;
}

.main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.sign-up-date {
  width: 14%;
}

.main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.email {
  width: 18%;
}

.main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.phone-number {
  width: 13%;
}

.main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.assessment {
  width: 8%;
}

//
.main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.edit {
  width: 6%;
}

.main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.reset {
  width: 6%;
}

.main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.delete {
  width: 6%;
}

.main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.assign {
  width: 6%;
}

.main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.action {
  width: 3%;
}

// added for admin related rights
.main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.edit1 {
  width: 7%;
  text-align: center;
}

.main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.assessment1 {
  width: 9%;
}

.main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.reset1 {
  width: 7%;
  text-align: center;
}

.main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.delete1 {
  width: 6%;
  text-align: center;
}

.main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.action1 {
  width: 4%;
  text-align: center;
}

.sign-up-left img:first-child {
  max-width: 333px;
}

.main-wrapper .assessment-inner .navigation .navigation-logo img {
  max-width: 117px;
}

//AGENT SIGN UP START
.agent-sign-up-wrapper {
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;

  &:before {
    content: '';
    background: url('/assets/images/agent-bottom-bg.svg') no-repeat;
    width: 367px;
    height: 366px;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  &:after {
    content: '';
    background: url('/assets/images/agent-top-bg.svg') no-repeat;
    width: 367px;
    height: 366px;
    position: absolute;
    right: 0;
    top: 0;
  }

  .agent-sign-up-inner {
    max-width: 1680px;
    margin: 0 auto;
    padding: 0 20px;
    z-index: 1;

    .agent-sign-up-left {
      width: calc(50% - 40px);
      margin-right: 40px;

      .agent-sign-up-left-top {
        margin-bottom: 70px;
      }

      .agent-sign-up-left-bottom {
        img,
        iframe {
          width: 100%;
          height: 450px;
        }
      }
    }

    .agent-sign-up-right {
      width: calc(50% - 40px);
      margin-left: 40px;
      background: #ffffff;
      box-shadow: 0px 10px 100px rgba(0, 0, 0, 0.06);
      padding: 72px 72px 56px 72px;

      h2 {
        font-weight: 700;
        font-size: 34px;
        color: #231f20;
        margin-bottom: 60px;
      }

      .profit-advisor-top {
        margin-bottom: 16px;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
        padding: 12px;

        .profit-advisor-left {
          width: 50%;
          padding-right: 12px;
        }

        .profit-advisor-right {
          width: 50%;
          padding-left: 12px;

          ul {
            padding: 0;
            margin: 0;

            li {
              padding: 0;
              margin: 0;
              list-style: none;
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 12px;

              &:last-child {
                margin-bottom: 0;
              }

              p,
              label {
                font-size: 14px;
                font-weight: normal;
                color: #231f20;
              }
            }
          }
        }

        p {
          font-weight: 400;
          font-size: 18px;
          line-height: 22px;
        }

        label {
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;
        }
      }

      form {
        margin: 0 -12px;
        display: flex;
        flex-wrap: wrap;

        .form-control-main {
          width: calc(50% - 24px);
          margin: 0 12px 16px 12px;

          &.address-input {
            width: calc(100% - 24px);
          }

          &.profit-signup {
            p {
              font-size: 18px;

              a {
                font-weight: 600;
                font-size: 18px;
                color: #231f20;

                &:hover {
                  color: #fa7a23;
                  transition-duration: 0.5s;
                }
              }
            }
          }

          span {
            top: 10px;
          }

          .form-control {
            background: #ebebeb;
            color: #231f20;
            height: 42px;
            line-height: 35px;
          }

          &.phoneInput {
            .iti--allow-dropdown {
              width: 100%;

              .iti__flag-container {
                .iti__selected-flag.dropdown-toggle {
                  width: 100%;
                }
              }

              .country-dropdown {
                width: 230px;

                .iti__country-list {
                  li {
                    position: relative;

                    .iti__country-name {
                      left: 76px;
                      top: 6px;
                    }

                    .iti__dial-code {
                      left: 37px;
                      top: 6px;
                    }
                  }
                }
              }
            }

            .phoneInputCustom {
              width: 100%;
              max-width: 100%;
              height: 42px;
              line-height: 35px;
              padding-left: 85px !important;
            }
          }
        }
      }
    }
  }
}

//Account image set full screen
.height-sidebar-100 {
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.scroll-sidebar-100 {
  height: 100vh;
  overflow: auto;
}

.phoneInput .agentLandingPhoneInput {
  height: 42px;
}

.width-class-generator(@index) when (@index > 0) {
  @class-name: ~'w-@{index}';
  @class-namePX: ~'w-px-@{index}';

  .@{class-name} {
    width: ~'@{index}%' !important;
  }

  .@{class-namePX} {
    width: ~'@{index}px' !important;
  }

  .width-class-generator(@index - 1);
}

.width-class-generator(100);

.height-class-generator(@index) when (@index > -1) {
  @height-class-name: ~'h-@{index}';

  .@{height-class-name} {
    height: ~'@{index}%' !important;
  }

  .height-class-generator(@index - 1);
}

.height-class-generator(100);

#landingContent {
  line-height: 30px;
}

.exclamation_mark {
  border: 1px solid orange;
  border-radius: 50%;
  padding: 0.1em 0.4em;
  color: orange;
  max-height: 22px;
  display: flex;
  align-items: center;
  max-width: 22px;
  width: 22px;
  justify-content: center;
  margin-right: 0.4rem;
}

.word-break {
  word-break: break-all;
  word-break: break-word;
}

.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-bottom {
  margin-top: 15px;
  padding: 0 15px;
}

.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-bottom .next-btn {
  width: 120px;
  height: 46px;
  background: #fa7a23;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-bottom .prev-btn {
  width: 120px;
  height: 46px;
  background: #2c5c84;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content {
  padding-bottom: 15px;
}

.new-assessment-bottom .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content.active-faq .new-ass-que-left-content-content,
.new-assessment-bottom .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content.active-faq .new-ass-que-left-content-bottom {
  display: block;
}

.new-assessment-bottom .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-content,
.new-assessment-bottom .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-bottom {
  display: none;
}

.NoData {
  display: none !important;
}

.imageRoate {
  transform: rotate(180deg);
}

.adminList {
  background: transparent !important;
  box-shadow: 0 6px 30px #0000000f !important;
  padding: 24px !important;
  margin-bottom: 10px !important;
  color: #000000 !important;
}

.new-morgage .sign-up-left h2 {
  width: 100%;
  font-size: 3rem;
}

.main-wrapper .assessment-bottom .question-header .add-new.add-script-btn {
  width: auto;
  padding: 0 14px;
}

.Disclaimer {
  font-size: 16px;
  color: #231f20;
  font-weight: 500;
  padding: 10px;
}

.form-control-main .tooltip-inner {
  width: 840px;
  max-width: unset !important;
  position: absolute;
  right: -85px;
  bottom: 7px;
  text-align: left;
}

.agent-profit-plan-bottom .plan-type-header {
  display: none;
}

.agent-profit-plan-bottom .agent-profit-plan-bottom-content .agent-content-box .agent-content-box-link .second {
  justify-content: flex-end;
}

.signup-input-div.phoneInput .iti,
.signup-input-div.phoneInput .iti input {
  width: 100%;
}

// NEW CLASSES CSS START HERE
.new-classes-wrapper .new-classes-left {
  width: 210px;
  background: #fff;
  box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.06);
}

.new-classes-wrapper .new-classes-middle {
  width: calc(100% - 712px);
  margin: 0 20px;
  background: #fff;
  box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.06);
  padding: 20px;
  max-height: calc(100vh - 314px);
  overflow: auto;
}

.new-classes-wrapper .new-classes-right {
  width: 462px;
  background: #fff;
  box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.06);
  padding: 16px 16px 0 16px;
  max-height: calc(100vh - 314px);
  overflow: auto;
}

.new-classes-wrapper .new-classes-right .agent-profit-plan-bottom-content {
  columns: unset;
}

.new-classes-wrapper .new-classes-middle .new-classes-download-btn .download-btn-new {
  background: #fa7a23;
  padding: 0 12px;
  height: 32px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border: none;
  cursor: pointer;
}

.new-classes-wrapper .new-classes-middle .new-classes-download-btn .download-btn-new span,
.new-classes-wrapper .new-classes-middle .new-classes-download-btn .delete-btn-new span {
  color: #fff;
  font-size: 12px;
  font-weight: 400;
}

.new-classes-wrapper .new-classes-middle .new-classes-download-btn .delete-btn-new {
  background: #ec6363;
  padding: 0 12px;
  height: 32px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border: none;
  cursor: pointer;
}

.new-classes-wrapper .new-classes-middle .middle-heading {
  color: #231f20;
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
}

.new-classes-wrapper .new-classes-right .agent-content-box .agent-content-box-wrapper-new .agent-content-box-left {
  width: 170px;
  height: 110px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.new-classes-wrapper .new-classes-right .agent-content-box .agent-content-box-wrapper-new .agent-content-box-left img {
  max-height: 100%;
}

.new-classes-wrapper .new-classes-right .agent-content-box.video-upload .agent-content-box-wrapper-new .agent-content-box-left {
  background: #fff1e8;
}

.new-classes-wrapper .new-classes-right .agent-content-box.file-upload .agent-content-box-wrapper-new .agent-content-box-left {
  background: #e5f8f3;
}

.new-classes-wrapper .new-classes-right .agent-content-box.file-upload.ppt-bg .agent-content-box-wrapper-new .agent-content-box-left {
  background: #fff6f3;
}

.new-classes-wrapper .new-classes-right .agent-content-box.file-upload.pdf-bg .agent-content-box-wrapper-new .agent-content-box-left {
  background: #fff6f3;
}

.new-classes-wrapper .new-classes-right .agent-content-box.link-upload .agent-content-box-wrapper-new .agent-content-box-left {
  background: #f0f9ff;
}

.new-classes-wrapper .new-classes-right .agent-content-box .agent-content-box-wrapper-new .agent-content-box-right {
  width: calc(100% - 170px);
  padding-left: 16px;
  box-sizing: border-box;
}

.f-14-400 {
  font-size: 14px;
  font-weight: 400;
  color: #231f20;
}

.new-classes-wrapper .new-classes-right .agent-content-box .agent-content-box-wrapper-new .agent-content-box-right h4 {
  height: 74px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.new-classes-wrapper .new-classes-right .agent-content-box .agent-content-box-wrapper-new .agent-content-box-right span {
  width: calc(100% - 34px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.new-classes-wrapper .new-classes-right .agent-content-box .agent-content-box-wrapper-new .agent-content-box-right span a {
  color: #009bff;
  font-size: 12px;
  font-weight: 600;
}

.new-classes-wrapper .new-classes-right .agent-content-box .agent-content-box-wrapper-new .agent-content-box-right .new-delete-icon {
  width: 24px;
  margin-left: 10px;
  height: 24px;
  background: #ec6363;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.new-classes-wrapper .new-classes-middle .new-classes-download-btn .new-classes-download-btn-left {
  width: calc(100% - 300px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.new-classes-wrapper .new-classes-middle .new-classes-download-btn .new-classes-download-btn-left span {
  color: #231f20;
  font-size: 12px;
  font-weight: 400;
}

.new-classes-wrapper .new-classes-middle .new-classes-download-btn .new-classes-download-btn-right {
  width: 300px;
  padding-left: 10px;
}

.new-classes-wrapper .new-classes-left .agent-profit-plan-bottom-topbar .agent-topbar-wrapper a {
  color: #231f20;
  font-size: 14px;
  font-weight: 400;
  padding: 10px 12px;
  display: inline-block;
  width: 100%;
}

.new-classes-wrapper .new-classes-middle .pdf-upload-prev .preview-middel,
.new-classes-wrapper .new-classes-middle .ppt-upload-prev .preview-middel {
  // width: 220px;
  // height: 56px;
  // background: #FFF6F3;
  // padding-left: 16px;
  box-sizing: border-box;
  justify-content: center;
}

.new-classes-wrapper .new-classes-middle .text-upload-prev .preview-middel {
  // width: 220px;
  // height: 56px;
  // background: #F0F9FF;
  // padding-left: 16px;
  // box-sizing: border-box;
}

.new-classes-wrapper .new-classes-middle .text-upload-prev .preview-middel p {
  color: #009bff;
  font-size: 12px;
  font-weight: 400;
  margin-left: 6px;
}

.new-classes-wrapper .new-classes-middle .pdf-upload-prev .preview-middel p,
.new-classes-wrapper .new-classes-middle .ppt-upload-prev .preview-middel p {
  color: #231f20;
  font-size: 12px;
  font-weight: 400;
  margin-left: 6px;
}

// .new-classes-wrapper .new-classes-middle .text-upload-prev .preview-middel img,
// .new-classes-wrapper .new-classes-middle .pdf-upload-prev .preview-middel img,
// .new-classes-wrapper .new-classes-middle .ppt-upload-prev .preview-middel img {
//   max-width: 300px;
// }

.new-classes-wrapper .new-classes-middle .image-upload-prev .preview-middel {
  width: 100%;
  height: 200px;
  background: #f0f9ff;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.new-classes-wrapper .new-classes-middle .image-upload-prev .preview-middel {
  height: auto;
  min-height: 200px;
}

.new-classes-wrapper .new-classes-middle .image-upload-prev .preview-middel span {
  text-align: center;
}

// .new-classes-wrapper .new-classes-middle .ppt-upload-prev .preview-middel {
//   background: #FFF6F3;
// }

.new-classes-wrapper .new-classes-middle .image-upload-prev .preview-middel {
  background: #e5f8f3;
}

.image-preview {
  height: 5rem;
  margin: 1rem 0;
}

.image-preview img {
  height: 100%;
}

.agent-profit-plan-bottom .agent-profit-plan-bottom-content .agent-content-box {
  cursor: pointer;
}

.text-center-middle {
  width: 100%;
  height: 200px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.previous-coaching-wrapper {
  background: #fff;
  box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.06);
  width: 100%;
  padding: 16px;
}

.previous-coaching-wrapper .previous-coaching-search {
  margin-bottom: 16px;
}

.previous-coaching-wrapper .previous-coaching-search a {
  width: 90px;
  height: 42px;
  line-height: 40px;
  background: #fa7a23;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

.previous-coaching-wrapper .previous-coaching-search input {
  border: 1px solid #231f20;
  width: calc(100% - 110px);
  height: 42px;
  padding-left: 20px;
  color: #231f20;
  transition: 0.5s;
  border-radius: 0;
  margin-right: 20px;
}

.previous-coaching-wrapper .previous-coaching-search input:hover,
.previous-coaching-wrapper .previous-coaching-search input:focus {
  border-color: #fa7a23;
}

.previous-coaching-table .previous-coaching-table-head {
  background: #231f20;
  width: 100%;
  padding: 12px 0;
}

.previous-coaching-table .previous-coaching-table-head li {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}

.previous-coaching-table .previous-coaching-table-content .list-video-name {
  color: #231f20;
  font-size: 14px;
  font-weight: 400;
  padding-left: 16px;
  box-sizing: border-box;
  width: calc(100% - 88px);
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.previous-coaching-table .previous-coaching-table-content .action {
  text-align: right;
}

.previous-coaching-table .previous-coaching-table-content .action,
.previous-coaching-table .previous-coaching-table-head li.action {
  width: 80px;
  padding: 0 16px;
}

.previous-coaching-table .previous-coaching-table-content .clickhere,
.previous-coaching-table .previous-coaching-table-head li.clickhere {
  width: 82px;
  padding: 0 16px;
}

.previous-coaching-table .previous-coaching-table-content .modified,
.previous-coaching-table .previous-coaching-table-head li.modified {
  width: 176px;
  padding: 0 16px;
}

.previous-coaching-table .previous-coaching-table-content .title,
.previous-coaching-table .previous-coaching-table-head li.title {
  width: calc(100% - 338px);
  padding: 0 16px;
}

.previous-coaching-table .previous-coaching-table-content .modified {
  color: #231f20;
  font-size: 14px;
  font-weight: 400;
}

.previous-coaching-table .previous-coaching-table-content .clickhere a {
  color: #fa7a23;
  font-size: 10px;
  font-weight: 600;
}

.previous-coaching-table .previous-coaching-table-content .previous-coaching-table-wrapper {
  // padding: 12px 0;
  border-bottom: 1px solid #dad9dc;
}

.previous-coaching-table .previous-coaching-table-content .previous-coaching-table-wrapper:last-child {
  border: none;
}

.previous-coaching-table .previous-coaching-table-content {
  border: 1px solid #dad9dc;
  border-top: none;
  max-height: calc(100vh - 467px);
  overflow: auto;
}

.prec-detail-wrapper .prec-detail-wrapper-left {
  background: #fff;
  box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.06);
  width: calc(100% - 482px);
  margin-right: 20px;
  padding: 20px;
  box-sizing: border-box;
  max-height: calc(100vh - 314px);
  overflow: auto;
}

.prec-detail-wrapper .prec-detail-wrapper-left h3 {
  color: #231f20;
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 10px;
}

.prec-detail-wrapper .prec-detail-wrapper-left .video-des {
  color: #231f20;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin: 0 0 20px 0;
}
.prec-detail-wrapper .prec-detail-wrapper-left .video-des a,
.prec-detail-wrapper .prec-detail-wrapper-left .video-des span,
.prec-detail-wrapper .prec-detail-wrapper-left .video-des p {
  font-size: 16px;
}

.prec-detail-wrapper .prec-detail-wrapper-left .video-des a {
  color: #fa7a23;
  word-break: break-all;
}
.prec-detail-wrapper .prec-detail-wrapper-left .video-btn-only p {
  // white-space: nowrap;
  // width: calc(100% - 197px);
  // margin-right: 20px;
  // overflow: hidden;
  // text-overflow: ellipsis;
}

.prec-detail-wrapper .prec-detail-wrapper-left .video-btn-only a svg {
  margin-right: 8px;
}

.prec-detail-wrapper .prec-detail-wrapper-left .video-btn-only a.edit-prev-btn {
  background: #fa7a23;
}

.prec-detail-wrapper .prec-detail-wrapper-left .video-btn-only a.video-delete-btn {
  width: 85px;
  height: 32px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background: #ec6363;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
}

.prec-detail-wrapper .prec-detail-wrapper-right {
  background: #fff;
  box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.06);
  width: 462px;
  padding: 20px;
  box-sizing: border-box;
}

.previous-coaching-table ul li {
  list-style: none;
}

.prec-detail-wrapper .prec-detail-wrapper-right .pre-call-content-box-wrapper {
  max-height: calc(100vh - 412px);
  overflow: auto;
}

.prec-detail-wrapper .prec-detail-wrapper-right .pre-call-content-box:last-child {
  margin-bottom: 0;
}

.prec-detail-wrapper .prec-detail-wrapper-right .pre-call-content-box {
  width: 100%;
  margin-bottom: 16px;
  cursor: pointer;
}

.prec-detail-wrapper .prec-detail-wrapper-right .pre-call-content-box .agent-content-box-wrapper-new {
  padding-right: 5px;
}

.prec-detail-wrapper .prec-detail-wrapper-right .pre-call-content-box .agent-content-box-left {
  width: 170px;
  background: #f1f1f1;
  text-align: center;
}
.prec-detail-wrapper .prec-detail-wrapper-right .pre-call-content-box .agent-content-box-left img {
  max-height: 116px;
}

.prec-detail-wrapper .prec-detail-wrapper-right .pre-call-content-box .agent-content-box-right {
  width: calc(100% - 170px);
  padding-left: 16px;
}

.prec-detail-wrapper .prec-detail-wrapper-right .pre-call-content-box .agent-content-box-right h4 {
  color: #231f20;
  font-size: 14px;
  line-height: 24px;
  height: 74px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 12px;
}

.prec-detail-wrapper .prec-detail-wrapper-right .pre-call-content-box .agent-content-box-right span {
  width: calc(100% - 55px);
  margin-right: 6px;
  color: #231f20;
  font-size: 12px;
  font-weight: 400;
  white-space: nowrap;

  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.prec-detail-wrapper .prec-detail-wrapper-right .pre-call-content-box .agent-content-box-right span a {
  color: #231f20;
  font-size: 12px;
  font-weight: 400;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.prec-detail-wrapper .prec-detail-wrapper-right .pre-call-content-box .agent-content-box-right .new-delete-icon {
  height: 24px;
  width: 24px;
  background: #ec6363;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.prec-detail-wrapper .prec-detail-wrapper-right .right-coaching-search {
  margin-bottom: 16px;
}

.prec-detail-wrapper .prec-detail-wrapper-right .right-coaching-search input {
  border: 1px solid #231f20;
  width: calc(100% - 110px);
  height: 42px;
  padding-left: 20px;
  color: #231f20;
  transition: 0.5s;
  border-radius: 0;
  margin-right: 20px;
}

.prec-detail-wrapper .prec-detail-wrapper-right .right-coaching-search input:hover,
.prec-detail-wrapper .prec-detail-wrapper-right .right-coaching-search input:focus {
  border-color: #fa7a23;
}

.prec-detail-wrapper .prec-detail-wrapper-right .right-coaching-search a {
  width: 90px;
  height: 42px;
  line-height: 40px;
  background: #fa7a23;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

.model-popup .question-bottom-wrapper .right-side .form-control-main.upload-img .position-relative + p,
.model-popup .question-bottom-wrapper .right-side .form-control-main.upload-img .image-preview + p {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.previous-coaching-popup.model-popup-wrapper .model-popup {
  width: 440px;
}

.cursor-pointer {
  cursor: pointer;
}

// new css for drag start
.cdk-drag-preview.courses-drag {
  width: calc(25% - 24px);
  margin: 0 12px 24px 12px;
  cursor: pointer;
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06);
}

.cdk-drag-preview.courses-drag .all-courses-img {
  height: 220px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.cdk-drag-preview.courses-drag img {
  max-height: 220px;
}

.cdk-drag-preview.courses-drag .all-courses-heading {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 16px 0 8px 0;
  padding: 0 12px;
}

.cdk-drag-preview.courses-drag .all-courses-heading p {
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #231f20;
  width: calc(100% - 50px);
  cursor: pointer;
  height: 50px;
  overflow: hidden;
  word-break: break-word;
}

.cdk-drag-preview.courses-drag .all-courses-heading span {
  width: 50px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background: rgba(44, 92, 132, 0.1);
  font-size: 14px;
  color: #2c5c84;
  height: 28px;
}

.cdk-drag-preview.courses-drag .all-courses-heading span svg {
  width: 14px;
  margin-right: 8px;
}

.cdk-drag-preview.courses-drag .all-courses-p {
  margin-bottom: 8px;
  color: #231f20;
  font-size: 14px;
  line-height: 24px;
  padding: 0 12px;
  height: 50px;
  overflow: hidden;
  word-break: break-word;
}

.cdk-drag-preview.courses-drag .all-courses-bottom {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background: #f9f9f9;
  padding: 9px 12px;
  width: calc(100% - 24px);
  margin: 0 12px 12px 12px;
}

.cdk-drag-preview.courses-drag .all-courses-bottom a {
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: #fa7a23;
}

.cdk-drag-preview.courses-drag .all-courses-bottom a svg {
  width: 12px;
  margin-right: 10px;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list-dragging .cdk-drag {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.example-custom-placeholder {
  background: #e7e6e6;
  border: dashed 3px #999;
  // height: 400px;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.inner {
  width: 100%;
  height: 100%;
  cursor: move;
}

.categories-item {
  height: 400px;
  position: relative;
}

.form-control-main .signup-uri {
  width: calc(100% - 40px);
}
.form-control-main .signup-tooltip {
  width: 40px;
  height: 50px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}
.form-control-main .signup-tooltip svg {
  cursor: help;
}
.form-control-main .signup-tooltip .signup-tooltip-content {
  position: absolute;
  top: 45px;
  background: #000;
  font-size: 14px;
  padding: 10px;
  width: 100%;
  display: none;
}
.form-control-main .signup-tooltip .signup-tooltip-content:before {
  content: '';
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #000;
  position: absolute;
  top: -5px;
  right: 7px;
}
.form-control-main .signup-tooltip:hover .signup-tooltip-content {
  display: block;
}
.form-control-main .signup-tooltip .signup-tooltip-content p {
  font-size: 13px;
  color: #b4b1b1;
}
.signup-uri-input {
  text-transform: lowercase;
}

//  classes detail drag and drop css for preview
.cdk-drag-preview .agent-content-box .agent-content-box-wrapper-new .agent-content-box-left {
  width: 170px;
  height: 110px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.cdk-drag-preview .agent-content-box.link-upload .agent-content-box-wrapper-new .agent-content-box-left {
  background: #f0f9ff;
}
.cdk-drag-preview .agent-content-box .agent-content-box-wrapper-new .agent-content-box-left img {
  max-height: 100%;
}
.cdk-drag-preview .agent-content-box .agent-content-box-wrapper-new .agent-content-box-right {
  width: calc(100% - 170px);
  padding-left: 16px;
  box-sizing: border-box;
}
.cdk-drag-preview .agent-content-box .agent-content-box-wrapper-new .agent-content-box-right h4 {
  height: 74px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #231f20;
  margin-bottom: 12px;
}
.cdk-drag-preview .agent-content-box .agent-content-box-wrapper-new .agent-content-box-right span {
  width: calc(100% - 34px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  color: #000000;
}

.cdk-drag-preview .agent-content-box .agent-content-box-wrapper-new .agent-content-box-right .new-delete-icon {
  width: 24px;
  margin-left: 10px;
  height: 24px;
  background: #ec6363;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.agent-profit-plan-classes-drag-placeholder {
  background: #e7e6e6;
  border: dashed 3px #999;
  // height: 110px;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.cdk-drag-preview .agent-content-box .agent-content-box-wrapper-new .agent-content-box-right span a {
  color: #009bff;
  font-size: 12px;
  font-weight: 600;
}
.cdk-drag-preview .agent-content-box.file-upload .agent-content-box-wrapper-new .agent-content-box-left {
  background: #e5f8f3;
}

.cdk-drag-preview .agent-content-box.file-upload.pdf-bg .agent-content-box-wrapper-new .agent-content-box-left {
  background: #fff6f3;
}
.cdk-drag-preview .agent-content-box.file-upload.ppt-bg .agent-content-box-wrapper-new .agent-content-box-left {
  background: #fff6f3;
}
.new-classes-wrapper .new-classes-right .cdk-drop-list {
  height: 126px;
  position: relative;
}
.new-classes-wrapper .new-classes-right .cdk-drop-list .cdk-drag {
  width: 100%;
  height: 100%;
  cursor: move;
}

.resource-wrapper .resource-title {
  color: #231f20;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
}
.resource-wrapper .resource-inner {
  margin: 0 -8px;
}
.resource-wrapper .resource-inner .resource-box {
  width: calc(25% - 16px);
  margin: 0 8px 16px 8px;
  background: #f9f9f9;
  padding: 8px;
  box-sizing: border-box;
}
.resource-wrapper .resource-inner .resource-box .resource-box-left {
  width: 100%;
}
.resource-wrapper .resource-inner .resource-box .resource-box-right {
  width: 100%;
  padding-top: 8px;
  box-sizing: border-box;
}
.resource-wrapper .resource-inner .resource-box .resource-box-right .resource-title {
  color: #231f20;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  margin-bottom: 8px;
}
.resource-wrapper .resource-inner .resource-box .resource-box-right .resource-email label,
.resource-wrapper .resource-inner .resource-box .resource-box-right .resource-link label,
.resource-wrapper .resource-inner .resource-box .resource-box-right .resource-link a,
.resource-wrapper .resource-inner .resource-box .resource-box-right .resource-desc {
  color: #231f20;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  margin-bottom: 8px;
}
.resource-wrapper .resource-inner .resource-box .resource-box-right .resource-email span,
.resource-wrapper .resource-inner .resource-box .resource-box-right .resource-link span {
  width: 20px;
}
.resource-wrapper .resource-inner .resource-box .resource-box-right .resource-email label,
.resource-wrapper .resource-inner .resource-box .resource-box-right .resource-link a {
  width: calc(100% - 20px);
}
.resource-wrapper .resource-inner .resource-box .resource-box-right .resource-link a:hover {
  color: #fa7a23;
}
.resource-wrapper .resource-inner .resource-box .resource-box-right .resource-box-right-top {
  margin-bottom: 7px;
}
.resource-wrapper .resource-inner .resource-box .resource-box-right .resource-box-right-top p {
  // color: #231f20;
  // font-size: 12px;
  // line-height: 19px;
  // height: 38px;
  // overflow: hidden;
  // display: -webkit-box;
  // -webkit-line-clamp: 2;
  // -webkit-box-orient: vertical;
  // word-break: break-all;
}

.resource-wrapper .resource-inner .resource-box .resource-box-right .resource-box-right-bottom .small-download span {
  color: #fa7a23;
  font-size: 10px;
  font-weight: 600;
}
.upload-file-wrapper-prev {
  margin: 0 -6px;
  max-height: 158px;
  overflow: auto;
}
.upload-file-wrapper-prev .upload-file-box-prev {
  width: calc(50% - 12px);
  margin: 0 6px 12px 6px;
  padding: 10px;
  background: #f9f9f9;
}
.upload-file-wrapper-prev .upload-file-box-prev p {
  color: #231f20;
  font-size: 12px;
  font-weight: 400;
  width: calc(100% - 16px);
  padding-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cdk-drag-preview .title {
  width: calc(100% - 338px);
  padding: 0 16px;
}

.cdk-drag-preview .modified {
  width: 176px;
  padding: 0 16px;
  color: #231f20;
  font-size: 14px;
  font-weight: 400;
}
.cdk-drag-preview .clickhere {
  width: 82px;
  padding: 0 16px;
}
.cdk-drag-preview .clickhere a {
  color: #fa7a23;
  font-size: 10px;
  font-weight: 600;
}
.cdk-drag-preview .action {
  width: 80px;
  padding: 0 16px;
}
.cdk-drag-preview .list-video-name {
  color: #231f20;
  font-size: 14px;
  font-weight: 400;
  padding-left: 16px;
  box-sizing: border-box;
  width: calc(100% - 88px);
}
.previous-coaching-table-content .previous-coaching-call-placeholder-wrapper {
  width: 100%;
  height: 100%;
  cursor: move;
  padding: 12px 0;
}

.previous-coaching-call-placeholder {
  background: #e7e6e6;
  border: dashed 3px #999;
  // height: 400px;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.previous-coaching-table-content .cdk-drop-list {
  height: 78px;
  position: relative;
}

.pre-call-content-box-wrapper .cdk-drop-list {
  height: 116px;
  position: relative;
}

.cdk-drag-preview .agent-content-box-left {
  width: 170px;
  background: #f1f1f1;
}
.cdk-drag-preview .agent-content-box-right {
  width: calc(100% - 170px);
  padding-left: 16px;
}
.cdk-drag-preview .agent-content-box-right h4 {
  color: #231f20;
  font-size: 14px;
  line-height: 24px;
  height: 74px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 12px;
}

.all-courses-wrapper .all-courses-inner .all-courses-heading p {
  height: 54px !important;
}

.newplan-link .ck.ck-editor__editable_inline {
  background: #ebebeb;
  border-color: #ebebeb;
}
.newplan-link.form-control-main span {
  position: unset;
}
.question-bottom-wrapper .question-bottom-inner form .newplan-link form {
  margin: 0;
}
.newplan-link .ck.ck-editor__editable_inline a {
  color: #fa7a23;
}
.newplan-link .ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
  border-color: #ebebeb;
  box-shadow: none;
}
.newplan-link .ck-body-inner {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.newplan-link .ck-body-inner .ck.ck-toolbar {
  display: flex;
  order: 1;
  margin-left: auto;
  width: 40px;
  height: 48px;
}
.newplan-link .ck-body-inner .ck.ck-content {
  width: calc(100% - 50px);
}
.ck.ck-powered-by {
  display: none;
}
.description-heading {
  margin: 20px 0 10px 0;
}
.description-heading p {
  color: #231f20;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
}
.profit-course.previous-coaching-popup .model-popup-inner {
  max-height: 90%;
  overflow: auto;
}
.resource-add-heading {
  margin-bottom: -32px;
}
.resource-add-heading a {
  padding: 0 12px;
  height: 32px;
  background: #fa7a23;
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.video-des-email {
  width: calc(100% - 85px);
  padding-right: 10px;
}
.video-des-email .video-des--link {
  margin-right: 16px;
}
.video-des-email .video-des--link a {
  color: #231f20;
}
.video-des-email .video-des--link a:hover {
  color: #fa7a23;
}
.inquiry-search-heade .assement-search.profit-filter {
  width: 23%;
  margin: 0;
  flex-grow: unset;
  padding-right: 16px;
}
.inquiry-search-heade .inquiry-search-wrapper {
  width: 77%;
}
.main-wrapper .assessment-bottom .inquiry-search-heade .assement-search.inquire {
  width: calc(100% - 97px);
}
.question-bottom-inner .form-control-main .form-control {
  color: #212529;
}

.previous-coaching-popup .question-bottom-wrapper .question-bottom-inner form .from-group {
  padding: 0;
  margin-right: 15px;
}

.profit-course .model-popup-inner {
  overflow-x: hidden !important;
}

.previous-coaching-table .previous-coaching-table-content .list-video-name {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.main-wrapper .assessment-topbar .userinfo .header-logo img {
  width: auto;
  height: auto;
}
.main-wrapper .assessment-topbar .userinfo .header-logo {
  padding-right: 40px;
  margin-right: 40px;
  border-right: 2px solid #fff;
}
.all-courses-wrapper {
  max-height: calc(100vh - 196px) !important;
}
.modal-content {
  border: none;
}
.preview-middel span.w-100 {
  text-align: center;
}

.new-canva-right {
  width: calc(100% - 226px);
  margin-left: 16px;
  box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.06);
  padding: 16px;
}
.new-canva-right .new-canva-right-search .new-canva-right-search-left {
  width: calc(100% - 114px);
}
.new-canva-right .new-canva-right-search .new-canva-right-search-left input {
  height: 42px;
  border: 1px solid #231f20;
  padding: 0 10px;
  width: 100%;
  box-sizing: border-box;
}
.new-canva-right .new-canva-right-search .new-canva-right-search-right {
  width: 114px;
  padding-left: 24px;
}
.new-canva-right .new-canva-right-search .new-canva-right-search-right button {
  width: 90px;
  height: 42px;
  background: #fa7a23;
  border: none;
  color: #fff;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
  align-items: center;
  justify-content: center;
}

.new-canva-right-table .new-canva-right-table-head {
  background: #231f20;
  padding: 12px 16px;
}
.new-canva-right-table .new-canva-right-table-head li,
.new-canva-right-table .new-canva-right-table-content-wrapper .new-canva-right-table-content li {
  list-style: none;
}
.new-canva-right-table .new-canva-right-table-content-wrapper .new-canva-right-table-content .canva-table-title,
.new-canva-right-table .new-canva-right-table-head .canva-table-title {
  width: calc(100% - 100px);
}
.new-canva-right-table .new-canva-right-table-content-wrapper .new-canva-right-table-content .canva-table-action,
.new-canva-right-table .new-canva-right-table-head .canva-table-action {
  width: 100px;
}
.new-canva-right-table .new-canva-right-table-head .canva-table-title {
  color: #fff;

  font-size: 14px;
  font-weight: 600;
}
.new-canva-right-table .new-canva-right-table-head .canva-table-action {
  color: #fff;

  font-size: 14px;
  font-weight: 600;
}

.new-canva-right-table .new-canva-right-table-content-wrapper .new-canva-right-table-content .canva-table-title .canva-table-title-img img {
  width: 88px;
}
.new-canva-right-table .new-canva-right-table-content-wrapper {
  border: 1px solid #dad9dc;
  border-bottom: none;
}
.new-canva-right-table .new-canva-right-table-content-wrapper .new-canva-right-table-content .canva-table-title .canva-table-title-img p {
  width: calc(100% - 88px);
  font-size: 16px;
  padding-left: 16px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
}
.new-canva-right-table .new-canva-right-table-content-wrapper .new-canva-right-table-content {
  border-bottom: 1px solid #dad9dc;
  padding: 0 0;
}
.new-canva-right-table .new-canva-right-table-content-wrapper .new-canva-right-table-content .script-canva-table-thead {
  padding: 12px 16px 12px 16px;
  align-items: center;
}
.new-canva-right-table-content-visible {
  margin: 16px 16px 16px 16px;
}
.new-canva-right-table-content-visible .new-canva-right-table-content-visible-left {
  width: 684px;
}
.new-canva-right-table-content-visible .new-canva-right-table-content-visible-right {
  width: calc(100% - 684px);
  padding-left: 24px;
}
.new-canva-right-table-content-visible .new-canva-right-table-content-visible-right p {
  color: #231f20;
  font-size: 16px;
  line-height: 26px;
  height: calc(100% - 32px);
  padding-bottom: 10px;
}
.new-canva-right-table-content-visible .new-canva-right-table-content-visible-right .new-canva-right-table-content-visible-right-bottom {
  height: 32px;
}
.new-canva-right-table-content-visible
  .new-canva-right-table-content-visible-right
  .new-canva-right-table-content-visible-right-bottom
  .d-button {
  background: #fa7a23;
  height: 32px;
  padding: 0 12px;
  color: #fff;
  text-align: center;
  font-size: 12px;
  border: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.new-canva-right-table .new-canva-right-table-content-wrapper.active-table .script-canva-table-thead {
  background: #f2f2f2;
}
.ck-body-wrapper.email-text-ckeditor {
  width: calc(100% - 30px);
  margin: 0 15px 0 15px;
  background: #ebebeb;
  padding: 8px;
}
.ck-body-wrapper.email-text-ckeditor .ck.ck-toolbar {
  background: #ebebeb;
  border: none;
}
.ck-body-wrapper.email-text-ckeditor .ck.ck-content {
  min-height: 100px;
}
.ck-body-wrapper.email-text-ckeditor .ck.ck-content a {
  color: #fa7a23;
}
.ck-body-wrapper.email-text-ckeditor .ck.ck-content ul {
  padding-left: 1rem;
}
.question-bottom-wrapper .question-bottom-inner .email-text-ckeditor form {
  margin: 0;
}
.ck-body-wrapper.email-text-ckeditor .ck.ck-content:focus,
.ck-body-wrapper.email-text-ckeditor .ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable),
.ck-body-wrapper.email-text-ckeditor .ck.ck-content:focus-visible {
  outline: none;
  border: 1px solid transparent;
  box-shadow: none;
}
.received_msg .received_withd_msg a {
  color: #fa7a23;
}
.received_msg .received_withd_msg strong u {
  font-weight: bolder;
}
.ck-body-wrapper.email-text-ckeditor .ck.ck-content strong u {
  font-weight: bolder;
}
.mass-heading {
  margin-left: 16px;
  margin-right: 16px;
  font-size: 16px;
}
.invalid-feedback-msg {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 12px;
  color: #dc3545;
}
