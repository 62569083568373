@media (max-width: 1600px) {
  .resource-wrapper .resource-inner .resource-box {
    width: calc(50% - 24px);
  }
}
@media (min-width: 1300px) and (max-width: 1600px) {
  .main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.assessment {
    width: 9%;
  }

  .main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.assign {
    width: 7%;
  }

  .main-wrapper .assessment-bottom .assesment-table.profitLogin ul li.email {
    width: 16%;
  }

  .form-control-main .tooltip-inner {
    width: 600px;
  }
}

@media (min-width: 1366px) {
  .new-ass-que-wrapper .new-ass-que-left {
    max-height: calc(100vh - 142px);
  }

  .new-ass-que-wrapper .new-ass-que-right {
    max-height: calc(100vh - 142px);
    overflow-y: auto;
    overflow-x: hidden;
  }
}

@media (max-width: 1366px) {
  .new-ass-que-wrapper {
    .new-ass-que-left {
      width: @full;
      margin-bottom: @twenty;
    }

    .new-ass-que-right {
      width: @full;
      padding-left: 0;
    }
  }

  .agent-sign-up-wrapper {
    .agent-sign-up-inner {
      .agent-sign-up-right {
        padding: 40px;
      }
    }
  }

  .form-control-main .tooltip-inner {
    width: 600px;
  }

  .new-classes-wrapper .new-classes-right {
    width: 300px;
  }

  .new-classes-wrapper .new-classes-right .agent-content-box .agent-content-box-wrapper-new .agent-content-box-left {
    width: 110px;
  }

  .new-classes-wrapper .new-classes-right .agent-content-box .agent-content-box-wrapper-new .agent-content-box-right {
    width: calc(100% - 110px);
  }

  .new-classes-wrapper .new-classes-middle {
    width: calc(100% - 550px);
  }

  .new-classes-wrapper .new-classes-right .agent-content-box.video-upload .agent-content-box-wrapper-new .agent-content-box-left video {
    width: 100%;
  }
  .new-canva-right-table-content-visible .new-canva-right-table-content-visible-left {
    width: 400px;
  }
  .new-canva-right-table-content-visible .new-canva-right-table-content-visible-right {
    width: calc(100% - 400px);
  }
}

@media (max-width: 1290px) {
  .new-classes-wrapper .new-classes-left {
    width: 100%;
    margin-bottom: 20px;
  }

  .new-classes-wrapper .new-classes-middle {
    width: 100%;
    margin: 0 0 20px 0;
  }

  .new-classes-wrapper .new-classes-right {
    width: 100%;
  }

  .prec-detail-wrapper .prec-detail-wrapper-left {
    width: 100%;
    margin-bottom: 30px;
    margin-right: 0;
    max-height: unset;
    overflow: unset;
  }

  .prec-detail-wrapper .prec-detail-wrapper-right {
    width: 100%;
  }

  .prec-detail-wrapper .prec-detail-wrapper-right .pre-call-content-box-wrapper {
    max-height: unset;
    overflow: unset;
  }
  .new-canva-right {
    width: 100%;
    margin-left: 0;
  }
}

@media (max-width: 1200px) {
  .agent-sign-up-wrapper {
    .agent-sign-up-inner {
      .agent-sign-up-right {
        .profit-advisor-top {
          .profit-advisor-left {
            width: 100%;
            margin-bottom: 20px;
            padding-right: 0;
          }

          .profit-advisor-right {
            width: 100%;
            padding-left: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .main-wrapper {
    .assessment-bottom {
      .assesment-table {
        width: @full;
        overflow: auto;

        .assesment-table-content {
          height: ~'calc(100vh - 430px)';
        }
      }

      .assesment-table-inner {
        width: 1200px;
      }

      .assement-search {
        width: @full;
        margin-bottom: @twenty - 10;
      }

      .profit-filter {
        width: @full;
        margin: 0;
        margin-bottom: @twenty - 10;
      }

      .assement-select {
        padding-left: 0;
      }

      .assesment-button {
        width: ~'calc(100% - 357px)';
        text-align: right;
      }
    }
  }

  .new-user-detail-wrapper {
    .new-user-detail-left {
      width: @full;
      padding-right: 0;
      border-right: none;
      margin-bottom: @twenty;

      a {
        word-break: break-all;
      }
    }

    .new-user-detail-right {
      width: 100%;
      padding-left: 0;
    }
  }

  .main-wrapper .assessment-bottom .agent-profit-adviser .assement-search {
    width: calc(100% - 100px);
    margin-bottom: 0;
    padding-right: 10px;
  }

  .main-wrapper .assessment-bottom .agent-profit-adviser .assesment-button {
    width: 90px;
  }
  .inquiry-search-heade .assement-search.profit-filter {
    padding-right: 0;
  }
  .inquiry-search-heade .inquiry-search-wrapper {
    width: 100%;
  }
  .main-wrapper .assessment-bottom .inquiry-search-heade .assesment-button {
    width: 90px;
  }
}

@media (max-width: 991px) {
  .signup-input-div.phoneInput .iti {
    width: 100%;
  }

  .signup-input-div.phoneInput .iti input {
    width: 100%;
  }

  .main-banner-wrapper .main-banner-right iframe {
    width: 100%;
  }

  .signup-img iframe {
    width: 100% !important;
  }

  .main-wrapper {
    .assessment-bottom {
      padding: @twenty;
    }
  }

  .question-list-wrapper {
    .question-inner {
      .question-list-main {
        .question-list {
          .question-list-content-wrapper {
            .question-list-content {
              width: ~'calc(100% - 40px)';
            }
          }
        }
      }
    }
  }

  .new-ass-que-wrapper {
    .new-ass-que-left {
      .new-ass-que-left-content {
        ul {
          li {
            &.ass-questions {
              width: ~'calc(100% - 30px)';
            }

            &.hint {
              margin-right: 0;
            }

            &.answers {
              width: ~'calc(100% - 80px)';
              margin-top: @twenty;
            }

            &.percentage {
              margin-top: @twenty;
            }
          }
        }
      }
    }
  }

  .model-popup-wrapper {
    .model-popup-inner {
      width: @full;
    }

    .model-popup {
      width: ~'calc(100% - 30px)';
      margin: 0 auto;
    }
  }

  .agent-sign-up-wrapper {
    height: auto;

    &:after,
    &:before {
      background-size: 175px;
      height: 175px;
      width: 175px;
    }

    .agent-sign-up-inner {
      .agent-sign-up-left {
        width: 100%;
        margin: 40px 0;

        .agent-sign-up-left-bottom {
          img,
          iframe {
            height: 340px;
          }
        }

        .agent-sign-up-left-top {
          margin-bottom: 40px;
        }
      }

      .agent-sign-up-right {
        width: 100%;
        margin: 0 0 50px 0;
      }
    }
  }

  .agent-classes-detail {
    .agent-profit-plan-bottom {
      .plan-type-header {
        background: #eeeeee;
        padding: 16px;
        display: block;

        &.accordian-up-img {
          + .agent-topbar-wrapper {
            display: flex !important;
          }

          img {
            transform: rotate(180deg);
          }
        }
      }

      .agent-topbar-wrapper {
        display: none !important;

        a {
          width: 100%;
          // padding: 0 16px !important;
        }
      }
    }
  }

  body .main-wrapper .assessment-bottom .assesment-table ul li.action-profit-ad.delete:before {
    content: 'Delete :';
  }

  body .main-wrapper .assessment-bottom.inquiry-assessment-bottom .assement-search.profit-filter select {
    border-radius: 0;
  }
  .new-canva-right-table-content-visible .new-canva-right-table-content-visible-left {
    width: 300px;
  }
  .new-canva-right-table-content-visible .new-canva-right-table-content-visible-right {
    width: calc(100% - 300px);
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  body .main-wrapper {
    .assessment-bottom {
      padding: 20px 15px;

      .assement-select {
        width: ~'calc(100% - 90px)';
      }

      .assesment-button {
        width: 90px;
        margin-left: 0 !important;
      }

      .assesment-table-inner {
        width: unset;
      }

      .assesment-table {
        padding: 0;

        .assesment-table-header {
          display: none;
        }

        .assesment-table-content {
          height: calc(100vh - 174px) !important;

          ul {
            &.table-main-content {
              padding: 10px 22px 10px 10px;
              position: relative;
              margin: 0 -5px;
              box-sizing: border-box;
              width: 100%;
            }
          }

          .assessment-list {
            .assessment-list-top {
              padding: 10px;

              .top-assessments,
              .top-created-date,
              .top-updated-date {
                display: none;
              }

              .assessment-list-top-ul {
                .top-ass-btn {
                  width: 100% !important;
                }
              }
            }

            .assessment-list-bottom {
              ul {
                padding-left: 10px;

                li {
                  &:before {
                    display: inline-block;
                    font-weight: 600;
                    margin-right: 10px;
                  }
                }

                .bot-new-name {
                  &:before {
                    content: 'Assessments : ';
                  }
                }

                .bot-created-date {
                  &:before {
                    content: 'Created Date : ';
                  }
                }

                .bot-updated-date {
                  &:before {
                    content: 'Updated Date : ';
                  }
                }

                .bot-new-edit {
                  &:before {
                    content: 'Edit :';
                  }
                }

                .bot-new-download {
                  &:before {
                    content: 'Download :';
                  }
                }

                .bot-new-delete {
                  &:before {
                    content: 'Delete :';
                  }
                }
              }
            }
          }
        }

        ul {
          li {
            width: calc(50% - 10px) !important;
            padding: 0 !important;
            margin: 5px;

            &:before {
              display: inline-block;
              font-weight: 600;
              margin-right: 10px;
              pointer-events: none;
            }

            &.age-agent-name {
              &:before {
                content: 'Agent name : ';
              }
            }

            &.inq-profit-advisor {
              &:before {
                content: 'Profit Advisor : ';
              }
            }

            &.gui-user {
              &:before {
                content: 'User Name : ';
              }
            }

            &.gui-count {
              &:before {
                content: 'Count : ';
              }
            }

            &.gui-date {
              &:before {
                content: 'Created Date : ';
              }
            }

            &.gui-gui {
              &:before {
                content: 'Is Guided : ';
              }
            }

            &.inq-name {
              &:before {
                content: 'Name : ';
              }
            }

            &.inq-email {
              &:before {
                content: 'Email : ';
              }
            }

            &.inq-phone-number {
              text-align: left !important;

              &:before {
                content: 'Phone Number : ';
              }
            }

            &.inq-inquiry-date {
              text-align: left !important;

              &:before {
                content: 'Inquiry Date : ';
              }
            }

            &.inq-sign-in {
              text-align: left !important;

              &:before {
                content: 'Sign In : ';
              }
            }

            &.inq-assign {
              text-align: left !important;

              &:before {
                // content: 'Assign : ';
              }
            }

            &.age-assessment {
              text-align: left !important;

              &:before {
                content: 'Assessment : ';
              }
            }

            &.age-edit {
              text-align: left !important;

              &:before {
                content: 'Edit : ';
              }
            }

            &.age-delete {
              text-align: left !important;

              &:before {
                content: 'Delete : ';
              }
            }

            &.inq-action {
              text-align: left !important;

              &:before {
                content: 'Action : ';
              }
            }

            &.age-assessmentcompany-name {
              &:before {
                content: 'Loan Officer Name : ';
              }
            }

            &.email {
              &:before {
                content: 'Email : ';
              }
            }

            &.uri {
              &:before {
                content: 'URI : ';
              }
            }

            &.phone-number {
              &:before {
                content: 'phone-number : ';
              }
            }

            &.sign-up-date {
              &:before {
                content: 'Sign Up Date : ';
              }
            }

            &.event-end {
              &:before {
                content: 'Event End : ';
              }
            }

            &.edit {
              text-align: left;

              &:before {
                content: 'Edit : ';
              }
            }

            &.reset {
              text-align: left !important;

              &:before {
                content: 'Reset : ';
              }
            }

            &.new-action {
              text-align: left !important;

              &:before {
                content: 'Show Agent : ';
              }

              .showAgent-btn {
                display: inline-block;
              }
            }

            &.event-start {
              &:before {
                content: 'Event Start : ';
              }
            }

            &.assessment {
              text-align: left;

              &:before {
                content: 'Assessment : ';
              }
            }

            &.delete {
              text-align: left;

              &:before {
                content: 'Action : ';
              }
            }

            &.action {
              position: absolute;
              top: 10px;
              right: 5px;
              width: auto !important;
            }
          }
        }
      }

      .question-header {
        .add-new {
          &:first-child {
            margin-left: 0;
          }
        }
      }

      &.agent-asst-bottom {
        .assement-search {
          width: calc(100% - 105px);
          margin-right: 15px;
          margin-bottom: 0;
        }
      }

      &.faq-asst-bottom {
        .question-list-wrapper {
          .question-inner {
            .question-list-main {
              .question-list {
                .question-list-head {
                  h3 {
                    width: calc(100% - 100px);
                  }
                }
              }
            }
          }
        }
      }

      &.agent-assessment-top {
        .assement-search {
          width: calc(100% - 105px);
          margin-right: 15px;
          margin-bottom: 0;
        }

        .assesment-button {
          a {
            height: 40px;
            line-height: 40px;
          }
        }

        .assesment-table {
          .assesment-table-content {
            height: calc(100vh - 273px) !important;
          }
        }
      }

      &.inquiry-assessment-bottom {
        .assement-search {
          &:not(.profit-filter) {
            width: calc(100% - 105px);
            margin-right: 15px;
            margin-bottom: 0;
          }

          &.profit-filter {
            select {
              padding-right: 25px;
            }
          }
        }
      }

      &.agent-classes-detail {
        .agent-profit-plan-classes {
          .agent-profit-plan-classes-left {
            > div {
              p {
                max-height: 123px;
                overflow: auto;
              }
            }
          }
        }
      }
    }

    .custom-radio {
      width: @full;
      padding-right: 0;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .test-assessment-ipad {
      .new-ass-que-wrapper {
        .new-ass-que-left {
          .new-ass-que-left-content {
            .new-ass-que-left-content-head {
              ul {
                li {
                  &.hint,
                  &.percentage,
                  &.answers {
                    display: none;
                  }
                }
              }
            }

            ul {
              li {
                &.answers {
                  width: calc(100% - 90px);
                }

                &.percentage {
                  text-align: right;
                }
              }
            }
          }
        }

        .new-ass-que-inner {
          > .user-details-header {
            padding: 0;
            margin-bottom: 15px;
          }
        }

        .user-details-header {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          background-color: #231f20;
          color: #ffffff;
          padding: 16px;

          h1 {
            font-size: 15px;
            font-weight: 700;
            margin-bottom: 0;
          }
        }

        .new-ass-que-right {
          .new-ass-que-right-top {
            padding: 0;
            margin-bottom: 0;
          }

          .user-detail-form {
            .new-ass-que-right-top {
              .new-user-left-content,
              .user-details-container {
                display: none;
              }

              .new-user-left-content {
                padding: 16px;

                ul {
                  li {
                    .imageRoate {
                      display: none;
                    }
                  }
                }
              }

              .user-details-container {
                padding-bottom: 16px;

                .user-input-div-details {
                  width: 100%;
                }
              }
            }

            &.active-form {
              .new-ass-que-right-top {
                .new-user-left-content,
                .user-details-container {
                  display: block;
                }
              }
            }
          }
        }

        .new-ass-que-left + .new-ass-que-right {
          .user-detail-form {
            display: none;
          }
        }
      }

      .form-control-main.sign-in-btn {
        margin: 20px 0;
      }

      .new-assessment-bottom {
        .new-ass-que-wrapper {
          .new-ass-que-left {
            + .new-ass-que-right {
              .user-detail-form {
                display: none;
              }
            }
          }
        }
      }
    }

    .assessment-topbar {
      background: #f8f8f8;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      padding: 12px 15px;

      .mobile-header {
        display: block;

        a {
          display: inline-block;
        }

        .mobile-logo {
          margin-left: 15px;

          img {
            height: 40px;
          }
        }
      }

      .userinfo {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .dropdown-toggle {
          span {
            white-space: nowrap;
            width: 120px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            text-align: left;
          }
        }
      }
    }

    .assessment-inner {
      .navigation {
        display: none;
        padding: 20px 15px;
        width: @full;
        position: absolute;
        cursor: pointer;
        top: 64px;
        border-top: 2px solid @bg-white;
        left: 0;
        width: 100%;
        z-index: 1;

        .navigation-logo {
          display: none;
        }

        nav {
          .mobile-close {
            display: block;
            position: absolute;
            top: 10px;
            right: 10px;
          }
        }
      }

      .assessment-body {
        width: @full;
      }

      &.menu-open {
        position: relative;

        .navigation {
          display: block;
        }
      }
    }
  }

  .model-popup-wrapper.add-new-course-popup .question-bottom-wrapper .question-bottom-inner form .from-group {
    padding: 0 15px;
  }

  body .main-wrapper .assessment-bottom .assesment-table ul.ui-agent-delete li.delete:before {
    content: 'Delete :';
  }
}

@media (max-width: 767px) {
  .sign-up-wrapper {
    min-height: 100vh;

    .sign-up-left,
    .sign-up-right {
      width: 100% !important;
    }

    .sign-up-left {
      padding: 20px 15px;
      display: none;
    }

    .sign-up-right {
      .sign-up-right-wrapper {
        padding: 20px 15px;

        .mobile-logo {
          display: block;
          margin-bottom: 30px;

          img {
            width: 100px;
          }
        }
      }
    }
  }

  .main-heading {
    font-size: 35px;
    line-height: 38px;
    margin-bottom: 40px;
  }

  .form-control-main {
    &.sign-in-btn {
      margin: 30px 0;
    }
  }

  //ASSESSMENT CSS START
  body .main-wrapper {
    .assessment-inner {
      .navigation {
        display: none;
        padding: 20px 15px;
        width: @full;
        position: absolute;
        cursor: pointer;
        top: 64px;
        border-top: 2px solid @bg-white;
        left: 0;
        width: 100%;
        z-index: 1;

        .navigation-logo {
          display: none;
        }

        nav {
          .mobile-close {
            display: block;
            position: absolute;
            top: 10px;
            right: 10px;
          }
        }
      }

      .assessment-body {
        width: @full;
      }

      &.menu-open {
        position: relative;

        .navigation {
          display: block;
        }
      }
    }

    .assessment-topbar {
      background: #f8f8f8;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      padding: 12px 15px;

      .mobile-header {
        display: block;

        a {
          display: inline-block;
        }

        .mobile-logo {
          margin-left: 15px;

          img {
            height: 40px;
          }
        }
      }

      .userinfo {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .dropdown-toggle {
          span {
            white-space: nowrap;
            width: 80px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            text-align: left;
          }
        }
      }
    }

    .assessment-bottom {
      padding: 20px 15px;

      .assement-select {
        width: ~'calc(100% - 90px)';
      }

      .assesment-button {
        width: 90px;
        margin-left: 0 !important;
      }

      .assesment-table-inner {
        width: unset;
      }

      .assesment-table {
        padding: 0;

        .assesment-table-header {
          display: none;
        }

        .assesment-table-content {
          height: unset;

          ul {
            &.table-main-content {
              padding: 10px 22px 10px 10px;
              position: relative;
            }
          }

          .assessment-list {
            .assessment-list-top {
              padding: 10px;

              .top-assessments,
              .top-created-date,
              .top-updated-date {
                display: none;
              }
            }

            .assessment-list-bottom {
              ul {
                padding-left: 10px;

                li {
                  &:before {
                    display: inline-block;
                    font-weight: 600;
                    margin-right: 10px;
                  }
                }

                .bot-new-name {
                  &:before {
                    content: 'Assessments : ';
                  }
                }

                .bot-created-date {
                  &:before {
                    content: 'Created Date : ';
                  }
                }

                .bot-updated-date {
                  &:before {
                    content: 'Updated Date : ';
                  }
                }

                .bot-new-edit {
                  &:before {
                    content: 'Edit :';
                  }
                }

                .bot-new-download {
                  &:before {
                    content: 'Download :';
                  }
                }

                .bot-new-delete {
                  &:before {
                    content: 'Delete :';
                  }
                }
              }
            }
          }
        }

        ul {
          li {
            width: @full !important;
            padding: 0 !important;
            margin: 5px;

            &:before {
              display: inline-block;
              font-weight: 600;
              margin-right: 10px;
            }

            &.company-name {
              &:before {
                content: 'Loan Officer Name : ';
              }
            }

            &.email {
              &:before {
                content: 'Email : ';
              }
            }

            &.phone-number {
              &:before {
                content: 'phone-number : ';
              }
            }

            &.sign-up-date {
              &:before {
                content: 'Sign Up Date : ';
              }
            }

            &.event-end {
              &:before {
                content: 'Event End : ';
              }
            }

            &.edit {
              text-align: left;

              &:before {
                content: 'Edit : ';
              }
            }

            &.reset {
              text-align: left !important;

              &:before {
                content: 'Reset : ';
              }
            }

            &.new-action {
              text-align: left !important;

              &:before {
                content: 'Show Agent : ';
              }

              .showAgent-btn {
                display: inline-block;
              }
            }

            &.event-start {
              &:before {
                content: 'Event Start : ';
              }
            }

            &.assessment {
              text-align: left;

              &:before {
                content: 'Assessment : ';
              }
            }

            &.delete {
              text-align: left;

              &:before {
                content: 'Action : ';
              }
            }

            &.action {
              position: absolute;
              top: 10px;
              right: 5px;
              width: auto !important;
            }
          }
        }
      }

      .question-header {
        .add-new {
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }

    .custom-radio {
      width: @full;
      padding-right: 0;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .question-bottom-wrapper {
    .question-bottom-inner {
      form {
        .from-group {
          width: 100%;
        }
      }
    }
  }

  .new-user-detail-wrapper {
    padding: @twenty - 5;

    .new-user-detail-left {
      .new-user-left-user {
        width: @full;
        margin-bottom: @twenty;
      }

      .new-user-left-content {
        width: @full;
      }
    }

    .new-user-detail-right {
      .new-user-right-current {
        width: @full;
      }
    }
  }

  .new-ass-que-wrapper {
    .new-ass-que-left {
      .new-ass-que-left-content {
        ul {
          padding: 0 15px;

          li {
            &.answers {
              padding-right: 10px;
              width: @full;
            }

            &.percentage {
              width: @full;

              select {
                margin: 0 10px 0 0;
              }
            }
          }
        }
      }
    }
  }

  .model-popup-wrapper {
    .model-popup {
      padding: @twenty;
    }
  }

  .agent-sign-up-wrapper {
    &:after,
    &:before {
      background-size: 100px;
      height: 100px;
      width: 100px;
    }

    .agent-sign-up-inner {
      .agent-sign-up-left {
        .agent-sign-up-left-top {
          margin-bottom: 20px;

          img {
            max-width: 150px;
          }
        }

        .agent-sign-up-left-bottom {
          img,
          iframe {
            height: 250px;
          }
        }
      }

      .agent-sign-up-right {
        padding: 20px;

        h2 {
          margin-bottom: 20px;
        }

        .profit-advisor-top {
          p {
            //width: 100%;
            //margin-bottom: 16px;
          }
        }

        form {
          .form-control-main {
            width: calc(100% - 24px);

            &.profit-signup {
              p {
                width: 100%;
                margin-bottom: 16px;
              }
            }
          }
        }
      }
    }
  }

  body .main-wrapper .assessment-bottom .assesment-table ul.ui-agent-delete li.delete:before {
    content: 'Delete :';
  }
}

@media (max-width: 767px) {
  //.new-ass-que-wrapper .new-ass-que-right{
  //  position: absolute;
  //  top: 78px;
  //  left: 0;
  //  padding: 0 15px;
  //}

  .new-ass-que-wrapper .user-input-div-details {
    width: 100%;
  }

  .new-ass-que-wrapper .new-ass-que-left {
    //padding-top: 10px;
  }

  .new-ass-que-wrapper .user-details-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: #231f20;
    color: #ffffff;
    padding: 16px;
  }

  h1 {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 0;
  }

  .new-ass-que-wrapper .new-ass-que-right .new-ass-que-right-top {
    padding: 0;
  }

  .new-ass-que-wrapper .new-user-left-content {
    padding: 16px;
  }

  .new-assessment-bottom .new-ass-que-wrapper .new-ass-que-left {
    margin-top: 20px;
  }

  .new-assessment-bottom .new-ass-que-wrapper .new-ass-que-left h2 {
    font-size: 17px;
  }

  .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-head ul li.hint,
  .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-head ul li.answers,
  .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content .new-ass-que-left-content-head ul li.percentage {
    display: none;
  }

  .graph-container .graph-block {
    margin: 0;
  }

  .graph-container .Graph-box {
    width: 100%;
    margin: 0 0 20px 0;
  }

  .new-assessment-bottom .new-ass-que-wrapper .new-ass-que-left + .new-ass-que-right .user-detail-form {
    display: none;
  }

  .new-assessment-bottom .new-ass-que-wrapper .new-ass-que-inner > .user-details-header {
    padding: 0;
  }

  .new-assessment-bottom
    .new-ass-que-wrapper
    .new-ass-que-right
    .user-detail-form.active-form
    .new-ass-que-right-top
    .new-user-left-content,
  .new-assessment-bottom
    .new-ass-que-wrapper
    .new-ass-que-right
    .user-detail-form.active-form
    .new-ass-que-right-top
    .user-details-container {
    display: block;
  }

  .new-assessment-bottom .new-ass-que-wrapper .new-ass-que-right .user-detail-form .new-ass-que-right-top .new-user-left-content,
  .new-assessment-bottom .new-ass-que-wrapper .new-ass-que-right .user-detail-form .new-ass-que-right-top .user-details-container {
    display: none;
  }

  .new-assessment-bottom .new-ass-que-wrapper .new-ass-que-right .new-ass-que-right-top {
    margin-bottom: 0;
  }

  body .main-wrapper .assessment-bottom.agent-classes-detail .agent-profit-plan-classes .agent-profit-plan-classes-left > div p {
    max-height: 123px;
    overflow: auto;
  }
}

@media (max-width: 767px) {
  .landing-signup-container .signup-img iframe {
    width: 100%;
  }

  .signup-input-div.phoneInput .iti--allow-dropdown {
    width: 100%;
  }

  .signup-input-div.phoneInput .iti--allow-dropdown .agentLandingPhoneInput {
    width: 100%;
  }

  .signup-title h2 {
    font-size: 26px;
    line-height: 38px;
  }

  body .main-wrapper {
    .assessment-bottom {
      &.agent-assessment-top {
        .assesment-company {
          .agent-top-header-mobile {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-end;
            margin-top: 15px;
          }
        }
      }

      .assesment-table {
        padding: 0;

        .assesment-table-header {
          display: none;
        }

        .assesment-table-content {
          height: calc(100vh - 167px) !important;

          ul {
            &.table-main-content {
              padding: 10px 22px 10px 10px;
              position: relative;
            }
          }

          .assessment-list {
            .assessment-list-top {
              padding: 10px;

              .top-assessments,
              .top-created-date,
              .top-updated-date {
                display: none;
              }
            }

            .assessment-list-bottom {
              ul {
                padding-left: 10px;

                li {
                  &:before {
                    display: inline-block;
                    font-weight: 600;
                    margin-right: 10px;
                  }
                }

                .bot-new-name {
                  &:before {
                    content: 'Assessments : ';
                  }
                }

                .bot-created-date {
                  &:before {
                    content: 'Created Date : ';
                  }
                }

                .bot-updated-date {
                  &:before {
                    content: 'Updated Date : ';
                  }
                }

                .bot-new-edit {
                  &:before {
                    content: 'Edit :';
                  }
                }

                .bot-new-download {
                  &:before {
                    content: 'Download :';
                  }
                }

                .bot-new-delete {
                  &:before {
                    content: 'Delete :';
                  }
                }
              }
            }
          }
        }

        ul {
          li {
            width: @full !important;
            padding: 0 !important;

            &:before {
              display: inline-block;
              font-weight: 600;
              margin-right: 10px;
            }

            &.inq-profit-advisor {
              &:before {
                content: 'Profit Advisor : ';
              }
            }

            &.gui-user {
              &:before {
                content: 'User Name : ';
              }
            }

            &.gui-count {
              &:before {
                content: 'Count : ';
              }
            }

            &.gui-date {
              &:before {
                content: 'Created Date : ';
              }
            }

            &.gui-gui {
              &:before {
                content: 'Is Guided : ';
              }
            }

            &.inq-name {
              &:before {
                content: 'Name : ';
              }
            }

            &.inq-email {
              &:before {
                content: 'Email : ';
              }
            }

            &.age-agent-name {
              &:before {
                content: 'Agent name : ';
              }
            }

            &.inq-phone-number {
              text-align: left !important;

              &:before {
                content: 'Phone Number : ';
              }
            }

            &.inq-inquiry-date {
              text-align: left !important;

              &:before {
                content: 'Inquiry Date : ';
              }
            }

            &.inq-sign-in {
              text-align: left !important;

              &:before {
                content: 'Sign In : ';
              }
            }

            &.inq-assign {
              text-align: left !important;

              &:before {
                // content: 'Assign : ';
              }
            }

            &.age-assessment {
              text-align: left !important;

              &:before {
                content: 'Assessment : ';
              }
            }

            &.age-edit {
              text-align: left !important;

              &:before {
                content: 'Edit : ';
              }
            }

            &.age-delete {
              text-align: left !important;

              &:before {
                content: 'Delete : ';
              }
            }

            &.inq-action {
              text-align: left !important;

              &:before {
                content: 'Action : ';
              }
            }

            &.age-assessmentcompany-name {
              &:before {
                content: 'Loan Officer Name : ';
              }
            }

            &.email {
              &:before {
                content: 'Email : ';
              }
            }

            &.uri {
              &:before {
                content: 'URI : ';
              }
            }

            &.phone-number {
              &:before {
                content: 'phone-number : ';
              }
            }

            &.sign-up-date {
              &:before {
                content: 'Sign Up Date : ';
              }
            }

            &.event-end {
              &:before {
                content: 'Event End : ';
              }
            }

            &.edit {
              text-align: left;

              &:before {
                content: 'Edit : ';
              }
            }

            &.reset {
              text-align: left !important;

              &:before {
                content: 'Reset : ';
              }
            }

            &.new-action {
              text-align: left !important;

              &:before {
                content: 'Show Agent : ';
              }

              .showAgent-btn {
                display: inline-block;
              }
            }

            &.event-start {
              &:before {
                content: 'Event Start : ';
              }
            }

            &.assessment {
              text-align: left;

              &:before {
                content: 'Assessment : ';
              }
            }

            &.delete {
              text-align: left;

              &:before {
                content: 'Action : ';
              }
            }

            &.action {
              position: absolute;
              top: 10px;
              right: 5px;
              width: auto !important;
            }
          }
        }
      }
    }

    .test-assessment-ipad {
      .form-control-main {
        &.sign-in-btn {
          button {
            width: calc(50% - 8px);
          }
        }
      }
    }
  }

  .model-popup-wrapper {
    &.add-new-officer {
      .custom-radio-wrapper {
        .custom-radio {
          .radio-container {
            width: 150px;
          }

          .checkmark {
            width: 130px;
          }
        }
      }
    }
  }

  body .main-wrapper .assessment-bottom.agent-assessment-top .assesment-table .assesment-table-content {
    height: calc(100vh - 336px) !important;
  }

  .question-list-wrapper {
    &.question-mobile {
      .question-inner {
        .question-list-main {
          .question-list {
            .question-list-head {
              h3 {
                width: calc(100% - 114px);
              }

              div {
                width: 114px;
                flex-wrap: wrap;
                justify-content: flex-end;

                h3 {
                  width: 100%;
                  display: block;
                  margin-right: 0 !important;
                  text-align: right;
                  margin-bottom: 10px;
                }
              }
            }
          }
        }
      }
    }
  }

  .faq-asst-bottom {
    .question-list-wrapper {
      .question-inner {
        .question-list-main {
          .question-list {
            .question-list-head {
              h3 {
                width: calc(100% - 85px);
              }
            }
          }
        }
      }
    }
  }

  // .assessment-body {
  //   .assessment-bottom {
  //     &.courses-top-header {
  //       &.agent-classes-detail {
  //         h3 {
  //           // width: calc(100% - 180px);
  //         }
  //         .assesment-header {
  //           button {
  //             width: 170px;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
  .assessment-body .assessment-bottom.courses-top-header.agent-classes-detail .assesment-header button {
    width: auto;
  }

  .model-popup-wrapper .model-popup .model-popup-title h3 {
    font-size: 21px;
  }

  .user-profile-bottom-inner {
    .edit-user-profile {
      .custom-radio {
        margin-bottom: 0;

        .radio-container {
          width: 134px !important;

          .checkmark {
            width: 134px !important;
          }
        }
      }
    }
  }

  // body .user-profile-bottom-inner .custom-radio-wrapper.edit-user-profile .custom-radio .radio-container .checkmark {
  //   width: 200px !important;
  // }
  body .main-wrapper .assessment-bottom .assesment-table ul li.inq-name:before {
    content: 'Name : ';
  }

  .model-popup-wrapper.add-new-course-popup .question-bottom-wrapper .question-bottom-inner form .from-group {
    padding: 0;
  }

  .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content ul li.percentage {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .new-ass-que-wrapper .new-ass-que-left .new-ass-que-left-content ul li.percentage .border {
    margin: 0 10px 0 0;
  }

  .form-control-main .tooltip-inner {
    width: 300px;
  }

  .new-classes-wrapper .new-classes-middle .new-classes-download-btn .new-classes-download-btn-left {
    width: 100%;
    margin-bottom: 8px;
  }

  .new-classes-wrapper .new-classes-middle .new-classes-download-btn .new-classes-download-btn-right {
    width: 100%;
    padding-left: 0;
    justify-content: flex-end;
  }

  .right-side .popup-custom-checkbox {
    width: auto !important;
  }

  .prec-detail-wrapper .prec-detail-wrapper-left iframe {
    height: 200px;
  }

  .prec-detail-wrapper .prec-detail-wrapper-right .pre-call-content-box .agent-content-box-left {
    width: 110px;
  }

  .prec-detail-wrapper .prec-detail-wrapper-right .pre-call-content-box .agent-content-box-right {
    width: calc(100% - 110px);
  }

  .prec-detail-wrapper .prec-detail-wrapper-right .pre-call-content-box .agent-content-box-left iframe {
    width: 100%;
  }

  .previous-coaching-table .previous-coaching-table-content .title,
  .previous-coaching-table .previous-coaching-table-content .modified,
  .previous-coaching-table .previous-coaching-table-content .clickhere,
  .previous-coaching-table .previous-coaching-table-content .action {
    width: 100%;
    text-align: left;
    margin: 5px 0;
  }

  .previous-coaching-table .previous-coaching-table-head {
    display: none;
  }

  .previous-coaching-table .previous-coaching-table-content {
    border-top: 1px solid #dad9dc;
  }

  .previous-coaching-table .previous-coaching-table-content .title {
    position: relative;
    padding-left: 63px;
  }

  .previous-coaching-table .previous-coaching-table-content .title:before {
    content: 'Title : ';
    font-weight: 600;
    margin-right: 10px;
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translate(0, -50%);
  }

  .previous-coaching-table .previous-coaching-table-content .modified {
    position: relative;
    padding-left: 93px;
  }

  .previous-coaching-table .previous-coaching-table-content .modified:before {
    content: 'Created : ';
    font-weight: 600;
    margin-right: 10px;
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translate(0, -50%);
  }

  .previous-coaching-table .previous-coaching-table-content .clickhere {
    position: relative;
    padding-left: 63px;
  }

  .previous-coaching-table .previous-coaching-table-content .clickhere:before {
    content: 'Link : ';
    font-weight: 600;
    margin-right: 10px;
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translate(0, -50%);
  }

  .previous-coaching-table .previous-coaching-table-content .action {
    position: relative;
    padding-left: 77px;
  }

  .previous-coaching-table .previous-coaching-table-content .action:before {
    content: 'Action : ';
    font-weight: 600;
    margin-right: 10px;
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translate(0, -50%);
  }

  .previous-coaching-table .previous-coaching-table-content {
    max-height: unset;
    overflow: unset;
  }

  .previous-coaching-popup.model-popup-wrapper .model-popup {
    width: calc(100% - 30px);
    margin: 0 auto;
  }

  body .main-wrapper .assessment-topbar .mobile-header .mobile-logo img {
    height: 37px;
  }

  body .main-wrapper .assessment-topbar .mobile-header .mobile-logo {
    margin-left: 5px;
  }

  .main-wrapper .assessment-topbar .userinfo img {
    width: 30px;
    height: 30px;
  }

  .previous-coaching-table .previous-coaching-table-content .list-video-name {
    width: 100%;
    padding-left: 0;
    margin-top: 5px;
    word-break: break-all;
  }

  .courses-top-header .question-header .add-new.back-to-list {
    display: block;
    margin-right: 0 !important;
    margin-top: 10px;
  }
  .new-classes-wrapper .new-classes-right .agent-content-box.video-upload .agent-content-box-wrapper-new .agent-content-box-left iframe {
    width: 100%;
  }
  .upload-file-wrapper-prev {
    margin: 0;
  }
  .upload-file-wrapper-prev .upload-file-box-prev {
    width: 100%;
    margin: 0 0 16px 0;
  }
  .resource-wrapper .resource-inner {
    margin: 0;
  }
  .resource-wrapper .resource-inner .resource-box {
    width: 100%;
    margin: 0 0 16px 0;
  }
  .previous-coaching-table-content .cdk-drop-list {
    height: auto;
  }
  .main-wrapper .assessment-bottom .inquiry-search-heade .assesment-button {
    width: 97px;
  }
  .video-des-email .video-des--email span,
  .video-des-email .video-des--link span {
    width: 23px;
  }
  .video-des-email .video-des--email label,
  .video-des-email .video-des--link a {
    width: calc(100% - 23px);
  }
  .previous-coaching-popup .question-bottom-wrapper .question-bottom-inner form .from-group {
    margin-right: 0;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul.ui-agent-delete li.delete1:before {
    content: 'Delete :';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.assessment1:before {
    content: 'Assessment : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.edit1:before {
    content: 'Edit : ';
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.action1 {
    position: absolute;
    top: 10px;
    right: 5px;
    width: auto !important;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.edit1 {
    text-align: left !important;
  }
  body .main-wrapper .assessment-bottom .assesment-table ul li.delete1 {
    text-align: left !important;
  }
  .new-canva-right-table .new-canva-right-table-content-wrapper .new-canva-right-table-content .canva-table-title {
    width: 100%;
    margin-bottom: 15px;
    position: relative;
    padding-left: 47px;
  }
  .new-canva-right-table .new-canva-right-table-content-wrapper .new-canva-right-table-content .canva-table-action {
    width: 100%;
    position: relative;
    padding-left: 61px;
  }
  .new-canva-right-table .new-canva-right-table-content-wrapper .new-canva-right-table-content .canva-table-title:before {
    content: 'Title : ';
    font-weight: 600;
    margin-right: 10px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
  }
  .new-canva-right-table .new-canva-right-table-content-wrapper .new-canva-right-table-content .canva-table-action:before {
    content: 'Action : ';
    font-weight: 600;
    margin-right: 10px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
  }
  .new-canva-right-table .new-canva-right-table-content-wrapper .new-canva-right-table-content .canva-table-title .canva-table-title-img p {
    width: 100%;
    padding-left: 0;
    margin-top: 5px;
  }
  .new-canva-right-table .new-canva-right-table-head {
    display: none;
  }
  .new-canva-right-table-content-visible .new-canva-right-table-content-visible-left {
    width: 100%;
  }
  .new-canva-right-table-content-visible .new-canva-right-table-content-visible-right {
    width: 100%;
    padding-left: 0;
    margin-top: 20px;
  }
}
