.dashboard-wrapper {
  padding: 24px 24px 0 30px;
  .dashboard-title {
    margin-bottom: 32px;
    p {
      color: #000;
      font-size: 24px;
      font-weight: 600;
    }
  }
  .dashboard-content {
    margin: 0 -24px;
    .dashboard-box {
      width: calc(20% - 48px);
      margin: 0 24px 48px 24px;
    }
  }
}
.model-popup-wrapper {
  &.dashboard-popup {
    .model-popup {
      width: 1371px;
    }
    .dashboard-content {
      margin: 0 -24px;
      .dashboard-box {
        width: calc(25% - 48px);
        margin: 0 24px 48px 24px;
      }
    }
  }
}
.header-dashboard {
  a {
    color: #e5702d;
    font-size: 16px;
    font-weight: 500;
  }
}
@media (max-width: 1400px) {
  .model-popup-wrapper {
    &.dashboard-popup {
      .model-popup {
        width: calc(100% - 30px);
        margin: 0 auto;
      }
      .dashboard-content {
        margin: 0 -24px;
        .dashboard-box {
          width: calc(25% - 48px);
          margin: 0 24px 48px 24px;
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  .dashboard-wrapper {
    .dashboard-content {
      margin: 0 -15px;
      .dashboard-box {
        width: calc(25% - 30px);
        margin: 0 15px 30px 15px;
      }
    }
  }
}
@media (max-width: 991px) {
  .model-popup-wrapper {
    &.dashboard-popup {
      .dashboard-content {
        margin: 0 -15px;
        .dashboard-box {
          width: calc(33.33% - 30px);
          margin: 0 15px 30px 15px;
        }
      }
    }
  }
  .header-dashboard {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .dashboard-wrapper {
    padding: 12px 15px;
    .dashboard-content {
      margin: 0 -15px;
      .dashboard-box {
        width: calc(50% - 30px);
        margin: 0 15px 30px 15px;
      }
    }
  }
  .model-popup-wrapper {
    &.dashboard-popup {
      .dashboard-content {
        margin: 0 -15px;
        .dashboard-box {
          width: calc(50% - 30px);
        }
      }
    }
  }
}
