
.LoaderModel{
    height: 100vh;
    width: 100vw !important;
    max-width: 100vw !important;
    margin: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;


    .modal-content{
        height: 100vh;
        width: 100vw;
        max-width: 100vw;
        background-color: transparent;
        border: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}